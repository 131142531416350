import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/inline_class.min.js";

import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
// import "froala-editor/js/plugins/video.min.js";
// import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
// import "froala-editor/js/plugins/quick_insert.min.js";
// import "froala-editor/css/plugins/quick_insert.min.css";

// import "froala-editor/js/plugins/print.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/froala_style.min.css";
import ChangeRequest from "./changeRequest.js";
import SuggestionCardComponent from "./SuggestionCardComponent";
import "./editor-style.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import RecommendationSkeletonLoader from "../loading-screen/RecommendationSkeleton.js";
import EditorSkeleton from "../loading-screen/EditorSkeleton.js";
import ResolveChangeRequestPopUp from "../PopUps/ResolveChangeRequestPopUp.js";
import { toast } from "react-toastify";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
const FunctionalEditor = () => {
  const isFirstContentChange = useRef(true);
  const [requestData, setRequestData] = useState(null);
  const [model, setModel] = useState("");
  const [realTimeModel, setRealTimeModel] = useState("");
  const [dbModel, setDBModel] = useState("");
  const [acceptedRecommendations, setAcceptedRecommendations] = useState([]);
  const [rejectedRecommendations, setRejectedRecommendations] = useState([]);
  const [showChangeRequest, setShowChangeRequest] = useState(false);
  const showChangeRequestRef = useState(showChangeRequest);
  const [showResolveWarning, setShowResolveWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const changedModelRef = useRef(model);
  const suggestionListRef = useRef(null);
  const [currentRecommendationIndex, setCurrentRecommendationIndex] =
    useState(-2);
  const [isDirty, setIsDirty] = useState(false);
  const debounceDelay = 5000; // 5 seconds
  const [fileName, setFileName] = useState("");
  const [activeRecommendationId, setActiveRecommendationId] = useState("");
  const [activeDocId, setActiveDocId] = useState("");
  const acceptedArray = useRef(acceptedRecommendations);
  const rejectedArray = useRef(rejectedRecommendations);
  const activeDocIdRef = useRef(activeDocId);
  const activeFileName = useRef(fileName);
  const [activeRecommendationData, setActiveRecommendationData] =
    useState(null);
  const activeRecommendationDataRef = useRef(activeRecommendationData);
  const [showByteCard, setShowByteCard] = useState(false);
  const [recommendationData, setRecommendationData] = useState(null);
  const recommendationDataRef = useRef(recommendationData);
  const [editorWidth, setEditorWidth] = useState(850);
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const realTimeModelRef = useRef(realTimeModel);
  const dBModelRef = useRef(dbModel);
  const location = useLocation();
  const navigate = useNavigate();
  const { id, byteId } = useParams();

  useEffect(() => {
    if (location.pathname.includes("document-edit")) {
      setShowChangeRequest(true);
    } else if (location.pathname.includes("document")) {
      setShowChangeRequest(false);
      if (String(activeDocId) !== String(id)) {
        setIsLoading(true);
      }
    }
  }, [location]);
  useEffect(() => {
    if (!showChangeRequest) {
      fetchData();
    }
    setActiveDocId(id);
    isFirstContentChange.current = true;
  }, [location]);

  useEffect(() => {
    dBModelRef.current = dbModel;
  }, [dbModel]);

  useEffect(() => {
    showChangeRequestRef.current = showChangeRequest;
  }, [showChangeRequest]);

  useEffect(() => {
    if (activeRecommendationData && showChangeRequest) {
      setShowByteCard(true);
    } else {
      setShowByteCard(false);
    }
    // console.log(showByteCard, "SHOW CHANGE REQUEST");
  }, [showChangeRequest, activeRecommendationData, id, location]);
  //FETCH DOCUMENT DATA
  const fetchData = async () => {
    try {
      let response;
      setModel("Loading");
      setAcceptedRecommendations([]);
      setRejectedRecommendations([]);
      //Fetch data for change-request (Byte based) recommendation
      if (location.pathname.includes("document-edit")) {
        setShowChangeRequest(true);
        response = await apiService.getRecommendationForByte(byteId);
        try {
          if (response) {
            setRequestData(response.data);
            let url;
            if (!activeRecommendationDataRef.current) {
              navigate(
                `/home/${byteId}/document-edit/${response.data.documents[0].doc_id}`,
                {
                  replace: true,
                }
              );
              url = response.data.documents[0].doc_content;
            } else {
              // console.log(" ALREADY A RECCOMENDION");
              navigate(
                `/home/${byteId}/document-edit/${activeRecommendationDataRef.current.doc_id}`,
                {
                  replace: true,
                }
              );
              url = activeRecommendationDataRef.current.doc_content;
              // highlightText();
            }
            const htmlResponse = await fetch(url, { mode: "cors" });
            const htmlBlob = await htmlResponse.blob();
            const htmlContent = await htmlBlob.text();
            // console.log("HTML CONTENT",htmlContent);
            setModel(htmlContent);
            setDBModel(htmlContent);
            if (response.data.documents && response.data.documents.length > 0) {
              let allRecommendations = [];

              const url = response.data.documents[0].doc_content;
              setFileName(url.substring(url.lastIndexOf("/") + 1));
              response.data.documents.forEach((document) => {
                const doc_id = document.doc_id;
                const doc_content = document.doc_content;

                const mappedRecommendations = document.recommendations.map(
                  (rec) => ({
                    ...rec,
                    doc_content: doc_content,
                    byte_id: response.data.request_id,
                    doc_id: doc_id,
                    x: 0,
                    y: -9999,
                  })
                );
                allRecommendations = [
                  ...allRecommendations,
                  ...mappedRecommendations,
                ];
              });

              setRecommendationData(allRecommendations);
              // if (!activeRecommendationData) {
              //   setActiveRecommendationData(allRecommendations[0]);
              //   setActiveRecommendationId(allRecommendations[0].id);
              // }
            }

            setIsLoading(false);
          }
        } catch (e) {
          toast.error("Invalid Document! Please try again");
          navigate("/home/all-requests");
        }
      } else if (location.pathname.includes("document")) {
        // setIsLoading(true);
        const container = containerRef.current;
        if (container) {
          container.scrollTop = 0;
        }

        //Fetch data for document based recommendation
        response = await apiService.getRecommendationSingleDoc(id);
        if (response) {
          setActiveRecommendationData(null);
          setRequestData(response);
          const url = response.data.document.doc_content;
          setFileName(url.substring(url.lastIndexOf("/") + 1));
          const htmlResponse = await fetch(url, { mode: "cors" });
          const htmlBlob = await htmlResponse.blob();
          const htmlContent = await htmlBlob.text();
          // console.log(url, "HTML LOADING FIRST TIME FOR DOCUMENT", htmlContent);
          setModel(htmlContent);
          setDBModel(htmlContent);
          if (
            response.data.document.bytes &&
            response.data.document.bytes.length > 0
          ) {
            const doc_id = response.data.document.doc_id;
            const mappedRecommendations = response.data.document.bytes.flatMap(
              (byte) =>
                byte.recommendations.map((rec) => ({
                  ...rec,
                  doc_content: response.data.document.doc_content,
                  byte_id: byte.byteId,
                  doc_id: doc_id,
                  x: 0,
                  y: -9999,
                }))
            );
            console.log("RECOMMENDATION FOR DOCUMENT", mappedRecommendations);
            setRecommendationData(mappedRecommendations);
          } else {
            setRecommendationData([]);
          }

          setIsLoading(false);
        }
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
      setIsLoading(true);
    }
  };

  //Handle size of change Request Header
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setEditorWidth(entry.contentRect.width);
      }
    });
    if (editorRef.current) {
      resizeObserver.observe(editorRef.current);
    }
    return () => {
      if (editorRef.current) {
        resizeObserver.unobserve(editorRef.current);
      }
    };
  }, [editorRef, model]);

  useEffect(() => {
    if (!activeRecommendationData || !showChangeRequest) return;

    const container = containerRef.current;
    if (container) {
      const activeRecommendation = recommendationData.find(
        (rec) => rec.id === activeRecommendationData.id
      );

      if (activeRecommendation) {
        const scrollPosition = activeRecommendation.y - 100;
        container.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      } else {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [
    activeRecommendationData,
    currentRecommendationIndex,
    recommendationData,
  ]);

  useEffect(() => {
    acceptedArray.current = acceptedRecommendations;
  }, [acceptedRecommendations]);

  useEffect(() => {
    rejectedArray.current = rejectedRecommendations;
  }, [rejectedRecommendations]);

  useEffect(() => {
    activeDocIdRef.current = activeDocId;
    // console.log("ACTIVE DOCUMENT ID", activeDocId);
  }, [activeDocId]);

  useEffect(() => {
    activeRecommendationDataRef.current = activeRecommendationData;
    // console.log(
    //   "ACTIVE RECOMMENDATION DATA",
    //   activeRecommendationDataRef.current
    // );
  }, [activeRecommendationData]);

  useEffect(() => {
    recommendationDataRef.current = recommendationData;
  }, [recommendationData]);
  useEffect(() => {
    activeFileName.current = fileName;
  }, [fileName]);

  const uploadDocument = async (newContent) => {
    if (newContent === "Loading") {
      return;
    }
    const cleanedContent = removeMarkTags(newContent);

    try {
      console.log("UPLOADED DOCUMENT", cleanedContent);
      // console.log("activeDocIdRef.current", activeDocIdRef.current);
      // console.log("Location.id", id);
      console.log("ACTIVE DOCUMENT FILE NAME", activeFileName.current);
      // console.log("FILE DOCUMENT FILE NAME", fileName);
      if (cleanedContent !== dBModelRef.current) {
        const htmlBlob = new Blob([cleanedContent], { type: "text/html" });
        const htmlFile = new File([htmlBlob], activeFileName.current, {
          type: "text/html",
          lastModified: Date.now(),
        });
        await apiService.uploadDocument(htmlFile, activeDocIdRef.current, "5");
        changedModelRef.current = newContent;
        setIsDirty(false);
      } else {
        console.log("Old data");
      }
    } catch (error) {
      // console.error("Failed to upload document:", error);
    }
  };

  const debouncedUpload = debounce((newContent) => {
    uploadDocument(newContent);
  }, debounceDelay);

  const handleBeforeUnload = (event) => {
    if (isDirty) {
      uploadDocument(realTimeModelRef.current);
      event.preventDefault();
      event.returnValue = "You have unsaved changes. Are you sure to leave?";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, location]);

  const handleUpdate = () => {
    if (isDirty) {
      uploadDocument(realTimeModelRef.current);
    }
  };

  const removeMarkTags = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const marks = tempElement.querySelectorAll("mark,recommendation");
    marks.forEach((mark) => {
      const parent = mark.parentNode;
      while (mark.firstChild) {
        parent.insertBefore(mark.firstChild, mark);
      }
      parent.removeChild(mark);
    });

    return tempElement.innerHTML;
  };

  const handleContentChange = (newContent) => {
    setIsDirty(true);
    debouncedUpload(newContent);
  };

  //Recommendation Functionality
  useEffect(() => {
    if (requestData) {
      changedModelRef.current = model;
      realTimeModelRef.current = model;
      // console.log("CALLED BY USEEFFECT IN MODEL");

      placeCircles();
    }
  }, [model]);

  useEffect(() => {
    console.log("RECOMMENDATION UPDATED DATA", recommendationData);
  }, [recommendationData]);

  useEffect(() => {
    if (recommendationData) {
      const sortedRecommendations =
        sortRecommendationsByDocIdAndY(recommendationData);
      const newIndex = sortedRecommendations.findIndex(
        (recommendation) => recommendation.id === activeRecommendationId
      );

      // console.log(newIndex, "setCurrentRecommendationIndex");
      if (newIndex !== -1) {
        setCurrentRecommendationIndex(newIndex);
      }
    }
  }, [activeRecommendationId]);

  //ADD RECOMMENDATION
  const addText = (recommendationId) => {
    if (!requestData) return false;

    if (activeRecommendationData) {
      const {
        section_main_heading1,
        section_main_heading2,
        section_main_heading3,
        section_main_heading4,
        change_request_text,
      } = activeRecommendationData;

      let filteredContent = change_request_text;

      const removeHeadingAndElement = (content, heading) => {
        if (!heading) return content;
        const regex = new RegExp(
          `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
          "gi"
        );
        return content.replace(regex, "");
      };

      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading1
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading2
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading3
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading4
      );
      const recommendationElement = document.querySelector(
        `[data-location="recommendation_${recommendationId}"]`
      );
      setAcceptedRecommendations((prev) => [...prev, recommendationId]);
      if (recommendationElement) {
        // console.log(
        //   "Recommendation found, removing it and replacing with filtered content."
        // );
        const parent = recommendationElement.parentNode;
        recommendationElement.remove();
        parent.innerHTML = filteredContent;
        return true;
      } else {
        const newModel = model + `${filteredContent}`;
        changedModelRef.current = newModel;
        setModel(newModel);
        setIsDirty(true);
        return true;
      }
    }
    return false;
  };

  //REPLACE RECOMMENDATION
  const replaceText = (recommendationId) => {
    if (!requestData) return false;
    let parser = new DOMParser();
    let doc = parser.parseFromString(model, "text/html");
    const recommendation = activeRecommendationData;

    if (recommendation) {
      const {
        section_main_heading1,
        section_main_heading2,
        section_main_heading3,
        section_main_heading4,
        change_request_text,
      } = recommendation;

      let filteredContent = change_request_text;

      const removeHeadingAndElement = (content, heading) => {
        if (!heading) return content;
        const regex = new RegExp(
          `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
          "gi"
        );
        return content.replace(regex, "");
      };
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading1
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading2
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading3
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading4
      );

      const markElements = doc.body.querySelectorAll("mark");

      if (markElements.length > 0) {
        const firstMark = markElements[0];
        firstMark.innerHTML = filteredContent;

        Array.from(firstMark.attributes).forEach((attr) =>
          firstMark.removeAttribute(attr.name)
        );

        for (let i = 1; i < markElements.length; i++) {
          const mark = markElements[i];
          const parentNode = mark.parentNode;
          if (parentNode) {
            const textNode = document.createTextNode(mark.innerHTML);
            parentNode.replaceChild(textNode, mark);
          }
        }
      } else {
        return false;
      }

      clearPreviousHighlights(doc);
      const updatedModel = doc.documentElement.outerHTML;
      setModel(updatedModel);
      return true;
    }
    return false;
  };

  //NO NEED
  // const findHtmlInElement = (element, html) => {
  //   const parser = new DOMParser();
  //   const parsedHtml = parser
  //     .parseFromString(html, "text/html")
  //     .body.innerHTML.trim();

  //   if (
  //     element.nodeType === Node.ELEMENT_NODE ||
  //     element.nodeType === Node.TEXT_NODE
  //   ) {
  //     const normalizedElementHtml = element.innerHTML
  //       ? element.innerHTML.trim()
  //       : "";
  //     const normalizedElementText = element.textContent.trim();
  //     if (
  //       normalizedElementHtml.includes(parsedHtml) ||
  //       normalizedElementText.includes(parsedHtml)
  //     ) {
  //       return element;
  //     }
  //     for (let child of element.childNodes) {
  //       const found = findHtmlInElement(child, html);
  //       if (found) return found;
  //     }
  //   }
  //   return null;
  // };

  const parseHtmlToNormalText = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const handleModelChange = useCallback((newModel) => {
    setModel(newModel);
    // console.log("HTML CONTENT HANDLE MODEL CHANGE FUNCTION CALLED");
  }, []);

  const addFloatingCircle = (x, y, index, recommendation) => {
    // console.log("ADD Function called");
    const editorContainer = document.querySelector(".froala-editor");
    if (editorContainer) {
      const circle = document.createElement("div");
      circle.className = "floating-circle";
      const circleContent = document.createElement("span");
      circleContent.className = "circle-content";
      circleContent.textContent = index;
      circle.appendChild(circleContent);
      circle.style.position = "absolute";
      circle.style.left = `${x}px`;
      circle.style.top = `${y}px`;
      circle.style.zIndex = 10;
      circle.isHighlighted = false;
      circle.addEventListener("click", () => {
        setActiveRecommendationData(recommendation);
        if (showChangeRequest) {
          // console.log("RECOMMENDATION SET ONLY NO NAVIGATION");
        } else {
          setShowChangeRequest(true);
          navigate(`/home/${recommendation.byte_id}/document-edit/loading`, {
            replace: true,
          });
        }
      });
      editorContainer.appendChild(circle);
    }
  };

  const handleNavigation = (recommendation) => {
    setActiveRecommendationData(recommendation);
    if (showChangeRequest) {
    } else {
      setShowChangeRequest(false);
      navigate(`/home/${recommendation.byte_id}/document-edit/loading`, {
        replace: true,
      });
    }
  };
  const placeCircles = () => {
    removeFloatingCircles();
    const editorContainer = document.querySelector(".froala-editor");
    if (editorContainer && recommendationData) {
      const updatedRecommendations = recommendationData.map((rec) => ({
        ...rec,
      }));
      const existingYCoordinates = new Set();
      const endPositions = [];
      updatedRecommendations.forEach((rec, index) => {
        if (
          acceptedArray.current.includes(rec.id) ||
          rejectedArray.current.includes(rec.id)
        ) {
          //MAY BE DISTURBING REFRESH
          // console.log("INCLUDED IN ACCEPTED ARRAY OR REJECTED ARRAY");
          return;
        }
        const previousHtml = parseHtmlToNormalText(rec.previous_string);
        const normalizedPreviousHtml = previousHtml
          .replace(/\n/g, "")
          .replace(/\s+/g, "")
          .toLowerCase();
        const normalizeTextContent = (node) =>
          node.textContent
            .replace(/<br\s*\/?>/gi, "")
            .replace(/&nbsp;/g, "")
            .replace(/\n/g, "")
            .replace(/\s+/g, "")
            .toLowerCase();
        const flatBodyText = normalizeTextContent(editorContainer);
        const matchStartIndex = flatBodyText.indexOf(normalizedPreviousHtml);
        const matchEndIndex = matchStartIndex + normalizedPreviousHtml.length;
        if (String(rec.doc_id) !== String(id)) {
          return;
        }
        if (matchStartIndex === -1 || rec.previous_string === "") {
          if (
            rec.change_request_type === "Add" &&
            !acceptedArray.current.includes(rec.id) &&
            !rejectedArray.current.includes(rec.id)
          ) {
            const y_position_add = editorRef.current.scrollHeight;
            addFloatingCircle(0, y_position_add, index + 1, rec);
          }
          endPositions.push(rec);

          return;
        }

        let range = document.createRange();
        let currentOffset = 0;

        const findMatchingNode = (node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            const originalText = node.textContent;
            const normalizedText = originalText
              .replace(/\s+/g, "")
              .toLowerCase();
            const textLength = normalizedText.length;

            if (
              currentOffset < matchEndIndex &&
              currentOffset + textLength > matchStartIndex
            ) {
              const startOffset = Math.max(0, matchStartIndex - currentOffset);
              const endOffset = Math.min(
                textLength,
                matchEndIndex - currentOffset
              );

              range.setStart(node, startOffset);
              range.setEnd(node, endOffset);
              if (rec.change_request_type === "Add") {
                const existingRecommendation = document.querySelector(
                  `[data-location="recommendation_${rec.id}"]`
                );
                if (existingRecommendation) {
                  const existingIndex =
                    existingRecommendation.getAttribute("data-index");

                  if (existingIndex !== (index + 1).toString()) {
                    existingRecommendation.setAttribute(
                      "data-index",
                      index + 1
                    );
                  }
                } else {
                  const originalText = node.textContent;
                  const textBefore = originalText.slice(0, matchEndIndex);
                  const textAfter = originalText.slice(matchEndIndex);
                  const recommendationElement =
                    document.createElement("recommendation");
                  recommendationElement.setAttribute(
                    "data-location",
                    `recommendation_${rec.id}`
                  );
                  recommendationElement.setAttribute(
                    "data-recommendation-doc-id",
                    rec.doc_id
                  );
                  recommendationElement.setAttribute("data-index", index + 1);
                  recommendationElement.onclick = () => handleNavigation(rec);

                  const lineBreak = document.createElement("br");
                  let parent = node.parentNode;
                  let grandParent = parent ? parent.parentNode : null;
                  const hasExistingRecommendation =
                    parent &&
                    Array.from(parent.children).some(
                      (child) =>
                        child.tagName === "RECOMMENDATION" &&
                        child.getAttribute("data-location") ===
                          `recommendation_${rec.id}`
                    );

                  if (hasExistingRecommendation) {
                    // console.log(
                    //   "Recommendation with the same data-location already exists. Skipping insertion."
                    // );
                    return;
                  }
                  if (parent) {
                    console.log("PARENT ", parent);
                    if (parent.tagName === "LI") {
                      let listParent = parent.parentNode;
                      if (
                        listParent.tagName === "UL" ||
                        listParent.tagName === "OL"
                      ) {
                        // console.log(
                        //   "PUTTINGGGGGGGGGGGGCIRCLE FOR",
                        //   listParent.lastElementChild
                        // );
                        // listParent.lastElementChild.insertBefore(lineBreak, parent.nextSibling);
                        // listParent.lastElementChild.insertBefore(recommendationElement, lineBreak.nextSibling);
                        return;
                      }
                    }
                    if (
                      parent.tagName === "TD" ||
                      parent.tagName === "TR" ||
                      parent.tagName === "strong"
                    ) {
                      let tableParent = parent.closest("table");
                      console.log("TABLE DATA", parent);
                      if (tableParent) {
                        let tableRow = parent.closest("tr");
                        if (tableRow) {
                          // For example, insert after the last child of the row
                          // tableRow.appendChild(recommendationElement);
                        }
                      }
                    }
                    parent.insertBefore(
                      recommendationElement,
                      lineBreak.nextSibling
                    );
                  } else if (grandParent) {
                    grandParent.insertBefore(lineBreak, node.nextSibling);
                    grandParent.insertBefore(
                      recommendationElement,
                      lineBreak.nextSibling
                    );
                  } else {
                    // console.error(
                    //   "No valid parent found to insert the recommendation element!"
                    // );
                  }
                  node.textContent = textBefore + textAfter;
                }
              } else {
                const existingRecommendation = document.querySelector(
                  `[data-location="recommendation_${rec.id}"]`
                );

                if (existingRecommendation) {
                  const existingIndex =
                    existingRecommendation.getAttribute("data-index");

                  if (existingIndex !== (index + 1).toString()) {
                    console.log(
                      `Updating recommendation ${existingIndex} to new index ${
                        index + 1
                      }`
                    );
                    existingRecommendation.setAttribute(
                      "data-index",
                      index + 1
                    );
                  } else {
                    // console.log(
                    //   "Recommendation already exists with the correct index, skipping update."
                    // );
                  }
                } else {
                  const recommendationElement =
                    document.createElement("recommendation");
                  recommendationElement.setAttribute(
                    "data-location",
                    `recommendation_${rec.id}`
                  );
                  recommendationElement.setAttribute(
                    "data-recommendation-doc-id",
                    rec.doc_id
                  );
                  recommendationElement.setAttribute("data-index", index + 1);
                  recommendationElement.onclick = () => handleNavigation(rec);
                  let parent = node.parentNode;
                  let parentInnerHTML = parent.innerHTML;
                  parent.innerHTML = "";
                  parent.appendChild(recommendationElement);
                  recommendationElement.innerHTML = parentInnerHTML;
                }
              }

              return true;
            }
            currentOffset += textLength;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (let childNode of node.childNodes) {
              if (findMatchingNode(childNode)) return true;
            }
          }
          return false;
        };

        if (
          findMatchingNode(editorContainer) &&
          // rec.change_request_type === "Replace" &&
          String(rec.doc_id) === String(id)
        ) {
          const recommendationElement = editorContainer.querySelector(
            `[data-location="recommendation_${rec.id}"]`
          );
          if (recommendationElement) {
            // console.log(recommendationElement, "RECOMMENDATION ELEMENT");
            requestAnimationFrame(() => {
              const rect = recommendationElement.getBoundingClientRect();
              const editorContainerRect =
                editorContainer.getBoundingClientRect();
              // console.log("editorContainerRect.top", editorContainerRect.top);
              // console.log(
              //   "editorContainer.scrollTop",
              //   editorContainer.scrollTop
              // );
              // console.log("rect.top", rect.top);

              let y =
                rect.top - editorContainerRect.top + editorContainer.scrollTop;
              // console.log("Recommendation", index + 1, "here is y", y);

              while (existingYCoordinates.has(y)) {
                y += 10;
              }
              if (rec.y === -9999) {
              rec.y = y;
              existingYCoordinates.add(y);
              }
            });
          }
        } else {
          endPositions.push(rec);
        }
      });

      const editorContainerHeight = editorRef.current.scrollHeight ;
      const lastYPosition = editorContainerHeight + endPositions.length * 100;
      endPositions.forEach((rec, i) => {
        let y_position = editorContainerHeight + i * 30;

        while (existingYCoordinates.has(y_position)) {
          y_position += 10;
        }
        // rec.tempYPosition = y_position;
        // });
        // endPositions.sort((a, b) => a.tempYPosition - b.tempYPosition);
        // endPositions.forEach((rec) => {
        //   let y_position = rec.tempYPosition;

        if (
          rec.y === -9999
        //   acceptedArray.current.includes(rec.id) ||
        //   rejectedArray.current.includes(rec.id)
        //   // ||
        //   // rec.change_request_type == "Add"
        ) {
        rec.y = y_position;
        existingYCoordinates.add(y_position);
        }
      });
      let sortedRecommendations = updatedRecommendations;
      // console.log("UPDATED RECOMMENDATION", sortedRecommendations);
      // console.log("ACTIVE RECOMMENDATION", activeRecommendationData);
      // console.log("ACTIVE RECOMMENDATION ID", activeRecommendationId);
      if (!activeRecommendationData && showChangeRequest) {
        //TODO MADE CHANGES FOR CHEKING USER NAVIGATE CORRECT INDEX DOC>DOC_EDIT
        sortedRecommendations = sortRecommendationsByDocIdAndY(
          updatedRecommendations
        );
        setActiveRecommendationData(sortedRecommendations[0]);
        setActiveRecommendationId(sortedRecommendations[0].id);
      }

      setRecommendationData(sortedRecommendations);
    }
  };

  const sortRecommendationsByDocIdAndY = (recommendations) => {
    const groupedByDocId = {};
    recommendations.forEach((recommendation) => {
      const { doc_id } = recommendation;
      if (!groupedByDocId[doc_id]) {
        groupedByDocId[doc_id] = [];
      }
      groupedByDocId[doc_id].push(recommendation);
    });
    Object.keys(groupedByDocId).forEach((docId) => {
      groupedByDocId[docId].sort((a, b) => a.y - b.y);
    });
    const sortedRecommendations = [];

    recommendations.forEach((recommendation) => {
      if (
        !sortedRecommendations.some(
          (rec) => rec.doc_id === recommendation.doc_id
        )
      ) {
        sortedRecommendations.push(...groupedByDocId[recommendation.doc_id]);
      }
    });

    return sortedRecommendations;
  };

  //SORTING WELL
  // const sortRecommendationsByDocIdAndY = (recommendations) => {
  //   // Check if all recommendations have the same doc_id
  //   const allSameDocId = recommendations.every(
  //     (rec) => rec.doc_id === recommendations[0].doc_id
  //   );

  //   // If all recommendations have the same doc_id, proceed with sorting by y
  //   if (allSameDocId) {
  //     return recommendations.sort((a, b) => a.y - b.y);
  //   }

  //   // Otherwise, group by doc_id and then sort by y within each group
  //   const groupedByDocId = new Map();
  //   recommendations.forEach((recommendation) => {
  //     const { doc_id } = recommendation;
  //     if (!groupedByDocId.has(doc_id)) {
  //       groupedByDocId.set(doc_id, []);
  //     }
  //     groupedByDocId.get(doc_id).push(recommendation);
  //   });

  //   // Sort each group by the y value
  //   groupedByDocId.forEach((group) => {
  //     group.sort((a, b) => a.y - b.y);
  //   });

  //   // Flatten the groups into a single array while preserving the original doc_id order
  //   const sortedRecommendations = [];
  //   recommendations.forEach(({ doc_id }) => {
  //     if (groupedByDocId.has(doc_id)) {
  //       sortedRecommendations.push(...groupedByDocId.get(doc_id));
  //       groupedByDocId.delete(doc_id); // Ensure each group is added only once
  //     }
  //   });

  //   return sortedRecommendations;
  // };

  //HIGHLIGHT TEXT TO BE CHANGED
  const highlightText = () => {
    if (!requestData || !activeRecommendationData) {
      return;
    }
    const stripTags = (htmlString) => {
      let doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };
    let strippedActiveRecommendation = stripTags(
      activeRecommendationDataRef.current.previous_string
    );
    let normalizedRecommendation = strippedActiveRecommendation
      .replace(/\n/g, "")
      .replace(/\s+/g, "")
      // .replace(/)
      .toLowerCase();
    let parser = new DOMParser();
    let doc = parser.parseFromString(changedModelRef.current, "text/html");
    clearPreviousHighlights(doc);
    const normalizeTextContent = (node) =>
      node.textContent
        .replace(/<br\s*\/?>/gi, "")
        .replace(/&nbsp;/g, "")
        .replace(/\n/g, "")
        .replace(/\s+/g, "")
        .toLowerCase();
    let flatBodyText = normalizeTextContent(doc.body);
    let matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
    let matchEndIndex = matchStartIndex + normalizedRecommendation.length;

    if (
      matchStartIndex === -1 ||
      activeRecommendationData.change_request_type !== "Replace"
    ) {
      console.log("Body", flatBodyText);
      console.log("normalizedRecommendation", normalizedRecommendation);
      clearPreviousHighlights(doc);
      setModel(doc.documentElement.outerHTML);
      return;
    }

    let currentOffset = 0;
    const highlightMatchedText = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let originalText = node.textContent;
        let normalizedText = originalText.replace(/\s+/g, "").toLowerCase();
        let textLength = normalizedText.length;
        if (
          currentOffset < matchEndIndex &&
          currentOffset + textLength > matchStartIndex
        ) {
          // console.log("Original Text", originalText);
          // console.log("Checking text node:", originalText);
          let parent = node.parentNode;
          if (parent) {
            parent.innerHTML = `<mark>${originalText}</mark>`;
            // console.log("HIGHLIGHTED NODE", parent);
          }
          //WRAP ENTIRE PARENT
          // if (parent) {
          //   const markWrapper = document.createElement("mark");
          //   // markWrapper.classList.add("highlighted");
          //   parent.replaceWith(markWrapper);
          //   markWrapper.appendChild(parent);
          //   console.log("Wrapped parent in <mark>:", markWrapper);
          // }
        }
        currentOffset += textLength;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => {
          highlightMatchedText(childNode);
        });
      }
    };
    highlightMatchedText(doc.body);
    const updatedModel = doc.documentElement.outerHTML;
    setModel(updatedModel);
  };

  const clearPreviousHighlights = (doc) => {
    const originalInnerHTML = doc.body.innerHTML;
    const matches = originalInnerHTML.match(/<mark>(.*?)<\/mark>/g);
    if (matches) {
      // console.log(
      //   "Removed highlights:",
      //   matches.map((match) => match.replace(/<\/?mark>/g, ""))
      // );
    }
    doc.body.innerHTML = originalInnerHTML.replace(
      /<mark>(.*?)<\/mark>/g,
      "$1"
    );
  };

  useEffect(() => {
    highlightText();
  }, [activeRecommendationData, recommendationData]);

  const removeFloatingCircles = () => {
    const editorContainer = document.querySelector(".froala-editor");
    if (editorContainer) {
      const circles = editorContainer.querySelectorAll(".floating-circle");
      circles.forEach((circle) => circle.remove());
    }
  };

  //Handle Warning Pop Up
  const handleOpenResolveWarning = async () => {
    const data = await apiService.pendingRecommendation(requestData.request_id);
    // console.log("status", status);
    if (data.isPending) {
      setShowResolveWarning(true);
    } else {
      await handleResolveByte();
      setShowChangeRequest(false);
      navigate(`/home/document/${activeDocIdRef.current}`);
    }
  };
  const handleCloseResolveWarning = () => {
    // fetchData();
    setShowResolveWarning(false);
  };
  const handleResolveByte = async () => {
    await apiService.resolveByte(requestData.request_id);
    handleCloseResolveWarning();
    setShowChangeRequest(false);
    navigate(`/home/document/${activeDocIdRef.current}`);
  };
  const handlePrevious = () => {
    if (currentRecommendationIndex > 0) {
      updateModel(currentRecommendationIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentRecommendationIndex < recommendationDataRef.current.length - 1) {
      updateModel(currentRecommendationIndex + 1);
    }
  };

  const updateModel = async (newIndex) => {
    const recommendation = recommendationDataRef.current[newIndex];
    // console.log(recommendationDataRef.current, "recommendationData");
    setActiveRecommendationId(recommendation.id);
    setActiveRecommendationData(recommendation);
    // console.log("RECOMMENDATION IN UPDATE MODEL", recommendation);
    const currentDocId = recommendation.doc_id;
    setActiveDocId(currentDocId);

    if (String(id) !== String(currentDocId)) {
      // console.log(id !== currentDocId, "Here is result for check");
      const container = containerRef.current;
      if (container) {
        container.scrollTop = 0;
      }
      await handleDocumentChange(currentDocId, recommendation.doc_content);
    }
  };

  // Function to handle document content change and navigation
  const handleDocumentChange = async (docId, docContentUrl) => {
    handleUpdate();
    navigate(`/home/${byteId}/document-edit/${docId}`, { replace: true });
    setFileName(docContentUrl.substring(docContentUrl.lastIndexOf("/") + 1));
    const htmlResponse = await fetch(docContentUrl, { mode: "cors" });
    const htmlBlob = await htmlResponse.blob();
    const newHtmlContent = await htmlBlob.text();
    // console.log(docContentUrl, "HTML CONTENT", docId);
    // console.log("HTML CONTENT", newHtmlContent);
    setModel(newHtmlContent);
    setDBModel(newHtmlContent);
    // highlightText();
  };
  useEffect(() => {
    // console.log("Updated model OF BODY:", model);
  }, [model]);
  const handleOnTap = () => {
    setShowChangeRequest(false);
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
    setCurrentRecommendationIndex(-1);
    navigate(`/home/document/${id}`, {
      replace: true,
    });
  };

  //LOADING
  if (isLoading) {
    return (
      <div id="editor" className="froala-editor-section">
        <div id="toolbar-container" className="toolbar-container"></div>
        <div style={{ paddingBottom: "15px" }}>
          <EditorSkeleton
            height="40px"
            borderRadius="100px"
            padding="4px 0px"
          />
        </div>
        <div className="editor-suggestion">
          <div className="change-request">
            <EditorSkeleton width="100%" height="100vh" borderRadius="4px" />
          </div>
          <RecommendationSkeletonLoader count={4} />
        </div>
      </div>
    );
  }

  return (
    <div className="doc-editor">
      <ResolveChangeRequestPopUp
        isVisible={showResolveWarning}
        onClickLButton={handleResolveByte}
        onClickRButton={handleCloseResolveWarning}
        onClose={handleCloseResolveWarning}
        title="Open AiEdits Pending"
        subtitle="Do you still wish to resolve the Change Request?"
        lButtonText="Resolve CR"
        rButtonText="View AiEdits"
      />
      <div id="editor" className="froala-editor-section fade-in">
        {/* Toolbar Container */}
        <div id="toolbar-container" className="toolbar-container"></div>
        <div className="editor-suggestion" ref={containerRef}>
          <div className="change-request">
            {showChangeRequest && (
              <ChangeRequest
                onResolve={handleOpenResolveWarning}
                width={editorWidth ?? 300}
                requester={requestData?.sender ?? "loading"}
                date={requestData?.date_time ?? "Date"}
                message={requestData?.request_text ?? "Change byte"}
                aiEdits={`${
                  recommendationData && recommendationData.length > 0
                    ? recommendationData.findIndex(
                        (recommendation) =>
                          recommendation.id === activeRecommendationData?.id
                      ) + 1
                    : 0
                }/${recommendationData?.length ?? 0}`}
                onPrevious={handlePrevious}
                onNext={handleNext}
                onTap={handleOnTap}
              />
            )}

            {isLoading ? (
              <EditorSkeleton />
            ) : (
              // Froala Editor
              <div ref={editorRef}>
                <FroalaEditorComponent
                  tag="textarea"
                  model={model}
                  onModelChange={handleModelChange}
                  config={{
                    toolbarSticky: true,
                    editorClass: "froala-editor",
                    spellcheck: true,
                    attribution: true,
                    heightMin: 200,
                    heightMax: 800,
                    placeholderText: "Edit your content here...",
                    toolbarVisibleWithoutSelection: true,
                    charCounterCount: true,
                    toolbarContainer: "#toolbar-container",
                    pluginsEnabled: [
                      "fullscreen",
                      "codeBeautifier",
                      "align",
                      "image",
                      "link",
                      "fontFamily",
                      "fontSize",
                      "specialCharacters",
                      "lists",
                      "table",
                      "codeView",
                      "colors",
                      "backgroundColor",
                      "undo",
                      "redo",
                      "paragraphFormat",
                      "inlineStyle",
                      "inlineClass",
                      "clearFormatting",
                      "increaseIndent",
                    ],
                    inlineClasses: {
                      "fr-class-code": "Code",
                      // "fr-class-highlighted": "Highlighted",
                      // "fr-class-transparency": "Transparent",
                    },
                    toolbarButtons: [
                      "bold",
                      "italic",
                      "underline",
                      // "|",
                      "fontFamily",
                      // "|",
                      "fontSize",
                      "textColor",
                      "backgroundColor",
                      "strikeThrough",
                      // "inlineClass", //CODE VIEW
                      // "codeView",
                      "subscript",
                      // "superscript",
                      "specialCharacters",
                      // "|",
                      "paragraphFormat",
                      "alignLeft",
                      "alignCenter",
                      "alignRight",
                      "alignJustify",
                      "indent",
                      "outdent",
                      "formatOL",
                      "formatOLSimple",
                      // "formatOLAlpha", NOT SUPPORTED BY FROALA >> HAVE IT IN INCLUDED IN FORMATOL
                      "|",

                      "insertLink",
                      "insertImage",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                      "codeView",
                    ],

                    showColorsPopup: true,
                    fontFamilySelection: true,
                    fontSizeSelection: false,
                    paragraphFormatSelection: false,
                    // quickInsertTags: ['p', 'div'],
                    // // quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
                    // quickInsertButtons: ['image', 'table', 'ol', 'ul', 'myButton'],
                    // pluginsEnabled: ['quickInsert', 'image', 'table', 'lists'],
                    // quickInsertTags: [
                    //  'p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'blockquote', 'underline', 'insertImage', 'insertLink', 'insertTable', 'undo', 'redo'
                    // ],
                    events: {
                      initialized: function () {
                        isFirstContentChange.current = true;
                        const secondToolbar =
                          document.querySelector(".fr-second-toolbar");
                        if (secondToolbar) {
                          secondToolbar.remove();
                        }
                      },
                      contentChanged: async function () {
                        const updatedModel = this.html.get();
                        changedModelRef.current = updatedModel;
                        setRealTimeModel(updatedModel);
                        if (isFirstContentChange.current) {
                          isFirstContentChange.current = false;
                        } else {
                          handleContentChange(updatedModel);
                        }
                      },
                      "image.beforeUpload": async function (images) {
                        if (images.length) {
                          const imageFile = images[0];

                          try {
                            const response = await apiService.uploadImage(
                              imageFile,
                              id
                            );
                            if (response.status === "success") {
                              this.image.insert(
                                response.imageUrl,
                                null,
                                null,
                                this.image.get()
                              );
                            } else {
                              // console.error("Image upload failed");
                            }
                          } catch (error) {
                            // console.error("Error uploading image:", error);
                          }
                          return false;
                        }
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
          {isLoading ? (
            <RecommendationSkeletonLoader count={4} />
          ) : (
            <div className="suggestion-list" ref={suggestionListRef}>
              {recommendationData &&
                recommendationData
                  .sort((a, b) => {
                    if (!showChangeRequest) {
                      return a.y - b.y;
                    }
                    return 0;
                  })
                  .map((recommendation, index) => {
                    if (String(recommendation.doc_id) !== String(id)) {
                      return null;
                    }
                    const recommendationElement = document.querySelector(
                      `[data-location="recommendation_${recommendation.id}"]`
                    );
                    if (recommendationElement) {
                      const currentIndex = recommendationElement.getAttribute("data-index");
                      if (currentIndex !== (index + 1).toString()) {
                        recommendationElement.setAttribute("data-index", index + 1);
                      }
                    }
                    return (
                      <div
                        key={recommendation.id}
                        className="suggestion-card"
                        style={{
                          position: "relative",
                          left: `0px`,
                          top: `${recommendation.y}px`,
                          transition: "all 0.2s ease",
                          zindex: "1000",
                        }}
                        data-index={index + 1}
                      >
                        <SuggestionCardComponent
                          num={index + 1}
                          isLoading={true}
                          recommendationData={recommendation}
                          isRecommendationAccepted={acceptedRecommendations.includes(
                            recommendation.id
                          )}
                          isRecommendationRejected={rejectedRecommendations.includes(
                            recommendation.id
                          )}
                          isActive={
                            activeRecommendationData
                              ? activeRecommendationData.id ===
                                recommendation.id
                              : false
                          }
                          onTapAccept={() => {
                            if (recommendation.change_request_type === "Add") {
                              return addText(recommendation.id);
                            } else {
                              return replaceText();
                            }
                          }}
                          updateAcceptStatus={() => {
                            setAcceptedRecommendations((prev) => [
                              ...prev,
                              recommendation.id,
                            ]);
                          }}
                          onTapReject={() => {
                            setRejectedRecommendations((prev) => [
                              ...prev,
                              recommendation.id,
                            ]);
                          }}
                          onCoverTap={() => {
                            setActiveRecommendationData(recommendation);
                            setActiveRecommendationId(recommendation.id);
                            if (!showChangeRequest) {
                              setShowChangeRequest(false);
                              navigate(
                                `/home/${recommendation.byte_id}/document-edit/loading`,
                                { replace: true }
                              );
                            }
                          }}
                        />
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FunctionalEditor;
