import React, { useState, useEffect } from "react";
import "../editor-style.css";
import BuildDocLoadingComponent from "../../loading-screen/BuildDocLoading";

export default function KnowledgeKeeperDoc({ content, loading }) {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  //token-wise
  useEffect(() => {
    if (!content) return;
    
    const words = content.split(" ");
    if (index < words.length) {
      const timeout = setTimeout(() => {
        setDisplayText(words.slice(0, index + 1).join(" "));
        setIndex((prev) => prev + 1);
      }, 5);

      return () => clearTimeout(timeout);
    }
  }, [index, content]);

  return (
    <div className="knowledge-keeper-doc">
      {loading ? (
        <BuildDocLoadingComponent />
      ) : (
        <div
          className="knowledge-keeper-content fade-in"
          dangerouslySetInnerHTML={{
            __html: `<span class='new-content'>${displayText}</span>`,
          }}
        />
      )}
    </div>
  );
}
