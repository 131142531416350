export const REMOVE_CHARACTERS = [
    "<br>",
    "<br/>",
    "-",
    "‐", // Hyphen (U+2010)
    "‑", // Non-breaking hyphen (U+2011)
    "‒", // Figure dash (U+2012)
    "–", // En dash (U+2013)
    "—", // Em dash (U+2014)
    "―", // Horizontal bar (U+2015)
    "+",
    "＋", // Full-width plus sign (U+FF0B)
    "\\",
    "＼", // Full-width backslash (U+FF3C)
    '"',
    "“", // Left double quotation mark (U+201C)
    "”", // Right double quotation mark (U+201D)
    "„", // Double low-9 quotation mark (U+201E)
    "‟", // Double high-reversed-9 quotation mark (U+201F)
    "’", // Right single quotation mark (U+2019)
    "‘", // Left single quotation mark (U+2018)
    "‚", // Single low-9 quotation mark (U+201A)
    "‛", // Single high-reversed-9 quotation mark (U+201B)
    "'",
    "′", // Prime (U+2032)
    "″", // Double prime (U+2033)
    "‶", // Reversed double prime (U+2036)
    "＇", // Full-width apostrophe (U+FF07)
    "&nbsp;",
    "\n",
    "\r", // Carriage return
    "\t", // Tab space
    " ",
    " ", // Non-breaking space (U+00A0)
    " ", // Thin space (U+2009)
    " ", // Hair space (U+200A)
    " ", // Narrow no-break space (U+202F)
    " ", // Medium mathematical space (U+205F)
    "　" // Full-width space (U+3000)
  ];
  