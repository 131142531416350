import React, { useState } from "react";
import "./popup-style.css";
import SvgCloseCross from "../../icons/CloseCross";
import HeaderSubHeadingComponent from "./CustomComponets";

const BuildDocPopUp = ({ isVisible, onClose, onClick }) => {
  const [docInstructions, setInstruction] = useState("");
  const [docDescription, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  if (!isVisible) return null;

  //Handle Close
  const handleClose = () => {
    setInstruction("");
    setDescription("");
    onClose();
  };

  const handleonSubmit = async () => {
    try {
      setLoading(true);
      const success = await onClick(docInstructions, docDescription, [
        {
          role: "user",
          content: "Generate the document",
        },
      ]);
      if (success) {
        setLoading(false);
      }
    } catch (e) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  return (
    <div className="build-doc-overlay" onClick={(e) => e.stopPropagation()}>
      <div className="build-doc-content" onClick={(e) => e.stopPropagation()}>
        <div className="show-row-space-between-header">
          <HeaderSubHeadingComponent
            title="Build Document with Ai"
            subtitle="Relax, Ai will help to build your document"
          />
          <SvgCloseCross className="cross-icon" onClick={handleClose} />
        </div>
        <div className="text-form-field-popup">
          About The Document
          <textarea
            className="add-request-form"
            placeholder="Describe the document’s purpose & audience. "
            value={docDescription}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="text-form-field-popup">
          Instruction
          <textarea
            className="add-request-form"
            placeholder="Any instructions for your writing assistant?"
            value={docInstructions}
            onChange={(e) => setInstruction(e.target.value)}
          />
        </div>
        <div className="show-row-space-between-header">
          <span onClick={handleClose} className="skip-text">
            Skip
          </span>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              disabled={
                !docDescription.trim() || !docInstructions.trim() || loading
              }
              className={`popup-button ${
                !docDescription.trim() || !docInstructions.trim()
                  ? "disabled-button"
                  : ""
              }`}
              onClick={handleonSubmit}
            >
              Submit
              {loading && <span className="button-loader"></span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildDocPopUp;
