import React, { useState, useRef, useEffect } from "react";
import "./AskChatbot.css";
import { X, Send } from "lucide-react";
import { apiService } from "../../services/apiService";

export default function ChatBoxCard({ teamspaceId, documentId, onClose }) {
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "Hello! How can I help you?",
    },
  ]);

  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef(null);

  //Update scroller position
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  //Send Message
  const sendMessage = async () => {
    try {
      if (input.trim() === "") return;
      setIsSending(true);
      const userMessage = { role: "user", content: input };
      setMessages((prev) => [...prev, userMessage]);
      setInput("");
      const filteredMessages = messages.filter(
        (msg) =>
          !(
            msg.role === "assistant" &&
            msg.content === "Hello! How can I help you?"
          ) 
      );
      const loadingMessage = {
        content: "Processing your request...",
        role: "assistant",
      };
      setMessages((prev) => [...prev, loadingMessage]);
      const response = await apiService.askBotQuery(teamspaceId, documentId, [
        ...filteredMessages,
        userMessage,
      ]);
      if (response.status === "SUCCESS") {
        setMessages((prev) =>
            prev.filter(
              (msg) => msg.content !== "Processing your request..."
            )
          );
        setMessages((prev) => [
          ...prev,
          { content: response.conversation, role: "assistant" },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            content: "Hmm... couldn't process that. Try again?",
            role: "assistant",
          },
        ]);
      }
    } catch (e) {
      setMessages((prev) => [
        ...prev,
        {
          content: "Something went wrong. Please try again.",
          role: "assistant",
        },
      ]);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="chatbot-container-card">
      <div className="chatbot-header-card">
        <span></span> <X className="close-icon-card" onClick={onClose} />
      </div>
      <div className="chatbot-messages-card">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message-card ${msg.role}`}
            dangerouslySetInnerHTML={{ __html: msg.content }}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chatbot-input-card">
        <textarea
          className="chatbot-textarea-card"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!isSending) sendMessage();
            }
          }}
        />
        <button
          onClick={sendMessage}
          className={`send-button-card ${isSending ? "disabled" : ""}`}
          disabled={isSending}
        >
          <Send size={18} className="rotated-icon-card" />
        </button>
      </div>
    </div>
  );
}
