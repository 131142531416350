import { useState } from "react";
import { MessageCircle } from "lucide-react";
import "./AskChatbot.css";
import ChatBoxCard from "./ChatBoxCard";

export default function AskChatbot({ teamspaceId, documentId }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="ask-chatbot-container">
      {open && (
        <ChatBoxCard
          teamspaceId={teamspaceId}
          documentId={documentId}
          onClose={() => setOpen(!open)}
        />
      )}
      <button className="ask-chatbot-button" onClick={() => setOpen(!open)}>
        <MessageCircle size={24} />
      </button>
    </div>
  );
}
