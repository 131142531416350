import React, { useEffect } from "react";
import "./style.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LoginPage from "./pages/loginPage";
import HomePage from "./pages/homePage";
import { useAuth } from "./components/ProtectedRoute/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HubspotForm from "./pages/ContactUs";
import ServerDown from "./components/FallBack/ServerDown/ServerDown";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import {
  attachBeforeUnloadListener,
  detachBeforeUnloadListener,
  startSyncInterval,
} from "./custom/syncDocument";
import UnsavedChangesHandler from "./components/PopUps/UnsavedChangeHandler";
import { ThemeProvider } from "./components/Theme/ThemeContext";

function App() {
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    startSyncInterval();
    attachBeforeUnloadListener();
    return () => {
      detachBeforeUnloadListener();
    };
  }, []);
  
  return (
    <>
      <ThemeProvider>
      <ToastContainer />
      <UnsavedChangesHandler />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/home/all-requests/open-byte" />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route
            path="/service-down"
            element={
              <ServerDown
                error="Server Down"
                retry={() =>
                  window.location.assign(
                    isLoggedIn ? "/home/all-requests/open-byte" : "/"
                  )
                }
              />
            }
          />
          <Route path="/home/*" element={<HomePage />} />
          <Route path="/contact-us" element={<HubspotForm />} />
        </Routes>
      </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
