import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import "./skeleton-loader-style.css";

export default function ProcessByteLoadingScreen({width, height}) {
  return (
    <div
      className="process-byte-screen"
      style={{ height: height-10, width: width }}
    >
      <DotLottieReact
        className="process-byte-animation"
        src="/animation.lottie"
        loop
        autoplay
      />
    </div>
  );
}
