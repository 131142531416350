import React, { useEffect, useRef, useState } from "react";
import "./editor-style.css";
import SvgCloseCross from "../../icons/CloseCross";
import SvgDoneCheck from "../../icons/DoneCheck";
import { apiService } from "../../services/apiService";
import { toast } from "react-toastify";

const SuggestionCardComponent = ({
  num,
  recommendationData,
  onTapAccept,
  updateAcceptStatus,
  onTapReject,
  isRecommendationAccepted,
  isRecommendationRejected,
  isActive,
  onCoverTap,
}) => {
  const contentRef = useRef(null);
  useEffect(() => {
    if (!isActive && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isActive]);

  const [isAccepted, setIsAccepted] = useState(isRecommendationAccepted);
  const [isRejected, setIsRejected] = useState(isRecommendationRejected);
  const [isAcceptLoading, setIsAccpetLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  const {
    id,
    section_main_heading1,
    section_main_heading2,
    section_main_heading3,
    section_main_heading4,
    change_request_text,
  } = recommendationData;

  let filteredContent = change_request_text;

  const removeHeadingAndElement = (content, heading) => {
    if (!heading) return content;
    const regex = new RegExp(
      `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
      "gi"
    );
    return content.replace(regex, "");
  };

  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading1
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading2
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading3
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading4
  );

  // Handle Recommendation Action
  const handleModifyRecommendation = async (action) => {
    const response = await apiService.acceptRejectByte(
      recommendationData.byte_id,
      recommendationData.doc_id,
      "5",
      "h1",
      section_main_heading1,
      recommendationData.previous_string,
      action,
      id
    );
    console.log(response);
    return response;
  };

  return (
    <div
      className={`suggestion-container ${isActive ? "active" : ""}`}
      onClick={() => {
        if (!isActive) {
          onCoverTap();
          console.log("FUNCTION CALLED");
        }
      }}
    >
      <div className="suggestion-header">
        <span className="suggestion-num">{num}</span>
        <span className="suggestion-title">
          {recommendationData.change_request_type}
        </span>
      </div>
      <div
        className={`suggestion-content ${isActive ? "active" : ""}`}
        ref={contentRef}
      >
        <div dangerouslySetInnerHTML={{ __html: filteredContent }} />
      </div>
      {isActive &&
        (!isAccepted && !isRejected ? (
          <div className="suggestion-actions">
            <button
              className={`accept-button ${isAcceptLoading ? "disabled" : ""}`}
              onClick={async (e) => {
                e.stopPropagation();
                setIsAccpetLoading(true);
                const acceptResult = await onTapAccept();
                if (acceptResult === true) {
                  try {
                    const response = await handleModifyRecommendation(
                      "ACCEPTED"
                    );
                    if (response?.status === "success") {
                      setIsAccepted(true);
                      updateAcceptStatus();
                    } else {
                      // toast.error("Try again");
                    }
                  } catch (e) {
                    toast.error("Please try again.");
                  } finally {
                    setIsAccpetLoading(false);
                  }
                } else {
                  toast.error("Text not found");
                  setIsAccpetLoading(false);
                }
              }}
              disabled={isAcceptLoading}
            >
              <SvgDoneCheck className="white-icon" /> Accept
              {isAcceptLoading && <span className="loading-circle"></span>}
            </button>
            <button
              className={`reject-button ${isRejectLoading ? "disabled" : ""}`}
              onClick={async (e) => {
                e.stopPropagation();
                setIsRejectLoading(true);
                try {
                  const response = await handleModifyRecommendation("REJECTED");
                  if (response?.status === "success") {
                    setIsRejected(true);
                    onTapReject();
                  } else {
                    // toast.error("Try again");
                  }
                } catch (error) {
                  console.error("Error during rejection:", error);
                  toast.error("An error occurred. Please try again.");
                } finally {
                  setIsRejectLoading(false);
                }
              }}
              disabled={isRejectLoading}
            >
              <SvgCloseCross className="grey-icon" /> Reject
              {isRejectLoading && <span className="loading-circle"></span>}
            </button>
          </div>
        ) : (
          <div>
            {isAccepted && (
              <button className="final-button">
                <SvgDoneCheck className="final-icon" /> Accepted
              </button>
            )}
            {isRejected && (
              <button className="final-button">
                <SvgCloseCross className="final-icon" /> Rejected
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default SuggestionCardComponent;
