import React, { useState, useEffect } from "react";
import { FaRegMeh } from "react-icons/fa";
import "./Fallback.css";

const ServerDown = ({ error, retry }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [retryAttempt, setRetryAttempt] = useState(0); // Track retry attempts
  useEffect(() => {
    if (sessionStorage.getItem("isRefreshed")) {
      retry();
      sessionStorage.removeItem("isRefreshed");
    }
    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [retry, retryAttempt]);
  // This function will update the state based on the online/offline status
  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
 if(navigator.onLine)  { handleRetry();}
  };

  // Register event listeners when the component mounts
  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  // Trigger retry when "Retry" button is clicked
  const handleRetry = () => {
    setRetryAttempt((prev) => prev + 1); // Increment retry count to trigger useEffect
    retry(); // Call the retry function passed from parent
  };

  if (!isOnline) {
    return (
      <div className="server-down-container">
        <div className="server-down-content">
          <div className="loader"></div>
          <h2 className="server-down-title">No Internet Connection</h2>
          <div className="server-down-loader"></div>
          <div className="server-down-actions"></div>
          <button className="retry-button" onClick={handleRetry}>
            Retry
          </button>
        </div>
      </div>
    );
  }

  // If online (server down or other issue), show the original "Server Down" UI
  return (
    <div className="server-down-container">
      <div className="server-down-content">
        <div className="server-down-icon">
          <FaRegMeh size={60} color="#FF6B6B" />
        </div>
        <h2 className="server-down-title">Sorry, we have a problem</h2>
        <p className="server-down-message">
          Don’t worry, it’s not your fault. We are already looking into it.
          Please come back in a few hours.
        </p>
        <div className="server-down-loader">
          <span className="working-text">Please Wait</span>
          <span className="dots">...</span>
        </div>
        <div className="server-down-actions">
          <p>
            or send an email to{" "}
            <a href="mailto:rahul@knowledgekeeper.ai">
              rahul@knowledgekeeper.ai
            </a>
          </p>
        </div>
        <button className="retry-button" onClick={handleRetry}>
          Retry
        </button>
      </div>
    </div>
  );
};

export default ServerDown;
