import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkToken = () => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token); 
  };

  useEffect(() => {
    checkToken(); 
    const handleStorageChange = () => {
      checkToken(); 
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); 

  const logout = () => {
    localStorage.removeItem("authToken");
    setIsLoggedIn(false);  
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
