import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/inline_class.min.js";

import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
// import "froala-editor/js/plugins/video.min.js";
// import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
// import "froala-editor/js/plugins/quick_insert.min.js";
// import "froala-editor/css/plugins/quick_insert.min.css";

// import "froala-editor/js/plugins/print.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/froala_style.min.css";
import ChangeRequest from "./changeRequest.js";
import SuggestionCardComponent from "./SuggestionCardComponent";
import "./editor-style.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import RecommendationSkeletonLoader from "../loading-screen/RecommendationSkeleton.js";
import EditorSkeleton from "../loading-screen/EditorSkeleton.js";
import ResolveChangeRequestPopUp from "../PopUps/ResolveChangeRequestPopUp.js";
import { toast } from "react-toastify";
import NotificationBar from "./NotificationBar.js";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
const FunctionalEditor = () => {
  const debounceDelay = 3000; // 3 seconds
  const [requestData, setRequestData] = useState(null);
  const [model, setModel] = useState("Loading");
  const [showChangeRequest, setShowChangeRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(true);
  const [recommendationData, setRecommendationData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [activeDocId, setActiveDocId] = useState("");
  const isFirstContentChange = useRef(true);
  const [showResolveWarning, setShowResolveWarning] = useState(false);
  const [showAutoRejectWarning, setshowAutoRejectWarning] = useState(false);
  const changedModelRef = useRef(model);
  const [currentRecommendationIndex, setCurrentRecommendationIndex] =
    useState(-1);
  const [isDirty, setIsDirty] = useState(false);
  const acceptedArray = useRef([]);
  const rejectedArray = useRef([]);
  const activeFileName = useRef(fileName);
  const activeDocIdRef = useRef(activeDocId);
  const [activeRecommendationData, setActiveRecommendationData] =
    useState(null);
  const activeRecommendationDataRef = useRef(activeRecommendationData);
  const [sortedRecommendationData, setSortedRecommendationData] =
    useState(null);
  const dBModelRef = useRef("");
  const recommendationDataRef = useRef(recommendationData);
  const [editorWidth, setEditorWidth] = useState(850);
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { id, byteId } = useParams();

  useEffect(() => {
    if (location.pathname.includes("document-edit")) {
      setShowChangeRequest(true);
    } else if (location.pathname.includes("document")) {
      setShowChangeRequest(false);
      if (String(activeDocId) !== String(id)) {
        setIsLoading(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!showChangeRequest || String(activeDocId) !== String(id)) {
      fetchData();
    }
    setActiveDocId(id);
    isFirstContentChange.current = true;
  }, [location]);

  //FETCH DOCUMENT DATA
  const fetchData = async () => {
    try {
      // setModel("Loading");
      if (location.pathname.includes("document-edit")) {
        await handleByteBasedRecommendation();
      } else if (location.pathname.includes("document")) {
        await handleDocumentBasedRecommendation();
      }
    } catch (error) {
      setIsLoading(true);
    }
  };

  // Function to handle Byte-based recommendations
  const handleByteBasedRecommendation = async () => {
    try {
      const response = await apiService.getRecommendationForByte(byteId);
      if (response) {
        const url = determineDocumentURL(response);
        const htmlContent = await fetchHTMLContent(url);
        processByteBasedDocuments(response);
        setFileName(url.substring(url.lastIndexOf("/") + 1));
        setRequestData(response.data);
        setModel(htmlContent);
        dBModelRef.current = htmlContent;
        setIsLoading(false);
      }
    } catch (e) {
      toast.error("Invalid Document! Please try again");
      navigate("/home/all-requests/open-byte");
    }
  };

  // Function to determine document URL
  const determineDocumentURL = (response) => {
    if (!activeRecommendationDataRef.current) {
      navigate(
        `/home/${byteId}/document-edit/${response.data.documents[0].doc_id}`,
        { replace: true }
      );
      return response.data.documents[0].doc_content;
    } else {
      navigate(
        `/home/${byteId}/document-edit/${activeRecommendationDataRef.current.doc_id}`,
        { replace: true }
      );
      return activeRecommendationDataRef.current.doc_content;
    }
  };

  // Function to process byte-based documents
  const processByteBasedDocuments = (response) => {
    if (response.data.documents && response.data.documents.length > 0) {
      let allRecommendations = [];
      for (let i = 0; i < response.data.documents.length; i++) {
        const document = response.data.documents[i];
        const { doc_id, doc_content, recommendations } = document;

        const mappedRecommendations = recommendations.map((rec) => ({
          ...rec,
          doc_content,
          byte_id: response.data.request_id,
          doc_id,
          x: 0,
          y: -9999,
        }));
        allRecommendations.push(...mappedRecommendations);
      }
      setRecommendationData(JSON.parse(JSON.stringify(allRecommendations)));
    }
  };

  // Function to handle document-based recommendations
  const handleDocumentBasedRecommendation = async () => {
    // const container = containerRef.current;
    // if (container) container.scrollTop = 0;
    const response = await apiService.getRecommendationSingleDoc(id);
    if (response) {
      const url = response.data.document.doc_content;
      const htmlContent = await fetchHTMLContent(url);
      processDocumentBytes(response);
      setRequestData(response);
      setFileName(url.substring(url.lastIndexOf("/") + 1));
      setModel(htmlContent);
      setActiveRecommendationData(null);
      dBModelRef.current = htmlContent;
      setIsLoading(false);
    }
  };

  // Function to process document bytes
  const processDocumentBytes = (response) => {
    const { document } = response.data;
    if (document.bytes && document.bytes.length > 0) {
      const doc_id = document.doc_id;
      const mappedRecommendations = document.bytes.flatMap((byte) =>
        byte.recommendations.map((rec) => ({
          ...rec,
          doc_content: document.doc_content,
          byte_id: byte.byteId,
          doc_id,
          x: 0,
          y: -9999,
        }))
      );
      setRecommendationData(mappedRecommendations);
    } else {
      setRecommendationData([]);
    }
  };

  // Helper function to fetch and parse HTML content
  const fetchHTMLContent = async (url) => {
    const cacheBuster = `?_=${new Date().getTime()}`;
    const fullUrl = url.includes("?")
      ? `${url}&${cacheBuster}`
      : `${url}${cacheBuster}`;

    const htmlResponse = await fetch(fullUrl, {
      mode: "cors",
      cache: "no-store",
    });
    if (!htmlResponse.ok) {
      return; //ADDED FOR GETTING DOC REMOVED THROW
    }
    const htmlText = await htmlResponse.text();
    return htmlText;
  };

  //Handle size of change Request Header
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setEditorWidth(entry.contentRect.width);
      }
    });
    if (editorRef.current) {
      resizeObserver.observe(editorRef.current);
    }
    return () => {
      if (editorRef.current) {
        resizeObserver.unobserve(editorRef.current);
      }
    };
  }, [editorRef, model]);

  useEffect(() => {
    if (!activeRecommendationData || !showChangeRequest) return;

    const container = containerRef.current;
    if (container) {
      const activeRecommendation = recommendationData.find(
        (rec) => rec.id === activeRecommendationData.id
      );

      const delay = 500;

      const scrollWithDelay = () => {
        if (activeRecommendation) {
          const scrollPosition = activeRecommendation.y - 200;
          container.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        } else {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }
      };

      setTimeout(scrollWithDelay, delay);
    }
  }, [
    activeRecommendationData,
    currentRecommendationIndex,
    // recommendationData,
    // showChangeRequest,
  ]);

  useEffect(() => {
    activeDocIdRef.current = activeDocId;
  }, [activeDocId]);

  useEffect(() => {
    activeRecommendationDataRef.current = activeRecommendationData;
  }, [activeRecommendationData]);

  useEffect(() => {
    recommendationDataRef.current = recommendationData;

    const sortedRecommendationDataList =
      sortRecommendationsByDocIdAndY(recommendationData);
    setSortedRecommendationData(sortedRecommendationDataList);
  }, [recommendationData]);

  useEffect(() => {
    activeFileName.current = fileName;
  }, [fileName]);

  const uploadDocument = async (newContent) => {
    const cleanedContent = removeMarkTags(newContent);
    if (cleanedContent !== dBModelRef.current) {
      const htmlBlob = new Blob([cleanedContent], { type: "text/html" });
      const htmlFile = new File([htmlBlob], activeFileName.current, {
        type: "text/html",
        lastModified: Date.now(),
      });
      await apiService.uploadDocument(htmlFile, activeDocIdRef.current);
      dBModelRef.current = cleanedContent;
      setIsDirty(false);
    }
  };

  const debouncedUpload = debounce((newContent) => {
    uploadDocument(newContent);
  }, debounceDelay);

  const handleBeforeUnload = (event) => {
    if (isDirty) {
      uploadDocument(changedModelRef.current);
      event.preventDefault();
      event.returnValue = "You have unsaved changes. Are you sure to leave?";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, location]);

  const handleUpdate = () => {
    if (isDirty) {
      uploadDocument(changedModelRef.current);
    }
  };

  const removeMarkTags = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const marks = tempElement.querySelectorAll("mark");
    const brTags = tempElement.querySelectorAll(`br[data-location]`);
    const spanTags = tempElement.querySelectorAll(`span[data-location]`);

    marks.forEach((mark) => {
      const parent = mark.parentNode;
      while (mark.firstChild) {
        parent.insertBefore(mark.firstChild, mark);
      }
      parent.removeChild(mark);
    });

    brTags.forEach((br) => {
      br.remove();
    });
    spanTags.forEach((span) => {
      span.remove();
    });
    const froalaTag = tempElement.querySelector(
      'p a[title="Froala Editor"]'
    )?.parentNode;
    if (froalaTag) {
      froalaTag.remove();
    }
    return tempElement.innerHTML;
  };

  const handleContentChange = (newContent) => {
    setIsDirty(true);
    debouncedUpload(newContent);
  };

  //Recommendation Functionality
  useEffect(() => {
    if (requestData) {
      changedModelRef.current = model;
    }
  }, [model]);

  useEffect(() => {
    if (
      activeRecommendationData &&
      !acceptedArray.current.includes(activeRecommendationData.id) &&
      !rejectedArray.current.includes(activeRecommendationData.id)
    ) {
      highlightText();
    }
  }, [
    activeRecommendationData,
    recommendationData,
    // currentRecommendationIndex,
  ]);

  useEffect(() => {
    if (recommendationData) {
      processAllRecommendations();
    }
  }, [recommendationData, fileName]);

  //AUTO REJECT RECOMMENDATION
  const rejectHiddenRecommendations = async () => {
    for (let recommendation of recommendationData) {
      if (
        recommendation.y === "hide" &&
        !rejectedArray.current.includes(recommendation.id)
      ) {
        try {
          setshowAutoRejectWarning(true);
          // const response = await apiService.acceptRejectByte(
          //   recommendation.byte_id,
          //   recommendation.doc_id,
          //   "h1",
          //   recommendation.section_main_heading1,
          //   recommendation.previous_string,
          //   "REJECTED",
          //   recommendation.id,
          //   "Rejected By FE"
          // );
          // if (response.success) {
          //   rejectedArray.current.push(recommendation.doc_id);
          // }
        } catch (error) {
        } finally {
          setTimeout(() => {
            setshowAutoRejectWarning(false);
          }, 300);
        }
      }
    }
  };
  useEffect(() => {
    if (recommendationData && showChangeRequest) {
      rejectHiddenRecommendations();
    }
  }, [recommendationData, activeRecommendationData, sortedRecommendationData]);

  useEffect(() => {
    if (recommendationData && activeRecommendationData) {
      const newIndex = sortedRecommendationData.findIndex(
        (recommendation) => recommendation.id === activeRecommendationData.id
      );

      if (newIndex !== -1) {
        setCurrentRecommendationIndex(newIndex);
      }
    }
  }, [activeRecommendationData, sortedRecommendationData]);

  //ADD RECOMMENDATION
  const addText = (recommendationId) => {
    if (!requestData) return false;
    if (activeRecommendationData) {
      const {
        section_main_heading1,
        section_main_heading2,
        section_main_heading3,
        section_main_heading4,
        change_request_text,
        previous_string,
      } = activeRecommendationData;

      let filteredContent = change_request_text;
      const removeHeadingAndElement = (content, heading) => {
        if (!heading) return content;
        const regex = new RegExp(
          `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
          "gi"
        );
        return content.replace(regex, "");
      };
      //Remove previous string text
      const removeMatchingNodes = (content, previousString) => {
        if (!previousString) return content;

        const contentContainer = document.createElement("div");
        contentContainer.innerHTML = content;

        const previousContainer = document.createElement("div");
        previousContainer.innerHTML = previousString;

        // Function to find and remove matching nodes
        const removeMatchingContent = (contentParent, previousParent) => {
          const previousChildren = Array.from(previousParent.childNodes);
          previousChildren.forEach((previousChild) => {
            if (previousChild.nodeType === Node.ELEMENT_NODE) {
              const tagName = previousChild.tagName;
              const matchingNodes = Array.from(
                contentParent.querySelectorAll(tagName)
              ).filter(
                (node) =>
                  node.textContent.trim() === previousChild.textContent.trim()
              );
              matchingNodes.forEach((node) => node.remove());
              removeMatchingContent(contentParent, previousChild);
            }
          });
        };
        removeMatchingContent(contentContainer, previousContainer);
        const removeEmptyNodes = (parent) => {
          const allNodes = parent.querySelectorAll("*");
          allNodes.forEach((node) => {
            if (
              (!node.textContent.trim() && !node.hasChildNodes()) ||
              (node.textContent.trim() === "" &&
                node.querySelectorAll("*").length === 1 &&
                node.querySelectorAll("span").length > 0) ||
              (node.tagName === "UL" &&
                node.querySelectorAll("li").length === 0)
            ) {
              node.remove();
            }
          });
        };
        removeEmptyNodes(contentContainer);
        return contentContainer.innerHTML;
      };
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading1
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading2
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading3
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading4
      );
      filteredContent = removeMatchingNodes(filteredContent, previous_string);

      const parser = new DOMParser();
      const doc = parser.parseFromString(changedModelRef.current, "text/html");
      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );
      // const lineBreakElement = doc.querySelector(
      //   `br[data-location="br_${recommendationId}"]`
      // );

      if (recommendationElement) {
        const parent = recommendationElement.parentNode;
        if (parent) {
          recommendationElement.remove();
          const tempContainer = document.createElement("div");
          tempContainer.innerHTML = filteredContent;
          const nextSibling = parent.nextSibling;
          if (nextSibling) {
            parent.parentNode.insertBefore(tempContainer, nextSibling);
          } else {
            parent.innerHTML += filteredContent;
          }
          const newModel = doc.documentElement.outerHTML;
          setModel(newModel);
          uploadDocument(newModel);
          // setIsDirty(true);
          return true;
        }
      }
    }
    return false;
  };

  //REJECT RECOMMENDATION
  const rejectRecommendation = (recommendationId) => {
    if (!requestData) return false;
    if (activeRecommendationData) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(changedModelRef.current, "text/html");
      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );
      // const lineBreakElement = doc.querySelector(
      //   `br[data-location="br_${recommendationId}"]`
      // );

      if (recommendationElement) {
        const parent = recommendationElement.parentNode;
        if (parent) {
          recommendationElement.remove();
          clearPreviousHighlights(doc);
          const newModel = doc.documentElement.outerHTML;
          changedModelRef.current = newModel;
          setModel(newModel);
          setIsDirty(true);
          return true;
        }
      }
    }
    return false;
  };

  //REPLACE RECOMMENDATION
  const replaceText = (recommendationId) => {
    if (!requestData) return false;
    let parser = new DOMParser();
    let doc = parser.parseFromString(changedModelRef.current, "text/html");
    const recommendation = activeRecommendationData;

    if (recommendation) {
      const {
        section_main_heading1,
        section_main_heading2,
        section_main_heading3,
        section_main_heading4,
        change_request_text,
        previous_string,
      } = recommendation;

      let filteredContent = change_request_text;
      const removeMatchingNodes = (content, previousString) => {
        if (!previousString) return content;
        const contentContainer = document.createElement("div");
        contentContainer.innerHTML = content;

        const previousContainer = document.createElement("div");
        previousContainer.innerHTML = previousString;
        const removeMatchingContent = (contentParent, previousParent) => {
          const previousChildren = Array.from(previousParent.childNodes);
          previousChildren.forEach((previousChild) => {
            if (previousChild.nodeType === Node.ELEMENT_NODE) {
              const tagName = previousChild.tagName;
              const matchingNodes = Array.from(
                contentParent.querySelectorAll(tagName)
              ).filter(
                (node) =>
                  node.textContent.trim() === previousChild.textContent.trim()
              );
              matchingNodes.forEach((node) => node.remove());
              removeMatchingContent(contentParent, previousChild);
            }
          });
        };
        removeMatchingContent(contentContainer, previousContainer);
        const removeEmptyNodes = (parent) => {
          const allNodes = parent.querySelectorAll("*");
          allNodes.forEach((node) => {
            if (
              (!node.textContent.trim() && !node.hasChildNodes()) ||
              (node.textContent.trim() === "" &&
                node.querySelectorAll("*").length === 1 &&
                node.querySelectorAll("span").length > 0) ||
              (node.tagName === "UL" &&
                node.querySelectorAll("li").length === 0)
            ) {
              node.remove();
            }
          });
        };
        removeEmptyNodes(contentContainer);
        return contentContainer.innerHTML;
      };
      const removeHeadingAndElement = (content, heading) => {
        if (!heading) return content;
        const regex = new RegExp(
          `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
          "gi"
        );
        return content.replace(regex, "");
      };
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading1
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading2
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading3
      );
      filteredContent = removeHeadingAndElement(
        filteredContent,
        section_main_heading4
      );
      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );
      const recommendationParts = doc.querySelectorAll(
        `span[data-location="recommendation_part${recommendationId}"]`
      );

      if (recommendationElement) {
        // const recommendationValue = recommendationElement.innerHTML.trim();
        let parent = recommendationElement.parentNode;
        if (parent) {
          const htmlString = filteredContent;
          let tempDiv = document.createElement("div");
          if (htmlString) {
            tempDiv.innerHTML = htmlString.trim();
          }
          function removeEmptyNodes(element) {
            const children = Array.from(element.childNodes);
            for (const child of children) {
              if (
                child.nodeType === Node.ELEMENT_NODE &&
                (child.tagName === "P" || child.tagName === "SPAN") &&
                child.innerHTML.trim() === ""
              ) {
                element.removeChild(child);
              } else if (
                child.nodeType === Node.TEXT_NODE &&
                child.textContent.trim() === ""
              ) {
                element.removeChild(child);
              } else if (child.nodeType === Node.ELEMENT_NODE) {
                removeEmptyNodes(child);
                if (child.innerHTML.trim() === "") {
                  element.removeChild(child);
                }
              }
            }
          }
          removeEmptyNodes(tempDiv);
          if (
            tempDiv.firstChild &&
            tempDiv.firstChild.nodeType === Node.ELEMENT_NODE &&
            (tempDiv.firstChild.tagName === "UL" ||
              tempDiv.firstChild.tagName === "OL" ||
              tempDiv.firstChild.tagName === "TABLE")
          ) {
            const newParent = parent.nextSibling;
            if (newParent) {
              newParent.innerHTML = "";
              const listItems = tempDiv.firstChild.querySelectorAll("li");
              listItems.forEach((li) => {
                newParent.appendChild(li.cloneNode(true));
              });
              const rows = tempDiv.firstChild.querySelectorAll("tr");
              rows.forEach((row) => {
                newParent.appendChild(row.cloneNode(true));
              });
            }
          } else {
            parent.innerHTML = "";
            Array.from(tempDiv.childNodes).forEach((child) => {
              if (
                child.nodeType === Node.ELEMENT_NODE &&
                child.tagName === "P"
              ) {
                if (parent.innerHTML.trim() !== "") {
                  parent.innerHTML += "<br><br>";
                }
                parent.innerHTML += child.innerHTML;
              } else {
                parent.innerHTML += child.innerHTML;
              }
            });
          }
        }
        if (recommendationElement) {
          recommendationElement.remove();
        }
        clearPreviousHighlights(doc);
        recommendationParts.forEach((element) => {
          if (element.parentNode) {
            element.parentNode.remove();
          }
        });
        const updatedModel = removeEmptyParagraphs(
          doc.documentElement.outerHTML
        );
        setModel(updatedModel);
        uploadDocument(updatedModel);
        // setIsDirty(true);
        return true;
      }
    }
    return false;
  };
  const removeEmptyParagraphs = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const paragraphs = tempElement.querySelectorAll("p");

    paragraphs.forEach((paragraph) => {
      if (
        paragraph.innerHTML.trim() === "" ||
        (paragraph.children.length === 1 &&
          paragraph.firstElementChild.tagName === "BR")
      ) {
        paragraph.remove();
      }
    });
    return tempElement.innerHTML;
  };

  //Froala Model Callback
  const handleModelChange = useCallback((newModel) => {
    setModel(newModel);
  }, []);

  //Navigation for document >> document-edit
  const handleNavigation = (recommendation) => {
    setActiveRecommendationData(recommendation);
    if (showChangeRequest) {
    } else {
      navigate(`/home/${recommendation.byte_id}/document-edit/loading`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const spans = document.querySelectorAll("span[data-location]");
    const clickHandler = (event) => {
      event.stopPropagation();
      const recId = parseInt(
        event.target.getAttribute("data-location").split("_")[1]
      );
      const recommendation = recommendationDataRef.current.find(
        (rec) => rec.id === recId
      );

      if (recommendation) {
        handleNavigation(recommendation);
      }
    };
    spans.forEach((span) => {
      const recId = parseInt(span.getAttribute("data-location").split("_")[1]);
      const recommendationIndex = recommendationDataRef.current.findIndex(
        (rec) => rec.id === recId
      );
      span.addEventListener("click", clickHandler);
      if (
        recommendationIndex !== -1 &&
        span.innerHTML !== (recommendationIndex + 1).toString()
      ) {
        span.innerHTML = (recommendationIndex + 1).toString();
      } else {
      }
      return () => {
        spans.forEach((span) => {
          span.removeEventListener("click", clickHandler);
        });
      };
    });
  }, [recommendationDataRef.current, model]);

  // setRecommendationData(sortedRecommendations);
  const sortRecommendationsByDocIdAndY = (recommendations) => {
    if (!recommendations || recommendations.length <= 0) {
      return;
    }

    // Remove recommendations where y is "hide"
    const filteredRecommendations = recommendations.filter(
      (recommendation) => recommendation.y !== "hide"
    );

    const groupedByDocId = {};

    // Group recommendations by doc_id
    filteredRecommendations.forEach((recommendation) => {
      const { doc_id } = recommendation;
      if (!groupedByDocId[doc_id]) {
        groupedByDocId[doc_id] = [];
      }
      groupedByDocId[doc_id].push(recommendation);
    });

    // Sort each group by y
    Object.keys(groupedByDocId).forEach((docId) => {
      groupedByDocId[docId].sort((a, b) => a.y - b.y);
    });

    const sortedRecommendations = [];

    filteredRecommendations.forEach((recommendation) => {
      if (
        !sortedRecommendations.some(
          (rec) => rec.doc_id === recommendation.doc_id
        )
      ) {
        sortedRecommendations.push(...groupedByDocId[recommendation.doc_id]);
      }
    });

    return sortedRecommendations;
  };

  useEffect(() => {
    if (!recommendationData || !recommendationData.length < 0) {
      return;
    }
    let smallestYRec = null;
    const spans = document.querySelectorAll("span[data-location]");
    spans.forEach((span) => {
      const recId = parseInt(span.getAttribute("data-location").split("_")[1]);
      const recommendation = recommendationData.find((rec) => rec.id === recId);
      if (recommendation) {
        const rect = span.getBoundingClientRect();
        const container = containerRef?.current;
        const scrollOffset = container
          ? container.scrollTop
          : window.scrollY || document.documentElement.scrollTop;
        let yCoordinate = rect.top + scrollOffset - 230;
        if (!showChangeRequest) {
          while (
            recommendationDataRef.current.some(
              (rec) => Math.abs(rec.y - yCoordinate) <= 90
            )
          ) {
            yCoordinate += 90;
          }
        } else {
          while (
            recommendationDataRef.current.some(
              (rec) => Math.abs(rec.y - yCoordinate) <= 5
            )
          ) {
            yCoordinate += 8;
          }
        }
        if (recommendation.y < 0) {
          recommendation.y = yCoordinate;
        }
        if (!smallestYRec || recommendation.y < smallestYRec.y) {
          smallestYRec = recommendation;
        }
      }
    });
    if (smallestYRec && !activeRecommendationData && showChangeRequest) {
      setActiveRecommendationData(smallestYRec);
    } else {
    }
    const updatedData = [...recommendationDataRef.current];
    const sortData = sortRecommendationsByDocIdAndY(updatedData);
    setSortedRecommendationData(sortData);
    setIsSuggestionLoading(false);
  }, [model]);

  const removeHeadingAndElement = (content, heading) => {
    if (!heading) return content;
    const regex = new RegExp(
      `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
      "gi"
    );
    return content.replace(regex, "");
  };

  //Remove previous string text
  const removeMatchingNodes = (content, previousString) => {
    if (!previousString) return content;
    const contentContainer = document.createElement("div");
    contentContainer.innerHTML = content;

    const previousContainer = document.createElement("div");
    previousContainer.innerHTML = previousString;
    const removeMatchingContent = (contentParent, previousParent) => {
      const previousChildren = Array.from(previousParent.childNodes);
      previousChildren.forEach((previousChild) => {
        if (previousChild.nodeType === Node.ELEMENT_NODE) {
          const tagName = previousChild.tagName;
          const matchingNodes = Array.from(
            contentParent.querySelectorAll(tagName)
          ).filter(
            (node) =>
              node.textContent.trim() === previousChild.textContent.trim()
          );
          matchingNodes.forEach((node) => node.remove());
          removeMatchingContent(contentParent, previousChild);
        }
      });
    };
    removeMatchingContent(contentContainer, previousContainer);
    const removeEmptyNodes = (parent) => {
      const allNodes = parent.querySelectorAll("*");
      allNodes.forEach((node) => {
        if (
          (!node.textContent.trim() && !node.hasChildNodes()) ||
          (node.textContent.trim() === "" &&
            node.querySelectorAll("*").length === 1 &&
            node.querySelectorAll("span").length > 0) ||
          (node.tagName === "UL" && node.querySelectorAll("li").length === 0) ||
          (node.tagName === "OL" && node.querySelectorAll("li").length === 0)
        ) {
          node.remove();
        }
      });
    };
    removeEmptyNodes(contentContainer);
    return contentContainer.innerHTML;
  };
  //PLACE ANNOTATION
  const processAllRecommendations = () => {
    if (!recommendationData || recommendationData.length === 0) {
      return;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(changedModelRef.current, "text/html");
    const spanElements = doc.querySelectorAll("span[data-location]");
    spanElements.forEach((element) => element.remove());
    const brElements = doc.querySelectorAll("br[data-location]");
    brElements.forEach((element) => element.remove());
    recommendationDataRef.current.forEach((rec, index) => {
      if (
        !rec ||
        !rec.change_request_text ||
        String(rec.doc_id) !== String(activeDocIdRef.current) ||
        acceptedArray.current.includes(rec.id) ||
        rejectedArray.current.includes(rec.id)
      ) {
        return;
      }
      if (rec.change_request_type === "Add") {
        addRecommendationAddAnnotation(doc, rec, index + 1);
      } else if (rec.change_request_type === "Replace") {
        addRecommendationReplaceAnnotation(doc, rec, index + 1);
      }
    });
    const updatedModel = removeEmptyParagraphs(doc.documentElement.outerHTML);
    setModel(updatedModel);
  };

  const addRecommendationAddAnnotation = (doc, rec, index) => {
    if (!rec) {
      return;
    }
    const {
      section_main_heading1,
      section_main_heading2,
      section_main_heading3,
      section_main_heading4,
      change_request_text,
      previous_string,
    } = rec;
    let filteredContent = change_request_text;
    filteredContent = removeHeadingAndElement(
      filteredContent,
      section_main_heading1
    );
    filteredContent = removeHeadingAndElement(
      filteredContent,
      section_main_heading2
    );
    filteredContent = removeHeadingAndElement(
      filteredContent,
      section_main_heading3
    );
    filteredContent = removeHeadingAndElement(
      filteredContent,
      section_main_heading4
    );
    filteredContent = removeMatchingNodes(filteredContent, previous_string);
    if (previous_string !== "" && !filteredContent.trim()) {
      // rec.y = "hide";
      return;
    }
    const stripTags = (htmlString) => {
      let doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = rec.previous_string;
    let strippedActiveRecommendation;
    let lastNode;
    tempContainer.innerHTML = rec.previous_string;
    lastNode = tempContainer.lastChild;
    while (lastNode && !lastNode.textContent?.trim()) {
      lastNode = lastNode.previousSibling;
    }
    strippedActiveRecommendation = stripTags(lastNode?.textContent || "");
    let normalizedRecommendation = strippedActiveRecommendation
      .replace(/\n/g, "")
      .replace(/[-"’' ]/g, "")
      .replace(/\s+/g, "")
      .toLowerCase();
    const normalizeTextContent = (node) =>
      node.textContent
        .replace(/<br\s*\/?>/gi, "")
        .replace(/[-"“”’‘']/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/\n/g, "")
        .replace(/\s+/g, "")
        .toLowerCase();
    let flatBodyText = normalizeTextContent(doc.body);
    let matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
    let matchEndIndex = matchStartIndex + normalizedRecommendation.length;
    if (matchStartIndex === -1 || rec.previous_string === "") {
      // if (rec.previous_string !== "") {
      //   // rec.y = "hide";
      //   return;
      // }

      const recommendationElement = document.createElement("span");
      recommendationElement.setAttribute(
        "data-location",
        `recommendation_${rec.id}`
      );
      recommendationElement.innerHTML = index;
      recommendationElement.style.position = "relative";
      recommendationElement.style.width = "18px";
      recommendationElement.style.height = "18px";
      recommendationElement.style.borderRadius = "50%";
      recommendationElement.style.backgroundColor = "#006666";
      recommendationElement.style.color = "white";
      recommendationElement.style.display = "flex";
      recommendationElement.style.alignItems = "center";
      recommendationElement.style.justifyContent = "center";
      recommendationElement.style.fontSize = "10px";
      recommendationElement.style.fontWeight = "bold";
      recommendationElement.style.cursor = "pointer";
      recommendationElement.style.zIndex = "10000000";
      recommendationElement.setAttribute("contenteditable", "false");
      recommendationElement.style.userSelect = "none";
      recommendationElement.setAttribute("data-index", index + 1);
      const lastBodyNode = doc.body.lastChild;
      lastBodyNode.parentNode.insertBefore(
        recommendationElement,
        lastBodyNode.nextSibling
      );

      return;
    }

    let currentOffset = 0;
    const addTag = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let originalText = node.textContent;
        let normalizedText = originalText
          .replace(/\n/g, "")
          .replace(/[-"“”‘'’]/g, "")
          .replace(/\s+/g, "")
          .toLowerCase();
        let textLength = normalizedText.length;
        if (
          currentOffset < matchEndIndex &&
          currentOffset + textLength > matchStartIndex
        ) {
          if (normalizedRecommendation.includes(normalizedText)) {
            if (
              doc.querySelector(`[data-location="recommendation_${rec.id}"]`)
            ) {
              return;
            }
            const recommendationElement = document.createElement("span");
            recommendationElement.setAttribute(
              "data-location",
              `recommendation_${rec.id}`
            );
            recommendationElement.innerHTML = index;
            recommendationElement.style.position = "relative";
            recommendationElement.style.width = "18px";
            recommendationElement.style.height = "18px";
            recommendationElement.style.borderRadius = "50%";
            recommendationElement.style.backgroundColor = "#006666";
            recommendationElement.style.color = "white";
            recommendationElement.style.display = "flex";
            recommendationElement.style.alignItems = "center";
            recommendationElement.style.justifyContent = "center";
            recommendationElement.style.fontSize = "10px";
            recommendationElement.style.fontWeight = "bold";
            recommendationElement.style.cursor = "pointer";
            recommendationElement.style.zIndex = "40";
            recommendationElement.setAttribute("contenteditable", "false");
            recommendationElement.style.userSelect = "none";
            recommendationElement.setAttribute("data-index", index + 1);
            const htmlString = rec.previous_string;
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = htmlString.trim();
            let parent = node.parentNode;
            while (
              parent &&
              parent.tagName !== lastNode.tagName &&
              parent.tagName !== "STRONG"
            ) {
              parent = parent.parentNode;
            }
            if (
              parent &&
              (parent.tagName === "UL" ||
                parent.tagName === "OL" ||
                parent.tagName === "TABLE")
            ) {
              if (parent.tagName === parent.parentNode.tagName) {
                parent = parent.parentNode;
              }
              parent.parentNode.insertBefore(
                recommendationElement,
                parent.nextSibling
              );
            } else {
              if (parent && parent.tagName === lastNode.tagName) {
                parent.insertBefore(recommendationElement, node.nextSibling);
              } else {
                if (parent) {
                  parent.insertBefore(recommendationElement, node.nextSibling);
                } else {
                  // rec.y = "hide";
                }
                //TEMPORARY ADDED TO HANDLE IF PARENT NODE NOT FOUND OR NULL
              }
            }
          }
        }
        currentOffset += textLength;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => {
          addTag(childNode);
        });
      }
    };
    addTag(doc.body);
  };

  const addRecommendationReplaceAnnotation = (doc, rec, index) => {
    if (!rec) {
      return;
    }
    const stripTags = (htmlString) => {
      let doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };
    let strippedActiveRecommendation = stripTags(rec.previous_string);
    let normalizedRecommendation = strippedActiveRecommendation
      .replace(/\n/g, "")
      .replace(/\s+/g, "")
      .replace(/[-"“”’‘']/g, "")
      .toLowerCase();
    const normalizeTextContent = (node) =>
      node.textContent
        .replace(/<br\s*\/?>/gi, "")
        .replace(/&nbsp;/g, "")
        .replace(/[-"“”’‘']/g, "")
        .replace(/\n/g, "")
        .replace(/\s+/g, "")
        .toLowerCase();
    let flatBodyText = normalizeTextContent(doc.body);
    let matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
    let matchEndIndex = matchStartIndex + normalizedRecommendation.length;
    if (matchStartIndex === -1) {
      // rec.y = "hide";
      // return;
    // }
    const recommendationElement = document.createElement("span");
    recommendationElement.setAttribute(
      "data-location",
      `recommendation_${rec.id}`
    );
    recommendationElement.innerHTML = index;
    recommendationElement.style.position = "relative";
    recommendationElement.style.width = "18px";
    recommendationElement.style.height = "18px";
    recommendationElement.style.borderRadius = "50%";
    recommendationElement.style.backgroundColor = "red";
    recommendationElement.style.color = "white";
    recommendationElement.style.display = "flex";
    recommendationElement.style.alignItems = "center";
    recommendationElement.style.justifyContent = "center";
    recommendationElement.style.fontSize = "10px";
    recommendationElement.style.fontWeight = "bold";
    recommendationElement.style.cursor = "pointer";
    recommendationElement.style.zIndex = "10000000";
    recommendationElement.setAttribute("contenteditable", "false");
    recommendationElement.style.userSelect = "none";
    recommendationElement.setAttribute("data-index", index + 1);
    const lastBodyNode = doc.body.lastChild;
    lastBodyNode.parentNode.insertBefore(
      recommendationElement,
      lastBodyNode.nextSibling
    );

    return;
  }
    let currentOffset = 0;
    const addTag = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let originalText = node.textContent;
        let normalizedText = originalText
          .replace(/\s+/g, "")
          .replace(/[-"“”’‘']/g, "")
          .toLowerCase();
        let textLength = normalizedText.length;
        if (
          normalizedRecommendation &&
          normalizedRecommendation.includes(normalizedText)
        ) {
          if (
            currentOffset < matchEndIndex &&
            currentOffset + textLength > matchStartIndex
          ) {
            if (
              doc.querySelector(`[data-location="recommendation_${rec.id}"]`)
            ) {
              const recommendationElementPart = document.createElement("span");
              recommendationElementPart.setAttribute(
                "data-location",
                `recommendation_part${rec.id}`
              );
              recommendationElementPart.setAttribute(
                "contenteditable",
                "false"
              );
              recommendationElementPart.innerHTML = " ";
              recommendationElementPart.style.color = "white";
              node.parentNode.insertBefore(
                recommendationElementPart,
                node.nextSibling
              );
            } else {
              const recommendationElement = document.createElement("span");
              recommendationElement.setAttribute(
                "data-location",
                `recommendation_${rec.id}`
              );
              recommendationElement.innerHTML = index;
              recommendationElement.style.position = "relative";
              recommendationElement.style.fontFamily = "inherit";
              recommendationElement.style.fontSize = "inherit";
              recommendationElement.style.width = "18px";
              recommendationElement.style.height = "18px";
              recommendationElement.style.borderRadius = "50%";
              recommendationElement.style.backgroundColor = "#006666";
              recommendationElement.style.color = "white";
              recommendationElement.style.display = "flex";
              recommendationElement.style.alignItems = "center";
              recommendationElement.style.justifyContent = "center";
              recommendationElement.style.fontSize = "10px";
              recommendationElement.style.fontWeight = "bold";
              recommendationElement.setAttribute("contenteditable", "false");
              recommendationElement.style.userSelect = "none";
              recommendationElement.style.cursor = "pointer";
              recommendationElement.style.zIndex = "40";
              recommendationElement.style.pointerEvents = "auto";
              recommendationElement.setAttribute("data-index", index + 1);
              const htmlString = rec.previous_string;
              const tempDiv = document.createElement("div");
              tempDiv.innerHTML = htmlString.trim();
              const firstElement = tempDiv.firstElementChild;
              let parent = node.parentNode;
              while (parent && parent.tagName !== firstElement.tagName) {
                parent = parent.parentNode;
              }
              if (
                parent &&
                (parent.tagName === "UL" ||
                  parent.tagName === "OL" ||
                  parent.tagName === "TABLE")
              ) {
                if (parent.tagName === parent.parentNode.tagName) {
                  parent = parent.parentNode;
                }
                parent.parentNode.insertBefore(recommendationElement, parent);
              } else if (parent && parent.tagName === firstElement.tagName) {
                parent.insertBefore(recommendationElement, parent.firstChild);
              }
            }
          }
        } else {
        }
        currentOffset += textLength;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => {
          addTag(childNode);
        });
      }
    };
    addTag(doc.body);
  };

  //HIGHLIGHT TEXT TO BE CHANGED
  const highlightText = () => {
    if (!requestData || !activeRecommendationData) {
      return;
    }

    const stripTags = (htmlString) => {
      let doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };
    let strippedActiveRecommendation = stripTags(
      activeRecommendationData.previous_string
    );
    let normalizedRecommendation = strippedActiveRecommendation
      .replace(/\n/g, "")
      .replace(/\s+/g, "")
      .replace(/[-"“”’‘']/g, "")
      .toLowerCase();
    let parser = new DOMParser();
    let doc = parser.parseFromString(model, "text/html");
    clearPreviousHighlights(doc);
    const normalizeTextContent = (node) =>
      node.textContent
        .replace(/<br\s*\/?>/gi, "")
        .replace(/&nbsp;/g, "")
        .replace(/[-"'“”’‘]/g, "")
        .replace(/\n/g, "")
        .replace(/\s+/g, "")
        .toLowerCase();
    let flatBodyText = normalizeTextContent(doc.body);
    let matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
    let matchEndIndex = matchStartIndex + normalizedRecommendation.length;

    if (
      matchStartIndex === -1 ||
      activeRecommendationData.change_request_type !== "Replace"
    ) {
      clearPreviousHighlights(doc);
      setModel(doc.documentElement.outerHTML);
      return;
    }
    let currentOffset = 0;
    const highlightMatchedText = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let originalText = node.textContent;
        let normalizedText = originalText
          .replace(/\s+/g, "")
          .replace(/[-"“”’‘']/g, "")
          .toLowerCase();
        let textLength = normalizedText.length;
        if (
          normalizedRecommendation &&
          normalizedRecommendation.includes(normalizedText)
        ) {
          if (
            currentOffset < matchEndIndex &&
            currentOffset + textLength > matchStartIndex
          ) {
            let parent = node.parentNode;
            if (parent && parent.tagName !== "STRONG") {
              const markElement = document.createElement("mark");
              while (parent.firstChild) {
                markElement.insertBefore(
                  parent.lastChild,
                  markElement.firstChild
                );
              }
              parent.appendChild(markElement);
            } else {
            }
          }
        } else {
        }
        currentOffset += textLength;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => {
          highlightMatchedText(childNode);
        });
      }
    };
    highlightMatchedText(doc.body);
    const updatedModel = doc.documentElement.outerHTML;
    changedModelRef.current = updatedModel;
    setModel(updatedModel);
  };

  const clearPreviousHighlights = (doc) => {
    const marks = doc.querySelectorAll("mark");
    marks.forEach((mark) => {
      if (mark.closest("strong")) {
        return;
      }
      const parent = mark.parentNode;
      while (mark.firstChild) {
        parent.insertBefore(mark.firstChild, mark);
      }
      mark.remove();
    });
  };

  //Handle Resolve Warning Pop Up
  const handleOpenResolveWarning = async () => {
    const data = await apiService.pendingRecommendation(requestData.request_id);
    if (data.isPending) {
      setShowResolveWarning(true);
    } else {
      await handleResolveByte();
      setShowChangeRequest(false);
      navigate(`/home/document/${activeDocIdRef.current}`);
    }
  };
  const handleCloseResolveWarning = () => {
    setShowResolveWarning(false);
  };

  //Handle Resolve Byte
  const handleResolveByte = async () => {
    await apiService.resolveByte(requestData.request_id);
    handleCloseResolveWarning();
    setShowChangeRequest(false);
    navigate(`/home/document/${activeDocIdRef.current}`);
  };

  //Handle Navigation
  const handlePrevious = () => {
    if (currentRecommendationIndex > 0) {
      updateModel(currentRecommendationIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentRecommendationIndex < sortedRecommendationData.length - 1) {
      updateModel(currentRecommendationIndex + 1);
    }
  };

  const updateModel = async (newIndex) => {
    let recommendation = sortedRecommendationData[newIndex];
    if (!recommendation) {
      return;
    }
    const currentDocId = recommendation.doc_id;
    setActiveDocId(currentDocId);
    if (String(activeDocIdRef.current) !== String(currentDocId)) {
      setActiveRecommendationData(null);
      const container = containerRef.current;
      if (container) {
        container.scrollTop = 0;
      }
      await handleDocumentChange(currentDocId, recommendation.doc_content);
    } else {
      setActiveRecommendationData(recommendation);
    }
  };

  // Function to handle document content change and navigation
  const handleDocumentChange = async (docId, docContentUrl) => {
    handleUpdate();
    navigate(`/home/${byteId}/document-edit/${docId}`, { replace: true });
    const newHtmlContent = await fetchHTMLContent(docContentUrl);
    setModel(newHtmlContent);
    setFileName(docContentUrl.substring(docContentUrl.lastIndexOf("/") + 1));
    dBModelRef.current = newHtmlContent;
  };

  const handleOnTap = () => {
    setShowChangeRequest(false);
    setCurrentRecommendationIndex(-1);
    navigate(`/home/document/${id}`, {
      replace: true,
    });
  };

  //LOADING - Skeleton loading
  if (isLoading) {
    return (
      <div id="editor" className="froala-editor-section">
        <div id="toolbar-container" className="toolbar-container"></div>
        <div style={{ paddingBottom: "15px" }}>
          <EditorSkeleton
            height="40px"
            borderRadius="100px"
            padding="4px 0px"
          />
        </div>
        <div className="editor-suggestion">
          <div className="change-request">
            <EditorSkeleton width="100%" height="100vh" borderRadius="4px" />
          </div>
          <RecommendationSkeletonLoader count={4} />
        </div>
      </div>
    );
  }

  //Main Body
  return (
    <div className="doc-editor">
      <ResolveChangeRequestPopUp
        isVisible={showResolveWarning}
        onClickLButton={handleResolveByte}
        onClickRButton={handleCloseResolveWarning}
        onClose={handleCloseResolveWarning}
        title="Open AiEdits Pending"
        subtitle="Do you still wish to resolve the Change Request?"
        lButtonText="Resolve CR"
        rButtonText="View AiEdits"
      />
      <div id="editor" className="froala-editor-section fade-in">
        {/* Toolbar Container */}
        <div id="toolbar-container" className="toolbar-container"></div>
        <NotificationBar isVisible={showAutoRejectWarning} />
        <div className="editor-suggestion" ref={containerRef}>
          <div className="change-request">
            {showChangeRequest && (
              <ChangeRequest
                onResolve={handleOpenResolveWarning}
                width={editorWidth ?? 300}
                requester={requestData?.sender ?? "loading"}
                date={requestData?.date_time ?? "Date"}
                message={requestData?.request_text ?? "Change byte"}
                aiEdits={`${
                  sortedRecommendationData &&
                  sortedRecommendationData.length > 0
                    ? currentRecommendationIndex + 1
                    : 0
                }/${sortedRecommendationData?.length ?? 0}`}
                onPrevious={handlePrevious}
                onNext={handleNext}
                onTap={handleOnTap}
              />
            )}

            {isLoading ? (
              <EditorSkeleton />
            ) : (
              // Froala Editor
              <div ref={editorRef}>
                <FroalaEditorComponent
                  tag="textarea"
                  model={model}
                  onModelChange={handleModelChange}
                  config={{
                    toolbarSticky: true,
                    editorClass: "froala-editor",
                    spellcheck: true,
                    attribution: true,
                    heightMin: 200,
                    heightMax: 800,
                    placeholderText: "Edit your content here...",
                    toolbarVisibleWithoutSelection: true,
                    charCounterCount: true,
                    toolbarContainer: "#toolbar-container",
                    pluginsEnabled: [
                      "fullscreen",
                      "codeBeautifier",
                      "align",
                      "image",
                      "link",
                      "fontFamily",
                      "fontSize",
                      "specialCharacters",
                      "lists",
                      "table",
                      "codeView",
                      "colors",
                      "backgroundColor",
                      "undo",
                      "redo",
                      "paragraphFormat",
                      "inlineStyle",
                      "inlineClass",
                      "clearFormatting",
                      "increaseIndent",
                    ],
                    toolbarButtons: {
                      moreText: {
                        buttons: [
                          "paragraphFormat",
                          "fontFamily", //default Manrope
                          "fontSize", //default sie 11
                          "|",
                          "bold",
                          "italic",
                          "underline",
                          "textColor",
                          "backgroundColor",
                          "strikeThrough",
                          "html",
                          "subscript",
                          "superscript",
                          "specialCharacters",
                        ],
                        align: "left",
                        buttonsVisible: 6,
                      },
                      moreParagraph: {
                        buttons: [
                          // "|",
                          // "alignLeft",
                          // "alignCenter",
                          // "alignRight",
                          "insertCode",

                          "alignLeft",
                          "alignCenter",
                          "alignRight",
                          "alignJustify",
                          "indent",
                          "outdent",
                        ],
                        align: "left",
                        buttonsVisible: 0,
                      },
                      moreRich: {
                        buttons: [
                          "formatUL",
                          "formatOL",
                          "formatOLSimple",
                          "|",
                          "insertLink",
                          "insertImage",
                          "insertTable",
                        ],
                        align: "left",
                        buttonsVisible: 6,
                      },
                      moreMisc: {
                        buttons: ["undo", "redo", "formatPainter"],
                        align: "right",
                        buttonsVisible: 3,
                      },
                    },
                    showColorsPopup: true,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    paragraphFormatSelection: true,
                    events: {
                      initialized: function () {
                        isFirstContentChange.current = true;
                        const secondToolbar =
                          document.querySelector(".fr-second-toolbar");
                        if (secondToolbar) {
                          secondToolbar.remove();
                        }
                      },
                      "paste.before": function (e) {
                        const selection = this.selection.get();
                        const pasteContent = e.clipboardData.getData("text");
                        if (selection && pasteContent) {
                          const preTagRegex = /<pre[^>]*>/g;
                          const isInsidePre = preTagRegex.test(selection.text);

                          if (isInsidePre) {
                            const updatedContent = selection.text.replace(
                              /(<pre[^>]*>)(.*?)(<\/pre>)/s,
                              (match, p1, p2, p3) => `${p1}${pasteContent}${p3}`
                            );
                            this.html.insert(
                              updatedContent,
                              selection.start,
                              selection.end
                            );
                          } else {
                            this.html.insert(pasteContent);
                          }

                          return false;
                        }
                      },

                      "paste.after": function () {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      keydown: function (e) {
                        if (
                          e.key === "Backspace" ||
                          e.key === "Delete" ||
                          e.key === "Enter"
                        ) {
                          const updatedModel = this.html.get();
                          handleContentChange(updatedModel);
                        }
                      },
                      input: function (inputEvent) {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "image.removed": function ($img) {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "image.resize": function ($img) {
                        const updatedModel = this.html.get();
                        console.log(updatedModel);
                        handleContentChange(updatedModel);
                      },
                      "commands.before": function (cmd, param1, param2) {
                        if (cmd === "html") {
                          const userSelection = window.getSelection();
                          if (userSelection && userSelection.rangeCount) {
                            const range = userSelection.getRangeAt(0);
                            const selectedNode = range.startContainer;
                            if (selectedNode.nodeType === 3) {
                              const parentNode = selectedNode.parentNode;

                              if (
                                parentNode &&
                                parentNode.nodeName === "CODE" &&
                                parentNode.classList.contains(
                                  "custom-code-block"
                                )
                              ) {
                                const cleanedText = parentNode.textContent;
                                this.html.insert(cleanedText);
                              } else {
                                const wrappedCode = `<code class="custom-code-block">${userSelection.toString()}</code>`;
                                this.html.insert(wrappedCode); 
                              }
                            } else{
                              const codeBlock =
                              '<pre class="custom-code-block"><code class="language-html"> </code></pre>';
                            this.html.insert(codeBlock);                              
                            }
                          } else {
                            const codeBlock =
                              '<pre class="custom-code-block"><code class="language-html"> </code></pre>';
                            this.html.insert(codeBlock);
                          }
                          return false;
                        }
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "commands.after": function (cmd, param1, param2) {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "image.beforeUpload": async function (images) {
                        if (images.length) {
                          const imageFile = images[0];

                          try {
                            const response = await apiService.uploadImage(
                              imageFile,
                              id
                            );
                            if (response.status === "success") {
                              // this.image.insert(
                              //   response.imageUrl,
                              //   null,
                              //   null,
                              //   this.image.get()
                              // );
                              const cursorPosition = this.selection.get();
                              this.image.insert(
                                response.imageUrl,
                                null,
                                null,
                                cursorPosition
                              );
                              const updatedModel = this.html.get();
                              handleContentChange(updatedModel);
                            } else {
                              return false;
                            }
                          } catch (error) {}
                          return false;
                        }
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
          {isSuggestionLoading ? (
            <RecommendationSkeletonLoader count={5} />
          ) : (
            <div className="suggestion-list">
              {sortedRecommendationData &&
                sortedRecommendationData.map((recommendation, index) => {
                  if (String(recommendation.doc_id) !== String(id)) {
                    return null;
                  }
                  const recommendationElement = document.querySelector(
                    `span[data-location="recommendation_${recommendation.id}"]`
                  );

                  if (recommendationElement) {
                    const currentIndex = recommendationElement.innerHTML.trim();
                    const expectedIndex = (index + 1).toString();

                    if (currentIndex !== expectedIndex) {
                      recommendationElement.innerHTML = expectedIndex;
                    }
                  }
                  let adjustY = recommendation.y;
                  return (
                    <div
                      key={recommendation.id}
                      className="suggestion-card"
                      style={{
                        // position: showChangeRequest ? "relative" : "absolute",
                        position: "absolute",
                        left: `0px`,
                        top: `${adjustY}px`,
                        transition: "all 0.2s ease",
                      }}
                      data-index={index + 1}
                    >
                      <SuggestionCardComponent
                        num={index + 1}
                        isVisible={String(recommendation.y) === "-9999"}
                        recommendationData={recommendation}
                        isRecommendationAccepted={acceptedArray.current.includes(
                          recommendation.id
                        )}
                        isRecommendationRejected={rejectedArray.current.includes(
                          recommendation.id
                        )}
                        isActive={
                          activeRecommendationData
                            ? activeRecommendationData.id === recommendation.id
                            : false
                        }
                        onTapAccept={() => {
                          if (recommendation.change_request_type === "Add") {
                            return addText(recommendation.id);
                          } else {
                            return replaceText(recommendation.id);
                          }
                        }}
                        updateAcceptStatus={() => {
                          acceptedArray.current = [
                            ...acceptedArray.current,
                            recommendation.id,
                          ];
                        }}
                        onTapReject={() => {
                          const result = rejectRecommendation(
                            recommendation.id
                          );
                          if (result) {
                            rejectedArray.current = [
                              ...rejectedArray.current,
                              recommendation.id,
                            ];
                          }
                        }}
                        onCoverTap={() => {
                          handleNavigation(recommendation);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FunctionalEditor;
