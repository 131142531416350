import React, { useState } from "react";
import "./popup-style.css";
import HeaderSubHeadingComponent from "./CustomComponets";
import icons from "../../assets/icons";

const ResolveChangeRequestPopUp = ({
  isVisible,
  title,
  subtitle,
  onClickLButton,
  lButtonText,
  onClickRButton,
  rButtonText,
  onClose,
}) => {
  const [loadingRButton, setloadingRButton] = useState(false);
  const [loadingLButton, setloadingLButton] = useState(false);

  if (!isVisible) return null;
  const handleRButtonClick = async () => {
    setloadingRButton(true);
    await onClickRButton();
    setloadingRButton(false);
  };
  const handleLButtonClick = async () => {
    setloadingLButton(true);
    await onClickLButton();
    setloadingLButton(false);
  };
  return (
    <div
      className={`popup-overlay ${isVisible ? "show" : ""}`}
      onClick={onClose}
    >
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="show-row-space-between-header">
          <HeaderSubHeadingComponent title={title} subtitle={subtitle} />
          <img alt="close" src={icons.closeIcon} onClick={onClose} />
        </div>
        <div className="show-row-space-between-header">
          <button
            className="grey-button"
            disabled={loadingLButton || loadingRButton}
            onClick={() => handleLButtonClick()}
          >
            {lButtonText}
            {loadingLButton && <span className="button-loader"></span>}
          </button>
          <button
            className="teal-button"
            disabled={loadingRButton || loadingLButton}
            onClick={() => handleRButtonClick()}
          >
            {rButtonText}
            {loadingRButton && <span className="button-loader"></span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResolveChangeRequestPopUp;
