import React, { useState, useEffect, useRef } from "react";
import "../../style.css";
import "./sidebar-style.css";
import DropdownMenu from "../PopUps/DropDown";
import RenamePopUp from "../PopUps/RenamePopUp";
import CustomTooltip from "../PopUps/CustomToolTip";
import { apiService } from "../../services/apiService";
import SvgAddIcon from "../../icons/AddIcon";
import SvgRename from "../../icons/Rename";
import SvgTrash from "../../icons/Trash";
import SvgActiveDoc from "../../icons/ActiveDoc";
import SvgDocumentIcon from "../../icons/DocumentIcon";
import SvgActiveRightArrow from "../../icons/ActiveRightArrow";
import SvgFolderIcon from "../../icons/FolderIcon";
import SvgAddMember from "../../icons/AddMember";
import SvgTeamspace from "../../icons/Teamspace";
import { MoreHorizontal } from "lucide-react";

const TeamSpace = ({
  teamspaceId,
  title,
  tooltipId,
  folderToolTip,
  folderList = [],
  onClickCreateFolder,
  onClickCreateDoc,
  onClickDocument,
  handleOpenFolderDeletePopup,
  handleopendocumentdeletepopup,
  handleOpenDeleteTeamspacePopup,
  handleOpenInvitePopUp,
  activeItem,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isRenameVisible, setIsRenameVisible] = useState(false);
  const [teamSpaceTitle, setTitle] = useState(title);
  const [initialTitle, setInitialTitle] = useState(title);

  const teamSpaceRef = useRef(null);
  const menuRef = useRef(null);
  const nameToolTipId = "name-tooltip";

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const isActiveInTeam = folderList.some((folder) =>
      folder.documents.some((doc) => String(doc.id) === String(activeItem))
    );
    if (isActiveInTeam) {
      setIsOpen(true);
    }
  }, [activeItem, folderList]);
  useEffect(() => {
    setTitle(title);
  }, [title]);
  const toggleOpen = () => {
    if (folderList && folderList.length > 0) {
      setIsOpen(!isOpen);
    }
  };

  const handleDeleteOpenPopup = () => {
    handleCloseMenu();
    handleOpenDeleteTeamspacePopup();
  };
  const handleInviteOpenPopup = () => {
    handleCloseMenu();
    handleOpenInvitePopUp(teamspaceId);
  };

  const handleCloseMenu = () => {
    setShowDropdown(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    }
    onClickCreateFolder();
  };

  const handleUpdateRename = async () => {
    if (teamSpaceTitle.trim()) {
      await apiService.renameTeamspace(teamSpaceTitle, teamspaceId);
      setInitialTitle(teamSpaceTitle);
    } else {
      setTitle(initialTitle);
    }
  };

  const handleOpenRenamePopUp = () => {
    handleCloseMenu();
    setInitialTitle(teamSpaceTitle);
    setIsRenameVisible(true);
  };

  const handleCloseRenamePopUp = () => {
    handleUpdateRename();
    setIsRenameVisible(false);
  };

  const dropdownOptions = [
    {
      label: "Invite Teammates",
      icon: SvgAddMember,
      onClick: handleInviteOpenPopup,
    },
    {
      label: "Rename",
      icon: SvgRename,
      onClick: handleOpenRenamePopUp,
    },
    {
      label: "Delete",
      icon: SvgTrash,
      onClick: handleDeleteOpenPopup,
    },
  ];

  const CurrentIcon = isHovered
    ? isOpen
      ? SvgTeamspace
      : SvgActiveRightArrow
    : SvgTeamspace;
  const isTitleOverflowing = teamSpaceRef.current
    ? teamSpaceRef.current.offsetWidth < teamSpaceRef.current.scrollWidth
    : false;
  return (
    <div>
      {isTitleOverflowing && <CustomTooltip id={nameToolTipId} />}
      {/*Kebab Menu*/}
      {showDropdown && (
        <DropdownMenu
          referenceElement={menuRef.current}
          exceptionRef={menuRef}
          options={dropdownOptions}
          onClose={handleCloseMenu}
        />
      )}
      {/*Rename*/}
      {isRenameVisible && (
        <RenamePopUp
          title={teamSpaceTitle}
          setTitle={setTitle}
          referenceElement={teamSpaceRef.current}
          onClose={handleCloseRenamePopUp}
          exceptionRef={teamSpaceRef}
        />
      )}
      <div
        className="show-row-space-between"
        onClick={toggleOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="teamspace-header">
          <CurrentIcon className="default-img-icon-teamspace" />
          <span
            data-tooltip-id={nameToolTipId}
            data-tooltip-content={teamSpaceTitle}
            ref={teamSpaceRef}
            className={`folder-title ${isHovered ? "active" : ""}`}
          >
            {teamSpaceTitle}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MoreHorizontal
            ref={menuRef}
            className="hide-icon"
            onClick={handleMenuClick}
            size={16}
          />

          <SvgAddIcon
            data-tooltip-id={tooltipId}
            data-tooltip-content="Create New Folder"
            className="hide-icon"
            onClick={handleAddClick}
            style={{ outline: "none" }}
          />
        </div>
      </div>
      {isOpen && folderList && folderList.length > 0 && (
        <div>
          {folderList.map((e, index) => (
            <Folder
              key={index}
              folderId={e.id}
              title={e.folderName}
              docList={e.documents}
              toolTipId={folderToolTip}
              onClickCreateDoc={onClickCreateDoc}
              onClickDocument={onClickDocument}
              handleOpenFolderDeletePopup={() =>
                handleOpenFolderDeletePopup(e.id)
              }
              handleopendocumentdeletepopup={handleopendocumentdeletepopup}
              activeItem={activeItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamSpace;

/************************************************ FOLDER ***************************************/

const Folder = ({
  folderId,
  title,
  toolTipId,
  docList,
  onClickCreateDoc,
  onClickDocument,
  handleOpenFolderDeletePopup,
  handleopendocumentdeletepopup,
  activeItem,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRenameVisible, setIsRenameVisible] = useState(false);
  const [folderTitle, setTitle] = useState(title);
  const [initialTitle, setInitialTitle] = useState(title);
  const menuRef = useRef(null);
  const titleRef = useRef(null);
  const nameToolTipId = "folder-tooltip";
  useEffect(() => {
    const isActiveInFolder = docList.some(
      (doc) => String(doc.id) === String(activeItem)
    );
    if (isActiveInFolder) setIsOpen(true);
  }, [activeItem, docList]);

  useEffect(() => {
    setTitle(title);
  }, [title]);

  const toggleOpen = () => {
    if (docList && docList.length > 0) {
      setIsOpen(!isOpen);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowDropdown((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setShowDropdown(false);
  };
  const CurrentIcon = isHovered
    ? isOpen
      ? SvgFolderIcon
      : SvgActiveRightArrow
    : SvgFolderIcon;

  const handleAddDocClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    }
    onClickCreateDoc(folderId);
  };
  const handleOpenDeletePopup = () => {
    handleCloseMenu();
    handleOpenFolderDeletePopup(folderId);
  };

  const handleUpdateRename = async () => {
    if (folderTitle.trim()) {
      await apiService.renameFolder(folderTitle, folderId);
      setInitialTitle(folderTitle);
    } else {
      setTitle(initialTitle);
    }
  };

  const handleOpenRenamePopUp = () => {
    handleCloseMenu();
    setInitialTitle(folderTitle);
    setIsRenameVisible(true);
  };

  const handleCloseRenamePopUp = () => {
    handleUpdateRename();
    setIsRenameVisible(false);
  };

  const dropdownOptions = [
    {
      label: "Rename",
      icon: SvgRename,
      onClick: handleOpenRenamePopUp,
    },
    {
      label: "Delete",
      icon: SvgTrash,
      onClick: handleOpenDeletePopup,
    },
  ];
  const isTitleOverflowing = titleRef.current
    ? titleRef.current.offsetWidth < titleRef.current.scrollWidth
    : false;

  return (
    <div>
      <div
        className="show-row-space-between"
        onClick={toggleOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="folder-header"
          data-tooltip-id={nameToolTipId}
          data-tooltip-content={folderTitle}
        >
          <CurrentIcon className="default-img-icon" />
          <span
            ref={titleRef}
            className={`folder-title ${isHovered ? "active" : ""}`}
          >
            {folderTitle}
          </span>
        </div>

        <MoreHorizontal
          ref={menuRef}
          className="hide-icon"
          onClick={handleMenuClick}
          size={18}
        />
        {isTitleOverflowing && <CustomTooltip id={nameToolTipId} />}

        <SvgAddIcon
          data-tooltip-id={toolTipId}
          data-tooltip-content="Create New Document"
          className="hide-icon"
          onClick={handleAddDocClick}
          style={{ outline: "none" }}
        />
      </div>
      {isOpen && docList && docList.length > 0 && (
        <div>
          {docList.map((e, index) => (
            <Document
              key={index}
              docId={e.id}
              isLatest={e.isLatest}
              name={e.documentName}
              handleopendocumentdeletepopup={handleopendocumentdeletepopup}
              onClickDocument={onClickDocument}
              activeItem={activeItem}
            />
          ))}
        </div>
      )}
      {showDropdown && (
        <DropdownMenu
          referenceElement={menuRef.current}
          options={dropdownOptions}
          onClose={handleCloseMenu}
          exceptionRef={menuRef}
        />
      )}
      {/*Rename*/}
      {isRenameVisible && (
        <RenamePopUp
          title={folderTitle}
          referenceElement={titleRef.current}
          onClose={handleCloseRenamePopUp}
          exceptionRef={titleRef}
          setTitle={setTitle}
        />
      )}
    </div>
  );
};

// export default Folder;

/**********************************************DOCUMENT************************************8*/

const Document = ({
  docId,
  name,
  isLatest,
  onClickDocument,
  activeItem,
  handleopendocumentdeletepopup,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRenameVisible, setIsRenameVisible] = useState(false);
  const [docTitle, setDoc] = useState(name);
  const [initialTitle, setInitialTitle] = useState(name);
  const menuRef = useRef(null);
  const titleRef = useRef(null);
  const documentRef = useRef(null);

  useEffect(() => {
    if (String(docId) === String(activeItem)) {
      setIsActive(true);
      documentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      setIsActive(false);
    }
  }, [activeItem, docId]);

  useEffect(() => {
    setDoc(name);
  }, [name]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowDropdown((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setShowDropdown(false);
  };
  const handleOpenDeletePopup = (e) => {
    handleCloseMenu();
    handleopendocumentdeletepopup(docId);
  };

  const handleOpenRenamePopUp = () => {
    handleCloseMenu();
    setInitialTitle(docTitle);
    setIsRenameVisible(true);
  };

  const handleCloseRenamePopUp = () => {
    handleUpdateRename();
    setIsRenameVisible(false);
  };

  const dropdownOptions = [
    {
      label: "Rename",
      icon: SvgRename,
      onClick: handleOpenRenamePopUp,
    },
    {
      label: "Delete",
      icon: SvgTrash,
      onClick: handleOpenDeletePopup,
    },
  ];
  const CurrentIcon = isHovered || isActive ? SvgActiveDoc : SvgDocumentIcon;
  const tooltipId = "document-tooltip";
  const isTitleOverflowing = titleRef.current
    ? titleRef.current.offsetWidth < titleRef.current.scrollWidth
    : false;

  const handleUpdateRename = async () => {
    if (docTitle.trim()) {
      await apiService.renameDocument(docTitle, docId);
      setInitialTitle(docTitle);
    } else {
      setDoc(initialTitle);
    }
  };
  return (
    <div
      ref={documentRef}
      className={`show-row-space-between ${
        isHovered || isActive ? "active" : ""
      }`}
      onClick={() => {
        if (!isActive) {
          onClickDocument(docId);
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      {/*Kebab Menu*/}
      {showDropdown && (
        <DropdownMenu
          referenceElement={menuRef.current}
          options={dropdownOptions}
          onClose={handleCloseMenu}
          exceptionRef={menuRef}
        />
      )}
      {/*Rename*/}
      {isRenameVisible && (
        <RenamePopUp
          title={docTitle}
          referenceElement={titleRef.current}
          onClose={handleCloseRenamePopUp}
          exceptionRef={titleRef}
          setTitle={setDoc}
        />
      )}
      {isTitleOverflowing && <CustomTooltip id={tooltipId} />}
      <div className="document-content">
      {isLatest && <span className="latest-icon">✔</span>}
        <CurrentIcon className="default-img-icon" />
        <span
          ref={titleRef}
          data-tooltip-id={tooltipId}
          data-tooltip-content={docTitle}
          className={`folder-title ${isHovered || isActive ? "active" : ""}`}
        >
          {docTitle}
        </span>
      </div>
      <MoreHorizontal
        ref={menuRef}
        className="hide-icon"
        onClick={handleMenuClick}
        size={18}
      />
    </div>
  );
};
