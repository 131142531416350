import React, { useState, useEffect } from "react";
import "./popup-style.css";
import icons from "../../assets/icons";
import HeaderSubHeadingComponent from "./CustomComponets";
import { apiService } from "../../services/apiService";
import SkeletonLoaderComponent from "../loading-screen/SkeletonLoaderComponent";

const InviteMembersPopUp = ({ isVisible, onClose, teamspaceId }) => {
  const [emails, setEmails] = useState([]);
  const [userAccessList, setUserAccessList] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const userEmailsData = await apiService.userListWithTeamspaceAccess(
          teamspaceId
        );
        setUserAccessList(userEmailsData.users);
        if (userEmailsData.status === "success") {
          setTimeout(() => {
            setListLoading(false);
          }, 500);
        }
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };

    if (isVisible) {
      setListLoading(true);
      fetchEmails();
    }
  }, [isVisible]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " " || event.key === ",") {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (validateEmail(trimmedValue) && !emails.includes(trimmedValue)) {
        setEmails([...emails, trimmedValue]);
        setInputValue("");
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInviteMembers = async () => {
    setLoading(true);
    try {
      await apiService.inviteMembers(emails, teamspaceId);
      handleClearAndClose();
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveAccess = async (userId) => {
    try {
      await apiService.removeUserAccess(teamspaceId, userId);
      setUserAccessList(userAccessList.filter((e) => e.id !== userId));
    } catch (error) {
      console.error("Error removing access:", error);
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleClearAndClose = () => {
    setEmails([]);
    setInputValue("");
    setUserAccessList([]);
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div
      className={`popup-overlay ${isVisible ? "show" : ""}`}
      onClick={onClose}
    >
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="show-column">
          <div className="show-row-space-between-header">
            <HeaderSubHeadingComponent
              title="Invite Teammates"
              subtitle="Team members can read, edit, add and delete documents in your teamspace."
            />
            <img
              alt="close"
              src={icons.closeIcon}
              onClick={handleClearAndClose}
            />
          </div>
          {/* <label className="input-form-field-popup"> */}
            <div className="email-input-container">
              {emails.map((email, index) => (
                <div className="email-tag" key={index}>
                  {email}
                  <img
                    alt="close"
                    style={{ width: "16px", height: "16px" }}
                    src={icons.closeIcon}
                    className="remove-email"
                    onClick={() => removeEmail(email)}
                  />
                </div>
              ))}
              <input
                type="text"
               placeholder="Click ENTER after writing email address"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="email-input"
              />
            </div>
          {/* </label> */}
          <div className="label-text">People who have access</div>
          <div className="user-list-container">
            {listLoading || !userAccessList ? (
              <SkeletonLoaderComponent
                length={4}
                padding="6.5"
                margin="14px 0px"
              />
            ) : (
              userAccessList.map((e, index) => (
                <UserList
                  key={index}
                  email={e.email}
                  onRemove={() => {
                    handleRemoveAccess(e.id);
                  }}
                />
              ))
            )}
          </div>
        </div>
        <button
          disabled={emails.length === 0 || loading}
          className={`popup-button ${
            emails.length === 0 || loading ? "disabled-button" : ""
          }`}
          onClick={handleInviteMembers}
        >
          Done
          {loading && <span className="button-loader"></span>}
        </button>
      </div>
    </div>
  );
};

export default InviteMembersPopUp;

//USER LIST

const UserList = ({ email, onRemove }) => {
  const [loading, setLoading] = useState(false);
  const handleRemoveClick = async () => {
    setLoading(true);
    try {
      await onRemove();
    } catch (error) {
      console.error("Error removing user:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  const userEmail = localStorage.getItem("email");

  return (
    <div className="email-entry">
      <span className="email-text">{email}</span>
      {String(email) !== String(userEmail) && (
        <span
          className={`remove-link ${loading ? "disabled" : ""}`}
          onClick={() => !loading && handleRemoveClick()}
        >
          {loading ? <div className="remove-spinner"></div> : "Remove"}
        </span>
      )}
    </div>
  );
};
