const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ENDPOINTS = {
  LOGIN: `${API_BASE_URL}auth/login`,
  REGISTER: `${API_BASE_URL}auth/register`,
  UPLOAD_DOCUMENT: `${API_BASE_URL}clients/load-document`,
  MODIFY_RECOMMENDATION: `${API_BASE_URL}clients/modify`,
  CHECK_USER_EXIST: `${API_BASE_URL}clients/users/exists`,

  UPLOAD_IMAGE: (docId, CLIENT_ID) =>
    `${API_BASE_URL}/clients/${CLIENT_ID}/documents/${docId}/upload-image`,

  GET_OPEN_CHANGEREQUEST: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/open`,

  GET_CLOSED_CHANGEREQUEST: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/closed`,

  DELETE_CHANGE_REQUEST: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/delete`,

  CREATE_CHANGE_REQUEST: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/create`,

  GENERATE_DOCUMENT_WITH_AI: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/document/generate`,

  ASK_BOT: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/document/chat`,

  GET_USER_TEAMSPACE: (CLIENT_ID) =>
    `${API_BASE_URL}clients/clientDetails?clientId=${CLIENT_ID}`,

  CREATE_TEAMSPACE: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces`,

  CREATE_FOLDER: (CLIENT_ID) => `${API_BASE_URL}clients/${CLIENT_ID}/folders`,

  CREATE_DOCUMENT: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/documents`,

  GET_DOCUMENT: (CLIENT_ID,docId) => `${API_BASE_URL}clients/${CLIENT_ID}/documents/${docId}`,

  CHECK_TEAMSPACE_EXIST: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/unique`,

  CHECK_FOLDER_EXIST: (teamspaceId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/${teamspaceId}/folders/unique`,

  GET_TRASH: (CLIENT_ID) => `${API_BASE_URL}clients/${CLIENT_ID}/bytes/trash`,

  GET_RECOMMENDATION_SINGLE_BYTE: (byteId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/${byteId}/recommendations`,

  GET_RECOMMENDATION_DOC_BASED_BYTE: (CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/create-for-document`,


  GET_RECOMMENDATION_FOR_DOC: (docId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/documents/${docId}/recommendations`,

  RENAME_OR_DELETE_FOLDER: (folderId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/folders/${folderId}`,

  RENAME_OR_DELETE_TEAMSPACE: (teamspaceId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/${teamspaceId}`,

  RENAME_OR_DELETE_DOCUMENT: (docId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/documents/${docId}`,

  RESOLVE_BYTE: (byteId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/${byteId}/resolve-or-closed`,

  RETRY_BYTE: (byteId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/bytes/${byteId}/retry-byte`,

  FEEDBACK_BYTE: (byteId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/byte/${byteId}/feedback`,

  CHECK_BYTE_PENDING_RECOM: (byteId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/byte/${byteId}/is-pending-user-recommendation`,

  CHECK_DOCUMENT_EXIST: (folderId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/folders/${folderId}/documents/unique`,

  INVITE_MEMBERS: (teamspaceId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/${teamspaceId}/invite`,

  GET_USER_LIST_TEAMSPACE_ACCESS: (teamspaceId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/${teamspaceId}/users`,

  REMOVE_USER_TEAMSPACE_ACCESS: (teamspaceId, userId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/teamspaces/${teamspaceId}/users/${userId}`,

  GET_TEAMSPACE_LIST_USER_ACCESS: (userId, CLIENT_ID) =>
    `${API_BASE_URL}clients/${CLIENT_ID}/users/${userId}/teamspaces`,

};
