import React, { useEffect, useRef, useState } from "react";
import "./trash-style.css";
import CustomTooltip from "../PopUps/CustomToolTip";

const TrashComponent = ({ title, employee_name, date }) => {
  const tooltipId = "trash-item-tooltip";
  const byteDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const time = new Date(date).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current) {
        const { scrollWidth, clientWidth } = titleRef.current;
        // Set showTooltip to true if the text overflows
        setShowTooltip(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [title]);

  return (
    <div className="trash-container">
      <div className="trash-title">
        <span
          ref={titleRef}
          data-tooltip-id={showTooltip ? tooltipId : undefined} // Only add tooltip if it should show
          data-tooltip-content={showTooltip ? title : undefined} // Only set content if it should show
        >
          {title}
        </span>
      </div>
      <div className="trash-detail">
        {employee_name} <span>|</span> {byteDate} {time}
      </div>
      {showTooltip && <CustomTooltip id={tooltipId} />} 
    </div>
  );
};

export default TrashComponent;
