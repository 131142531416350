import React, { useEffect, useRef, useState } from "react";
import "./editor-style.css";
import SvgCloseCross from "../../icons/CloseCross";
import SvgDoneCheck from "../../icons/DoneCheck";
import { apiService } from "../../services/apiService";
import { toast } from "react-toastify";

const SuggestionCardComponent = ({
  num,
  recommendationData,
  onTapAccept,
  updateAcceptStatus,
  onTapReject,
  isRecommendationAccepted,
  isRecommendationRejected,
  isActive,
  isVisible,
  onCoverTap,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!isActive && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isActive]);

  const [isAccepted, setIsAccepted] = useState(isRecommendationAccepted);
  const [isRejected, setIsRejected] = useState(isRecommendationRejected);
  const [isAcceptLoading, setIsAccpetLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  const {
    id,
    section_main_heading1,
    section_main_heading2,
    section_main_heading3,
    section_main_heading4,
    change_request_text,
    previous_string,
    change_request_type,
  } = recommendationData;

  let filteredContent = change_request_text;

  const removeHeadingAndElement = (content, heading) => {
    if (!heading) return content;
    const regex = new RegExp(
      `<h[1-6][^>]*>[^<]*${heading}[^<]*<\\/h[1-6]>`,
      "gi"
    );
    return content.replace(regex, "");
  };

  //Remove previous string text
  const removeMatchingNodes = (content, previousString) => {
    if (!previousString) return content;
    const contentContainer = document.createElement("div");
    contentContainer.innerHTML = content;

    const previousContainer = document.createElement("div");
    previousContainer.innerHTML = previousString;
    const removeMatchingContent = (contentParent, previousParent) => {
      const previousChildren = Array.from(previousParent.childNodes);
      previousChildren.forEach((previousChild) => {
        if (previousChild.nodeType === Node.ELEMENT_NODE) {
          const tagName = previousChild.tagName;
          const matchingNodes = Array.from(
            contentParent.querySelectorAll(tagName)
          ).filter(
            (node) =>
              node.textContent.trim() === previousChild.textContent.trim()
          );
          matchingNodes.forEach((node) => node.remove());
          removeMatchingContent(contentParent, previousChild);
        }
      });
    };
    removeMatchingContent(contentContainer, previousContainer);
    const removeEmptyNodes = (parent) => {
      const allNodes = parent.querySelectorAll("*");
      allNodes.forEach((node) => {
        if (
          (!node.textContent.trim() && !node.hasChildNodes()) ||
          (node.textContent.trim() === "" &&
            node.querySelectorAll("*").length === 1 &&
            node.querySelectorAll("span").length > 0) ||
          (node.tagName === "UL" && node.querySelectorAll("li").length === 0) ||
          (node.tagName === "OL" && node.querySelectorAll("li").length === 0)
        ) {
          node.remove();
        }
      });
    };
    removeEmptyNodes(contentContainer);
    return contentContainer.innerHTML;
  };

  //bold unmatched text
  // const boldUnmatchedText = (content, previousString) => {
  //   if (!previousString) return content;
  //   const contentContainer = document.createElement("div");
  //   contentContainer.innerHTML = content;
  //   const previousContainer = document.createElement("div");
  //   previousContainer.innerHTML = previousString;
  //   const boldUnmatchedContent = (contentParent, previousParent) => {
  //     const previousChildren = Array.from(previousParent.childNodes);
  //     const contentChildren = Array.from(contentParent.childNodes);
  //     contentChildren.forEach((contentChild) => {
  //       if (contentChild.nodeType === Node.TEXT_NODE) {
  //         const matched = previousChildren.some(
  //           (prevChild) =>
  //             prevChild.nodeType === Node.TEXT_NODE &&
  //             prevChild.textContent.trim() === contentChild.textContent.trim()
  //         );
  //         if (!matched) {
  //           const boldWrapper = document.createElement("b");
  //           boldWrapper.textContent = contentChild.textContent;
  //           contentParent.replaceChild(boldWrapper, contentChild);
  //         }
  //       } else if (contentChild.nodeType === Node.ELEMENT_NODE) {
  //         const matchingNodes = previousChildren.filter(
  //           (prevChild) =>
  //             prevChild.nodeType === Node.ELEMENT_NODE &&
  //             prevChild.tagName === contentChild.tagName &&
  //             prevChild.textContent.trim() === contentChild.textContent.trim()
  //         );
  //         if (matchingNodes.length === 0) {
  //           Array.from(contentChild.childNodes).forEach((node) => {
  //             if (node.nodeType === Node.TEXT_NODE) {
  //               const boldWrapper = document.createElement("b");
  //               boldWrapper.textContent = node.textContent;
  //               contentChild.replaceChild(boldWrapper, node);
  //             }
  //           });
  //         } else {
  //           boldUnmatchedContent(contentChild, matchingNodes[0]);
  //         }
  //       }
  //     });
  //   };

  //   boldUnmatchedContent(contentContainer, previousContainer);

  //   return contentContainer.innerHTML;
  // };

  //NEW ONE FOR SENTENCE
  const boldUnmatchedText = (content, previousString) => {
    if (!previousString) return content;

    // Helper function to extract words from a string
    const extractWords = (text) => {
      return text
        .replace(/([.,!?;])/g, "") // Remove punctuation
        .split(/\s+/) // Split by whitespace
        .map((word) => word.trim().toLowerCase()) // Normalize case
        .filter(Boolean); // Remove empty strings
    };

    // Extract words from both content and previousString
    const contentWords = extractWords(content);
    const previousWords = new Set(extractWords(previousString));

    // Process content and wrap unmatched words in <b> tags
    const processedContent = content
      .split(/\s+/) // Split content into words
      .map((word) => {
        const cleanedWord = word.replace(/[.,!?;]/g, "").toLowerCase(); // Clean word of punctuation for matching
        return previousWords.has(cleanedWord) ? word : `<b>${word}</b>`; // Wrap in <b> if not matched
      })
      .join(" "); // Rebuild the content with spaces

    return processedContent;
  };

  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading1
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading2
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading3
  );
  filteredContent = removeHeadingAndElement(
    filteredContent,
    section_main_heading4
  );
  if (change_request_type === "Add") {
    filteredContent = removeMatchingNodes(filteredContent, previous_string);
  } else {
    filteredContent = boldUnmatchedText(filteredContent, previous_string);
  }
  // Handle Recommendation Action
  const handleModifyRecommendation = async (action) => {
    const response = await apiService.acceptRejectByte(
      recommendationData.byte_id,
      recommendationData.doc_id,
      "h1",
      section_main_heading1,
      recommendationData.previous_string,
      action,
      id,
      "Update using button"
    );
    return response;
  };
  if (!filteredContent.trim()) {
    return null;
  }
  if (isVisible) {
    return null;
  }
  return (
    <div
      className={`suggestion-container ${isActive ? "active" : ""}`}
      onClick={() => {
        if (!isActive) {
          onCoverTap();
        }
      }}
    >
      <div className="suggestion-header">
        <span className="suggestion-num">{num}</span>
        <span className="suggestion-title">
          {recommendationData.change_request_type}
        </span>
      </div>
      <div
        className={`suggestion-content ${isActive ? "active" : ""}`}
        ref={contentRef}
      >
        <div dangerouslySetInnerHTML={{ __html: filteredContent }} />
      </div>
      {isActive &&
        (!isAccepted && !isRejected ? (
          <div className="suggestion-actions">
            <button
              className={`accept-button ${isAcceptLoading ? "disabled" : ""}`}
              onClick={async (e) => {
                e.stopPropagation();
                setIsAccpetLoading(true);
                const acceptResult = await onTapAccept();
                if (acceptResult === true) {
                  try {
                    const response = await handleModifyRecommendation(
                      "ACCEPTED"
                    );
                    if (response?.status === "success") {
                      setIsAccepted(true);
                      updateAcceptStatus();
                    } else {
                    }
                  } catch (e) {
                    toast.error("Please try again.");
                  } finally {
                    setIsAccpetLoading(false);
                  }
                } else {
                  toast.error("Text not found");
                  setIsAccpetLoading(false);
                }
              }}
              disabled={isAcceptLoading}
            >
              <SvgDoneCheck className="white-icon" /> Accept
              {isAcceptLoading && <span className="loading-circle"></span>}
            </button>
            <button
              className={`reject-button ${isRejectLoading ? "disabled" : ""}`}
              onClick={async (e) => {
                e.stopPropagation();
                setIsRejectLoading(true);
                try {
                  const response = await handleModifyRecommendation("REJECTED");
                  if (response?.status === "success") {
                    setIsRejected(true);
                    onTapReject();
                  } else {
                  }
                } catch (error) {
                  console.error("Error during rejection:", error);
                  toast.error("An error occurred. Please try again.");
                } finally {
                  setIsRejectLoading(false);
                }
              }}
              disabled={isRejectLoading}
            >
              <SvgCloseCross className="grey-icon" /> Reject
              {isRejectLoading && <span className="loading-circle"></span>}
            </button>
          </div>
        ) : (
          <div>
            {isAccepted && (
              <button className="final-button">
                <SvgDoneCheck className="final-icon" /> Accepted
              </button>
            )}
            {isRejected && (
              <button className="final-button">
                <SvgCloseCross className="final-icon" /> Rejected
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default SuggestionCardComponent;
