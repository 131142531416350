import { toast } from "react-toastify";
import { apiService } from "../services/apiService";

export const cacheDocument = (docId, content, fileName) => {
  const existingData = JSON.parse(localStorage.getItem(docId));

  if (!existingData) {
    const firstModifiedTimestamp = Date.now();
    const documentData = { content, fileName, firstModifiedTimestamp };
    localStorage.setItem(docId, JSON.stringify(documentData));
    const unsyncedDocuments =
      JSON.parse(localStorage.getItem("unsyncedDocuments")) || [];
    unsyncedDocuments.push(docId);
    localStorage.setItem(
      "unsyncedDocuments",
      JSON.stringify(unsyncedDocuments)
    );
  } else {
    existingData.content = content;
    localStorage.setItem(docId, JSON.stringify(existingData));
  }
};

// Sync documents
export const syncDocuments = async (syncAll = false) => {
  const unsyncedDocuments =
    JSON.parse(localStorage.getItem("unsyncedDocuments")) || [];

  if (unsyncedDocuments.length === 0) {
    return;
  }

  try {
    const documentsToSync = unsyncedDocuments
      .map((docId) => {
        const storedData = JSON.parse(localStorage.getItem(docId));
        if (!storedData) return null;

        const currentContent = storedData.content;
        const firstModifiedTimestamp = storedData.firstModifiedTimestamp;
        const currentTime = Date.now();

        if (!syncAll && currentTime - firstModifiedTimestamp < 60000)
          return null;

        return {
          id: docId,
          content: currentContent,
          fileName: storedData.fileName,
        };
      })
      .filter((doc) => doc !== null);

    if (documentsToSync.length === 0) {
      return;
    }

    // Using a for loop to upload documents one by one
    for (const doc of documentsToSync) {
      const { id, content, fileName } = doc;
      const htmlBlob = new Blob([content], { type: "text/html" });
      const htmlFile = new File([htmlBlob], fileName, {
        type: "text/html",
        lastModified: Date.now(),
      });
      const result = await apiService.uploadDocument(htmlFile, id);
      if (result.status) {
        let updatedUnsyncedDocs = unsyncedDocuments.filter(
          (docId) => docId !== id
        );
        localStorage.setItem(
          "unsyncedDocuments",
          JSON.stringify(updatedUnsyncedDocs)
        );
        localStorage.removeItem(id);
      } else {
        if (result === 400) {
          let updatedUnsyncedDocs = unsyncedDocuments.filter(
            (docId) => docId !== id
          );
          localStorage.setItem(
            "unsyncedDocuments",
            JSON.stringify(updatedUnsyncedDocs)
          );
          localStorage.removeItem(id);
        } else {
          toast.error("Something went Wrong!");
        }
      }
    }
    if (syncAll) {
      localStorage.removeItem("unsyncedDocuments");
    }
  } catch (error) {
    toast.error("Oops! The document wasn't updated. Something went wrong.");
  }
};

// Start syncing every minute (in the sync file)
export const startSyncInterval = () => {
  setInterval(syncDocuments, 60000);
};
export const getUnsyncedDocumentCount = () => {
  const unsyncedDocuments =
    JSON.parse(localStorage.getItem("unsyncedDocuments")) || [];
  return unsyncedDocuments.length;
};

// Handle browser tab or window close (beforeunload event)
export const handleBeforeUnload = (event) => {
  if (getUnsyncedDocumentCount() > 0) {
    const confirmationMessage =
      "Your changes are still syncing. Are you sure you want to leave?";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }
};

export const attachBeforeUnloadListener = () => {
  window.addEventListener("beforeunload", handleBeforeUnload);
};

export const detachBeforeUnloadListener = () => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
};
