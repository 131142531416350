// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trash-container {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--borderColor);
  cursor: default !important;
  animation: fadeIn 0.4s ease-in;
}
.trash-content {
  background-color: var(--sideBarBg);
  border-radius: 8px;
  cursor: default !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.trash-title {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 65%;
  font-size: var(--twelve-font-size);
  font-weight: 600;
  color: var(--textColor);
  overflow: hidden;
}
.trash-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trash-detail {
  font-size: var(--ten-font-size);
  font-weight: 400;
  color: var(--lightTextColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
}

.trash-detail span {
  margin: 0rem 0.8rem;
}

/*****************************************************************************/

@media (max-width: 768px) {
  .trash-container {
    padding: 0.2rem 0.5rem;
  }

  .trash-detail span {
    margin: 0px 0.4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Trash/trash-style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,6CAA6C;EAC7C,0BAA0B;EAC1B,8BAA8B;AAChC;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,kCAAkC;EAClC,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA,8EAA8E;;AAE9E;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".trash-container {\n  padding: 0.5rem 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 0.5px solid var(--borderColor);\n  cursor: default !important;\n  animation: fadeIn 0.4s ease-in;\n}\n.trash-content {\n  background-color: var(--sideBarBg);\n  border-radius: 8px;\n  cursor: default !important;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.trash-title {\n  display: flex;\n  flex-direction: row;\n  align-items: start;\n  width: 65%;\n  font-size: var(--twelve-font-size);\n  font-weight: 600;\n  color: var(--textColor);\n  overflow: hidden;\n}\n.trash-title span {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.trash-detail {\n  font-size: var(--ten-font-size);\n  font-weight: 400;\n  color: var(--lightTextColor);\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  flex-grow: 1;\n}\n\n.trash-detail span {\n  margin: 0rem 0.8rem;\n}\n\n/*****************************************************************************/\n\n@media (max-width: 768px) {\n  .trash-container {\n    padding: 0.2rem 0.5rem;\n  }\n\n  .trash-detail span {\n    margin: 0px 0.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
