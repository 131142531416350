import React, { useEffect, useRef, useState } from "react";
import "../editor-style.css";
import SvgCloseCross from "../../../icons/CloseCross";
import SvgDoneCheck from "../../../icons/DoneCheck";
import { apiService } from "../../../services/apiService";
import { toast } from "react-toastify";
import { IoIosInformationCircleOutline } from "react-icons/io";
import InfoTooltip from "../../PopUps/InfoToolTip";
import { diff_match_patch } from "diff-match-patch";

const SuggestionCardComponent = ({
  num,
  recommendationData,
  onTapAccept,
  updateAcceptStatus,
  onTapReject,
  isRecommendationAccepted,
  isRecommendationRejected,
  isActive,
  isVisible,
  onCoverTap,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!isActive && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isActive]);
  const tooltipId = "info-tooltip";

  const [isAccepted, setIsAccepted] = useState(isRecommendationAccepted);
  const [isRejected, setIsRejected] = useState(isRecommendationRejected);
  const [isAcceptLoading, setIsAccpetLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  const {
    id,
    section_main_heading1,
    change_request_text,
    previous_string,
    prediction,
  } = recommendationData;
  //old
  function compareHtmlStrings(previousString, changeRequestText) {
    const parseHTML = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      return [...doc.body.childNodes];
    };

    const normalizeText = (text) =>
      text.trim().replace(/\s+/g, " ").toLowerCase();

    const calculateSimilarity = (text1, text2) => {
      const words1 = normalizeText(text1).split(" ");
      const words2 = normalizeText(text2).split(" ");
      const commonWords = words1.filter((word) => words2.includes(word));
      const similarity =
        (2 * commonWords.length) / (words1.length + words2.length);
      return similarity;
    };

    const nodesAreSimilar = (node1, node2) => {
      if (!node1 || !node2) return false;
      const text1 = node1.outerHTML || node1.nodeValue || "";
      const text2 = node2.outerHTML || node2.nodeValue || "";
      const isSimilar = calculateSimilarity(text1, text2) > 0.7;
      if (node2.textContent === node1.textContent) {
        return true;
      }
      if (
        normalizeText(node1.textContent) === normalizeText(node2.textContent)
      ) {
        return true;
      }
      return isSimilar;
    };
    let list = [];
    const prevNodes = parseHTML(previousString);
    const newNodes = parseHTML(changeRequestText);
    const usedPrevNodes = new Set();
    const usedNewNodes = new Set();
    newNodes.forEach((newNode, j) => {
      let matched = false;
      prevNodes.forEach((prevNode, i) => {
        if (
          !usedPrevNodes.has(i) &&
          !usedNewNodes.has(j) &&
          nodesAreSimilar(prevNode, newNode)
        ) {
          const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
          const newContent = newNode?.outerHTML || newNode?.nodeValue || "";

          if (normalizeText(prevContent) || normalizeText(newContent)) {
            list.push({
              prevString: prevContent,
              newString: newContent,
            });
          }

          usedPrevNodes.add(i);
          usedNewNodes.add(j);
          matched = true;
        }
      });

      if (!matched) {
        const newContent = newNode?.outerHTML || newNode?.nodeValue || "";
        if (normalizeText(newContent)) {
          list.push({
            prevString: "",
            newString: newContent,
          });
        }
      }
    });

    prevNodes.forEach((prevNode, i) => {
      if (!usedPrevNodes.has(i)) {
        const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
        if (normalizeText(prevContent)) {
          list.push({
            prevString: prevContent,
            newString: "",
          });
        }
      }
    });

    // Merge consecutive entries with `prev: ""`
    const mergedList = [];
    for (let i = 0; i < list.length; i++) {
      const current = list[i];
      if (
        current.prevString === "" &&
        i > 0 &&
        mergedList[mergedList.length - 1].prevString === "" &&
        mergedList[mergedList.length - 1].newString !== ""
      ) {
        mergedList[mergedList.length - 1].newString += current.newString;
      } else {
        mergedList.push(current);
      }
    }

    return mergedList;
  }
  
  const stripOuterTags = (htmlString) => {
    // Remove all HTML tags using a regular expression
    return htmlString.replace(/<[^>]*>/g, "");
  };

  const differences = compareHtmlStrings(previous_string, change_request_text);

  const generateChangeSummary = (previousString, changeRequestText) => {
    const prevContent = stripOuterTags(previousString);
    const newContent = stripOuterTags(changeRequestText);
    const dmp = new diff_match_patch();
    let diffs = dmp.diff_main(prevContent, newContent, true);
    dmp.diff_cleanupSemantic(diffs);

    const deletedWords = [];
    const addedWords = [];
    const replacedWords = [];
    const punctuationChanges = {
      ".": [],
      ",": [],
      "'": [],
      '"': [],
      // Add more punctuations if needed
    };

    diffs.forEach((diff, index) => {
      if (diff[0] === -1) {
        if (diffs[index + 1] && diffs[index + 1][0] === 1) {
          replacedWords.push({
            from: diff[1].trim(),
            to: diffs[index + 1][1].trim(),
          });
        } else {
          deletedWords.push(diff[1].trim());
        }
      } else if (diff[0] === 1) {
        if (!(diffs[index - 1] && diffs[index - 1][0] === -1)) {
          addedWords.push(diff[1].trim());
          // Check for punctuation marks
          const punctuationMatch = diff[1].trim().match(/[.,'"]+/);
          if (punctuationMatch) {
            punctuationMatch.forEach((punctuation) => {
              punctuationChanges[punctuation] =
                punctuationChanges[punctuation] || [];
              punctuationChanges[punctuation].push(diff[1].trim());
            });
          }
        }
      }
    });

    // Utility to truncate long strings
    const truncateLongText = (text) => {
      if (text.length > 100) {
        return `<span>${text.slice(0, 100)}....</span>`;
      }
      return text;
    };

    // Build the summary sections dynamically
    const summarySections = [];

    if (deletedWords.length > 0) {
      summarySections.push(
        `<div><strong>Delete:</strong><ul>${deletedWords
          .map((word) => `<li><i>${truncateLongText(word)}</i></li>`)
          .join("")}</ul></div>`
      );
    }

    if (replacedWords.length > 0) {
      summarySections.push(
        `<div><strong>Replace:</strong><ul>${replacedWords
          .map(
            (change) =>
              `<li><i>"${truncateLongText(
                change.from
              )}" with ${truncateLongText(change.to)}</i></li>`
          )
          .join("")}</ul></div>`
      );
    }

    if (addedWords.length > 0) {
      summarySections.push(
        `<div><strong>Add:</strong><ul>${addedWords
          .map((word) => `<li>${truncateLongText(word)}</li>`)
          .join("")}</ul></div>`
      );
    }

    return summarySections.length > 0 ? summarySections.join("") : "";
  };

 const generateFinalSummary = (differences) => {
    const summaries = differences.map(({ prevString, newString }) =>
      generateChangeSummary(prevString, newString)
    );

    const combinedSummary = {
      Replace: [],
      Delete: [],
      Add: [],
    };

    // Temporary arrays to track added and deleted punctuation marks
    const punctuationChanges = {
      fullstop: { add: [], delete: [] },
      comma: { add: [], delete: [] },
      apostrophe: { add: [], delete: [] },
      "question mark": { add: [], delete: [] },
    };

    // Process the summary differences
    summaries.forEach((summary) => {
      const temp = new DOMParser().parseFromString(summary, "text/html");
      const sections = temp.body.querySelectorAll("div");

      sections.forEach((section) => {
        const title = section
          .querySelector("strong")
          .textContent.replace(":", "")
          .trim();
        const items = section.querySelectorAll("ul li");

        items.forEach((item) => {
          const itemText = item.textContent.trim();
        
          if (itemText !== "") {
            // Skip single-character punctuation marks from final summary
            if (itemText.length === 1 && /[.,'!?]/.test(itemText)) {
              return;
            }
        
            // Handle Add, Delete, and Replace sections
            if (title === "Add") {
              combinedSummary.Add.push(item.innerHTML);
            } else if (title === "Delete") {
              combinedSummary.Delete.push(item.innerHTML);
            } else if (title === "Replace") {
              combinedSummary.Replace.push(item.innerHTML);
            }
          }
        });
        
      });
    });

    // Handle punctuation changes and integrate them into Add and Delete sections
    if (punctuationChanges.fullstop.add.length > 0) {
      if (punctuationChanges.fullstop.add.length === 0) {
        combinedSummary.Add.push(`Added a fullstop`);
      } else {
        combinedSummary.Add.push(`Added a few fullstops`);
      }
    }
    if (punctuationChanges.comma.add.length > 0) {
      if (punctuationChanges.comma.add.length === 0) {
        combinedSummary.Add.push(`Added a comma`);
      } else {
        combinedSummary.Add.push(`Added a few commas`);
      }
    }
    if (punctuationChanges.apostrophe.add.length > 0) {
      if (punctuationChanges.apostrophe.add.length === 0) {
        combinedSummary.Add.push(`Added a apostrope`);
      } else {
        combinedSummary.Add.push(`Added a few apostropes`);
      }
    }
    if (punctuationChanges["question mark"].add.length > 0) {
      if (punctuationChanges["question mark"].add.length === 0) {
        combinedSummary.Add.push(`Added a question mark`);
      } else {
        combinedSummary.Add.push(`Added a few question marks`);
      }
    }

    if (punctuationChanges.fullstop.delete.length > 0) {
      if (punctuationChanges.comma.delete.length === 0) {
        combinedSummary.Delete.push(`Deleted a fullstop`);
      } else {
        combinedSummary.Delete.push(`Deleted a few fullstops`);
      }
    }
    if (punctuationChanges.comma.delete.length > 0) {
      if (punctuationChanges.comma.delete.length === 0) {
        combinedSummary.Delete.push(`Deleted a comma`);
      } else {
        combinedSummary.Delete.push(`Deleted a few commas`);
      }
    }
    if (punctuationChanges.apostrophe.delete.length > 0) {
      if (punctuationChanges.apostrophe.delete.length === 0) {
        combinedSummary.Delete.push(`Deleted a apostrophe`);
      } else {
        combinedSummary.Delete.push(`apostrophes`);
      }
    }
    if (punctuationChanges["question mark"].delete.length > 0) {
      if (punctuationChanges["question mark"].delete.length === 0) {
        combinedSummary.Delete.push(`Deleted a question mark`);
      } else {
        combinedSummary.Delete.push(`Deleted a few question marks`);
      }
    }

    // Generate final combined summary
    let finalSummary = "";

    Object.keys(combinedSummary).forEach((section) => {
      if (combinedSummary[section].length > 0) {
        finalSummary += `<div><strong>${section}:</strong><ul>`;
        combinedSummary[section]
          .filter((item) => {
            const trimmedItem = item.replace(/\s+/g, "");
            return !/^[.,'!?]+$/.test(trimmedItem);
          })
          .forEach((item) => {
            finalSummary += `<li>${item}</li>`;
          });
        finalSummary += `</ul></div>`;
      }
    });

    return finalSummary;
  };

  let filteredContent = generateFinalSummary(differences);

  const suggestionTitle =
    filteredContent.includes("Delete:") || filteredContent.includes("Replace:")
      ? "Revisions"
      : "Additions";
  // Handle Recommendation Action
  const handleModifyRecommendation = async (action) => {
    const response = await apiService.acceptRejectByte(
      recommendationData.byte_id,
      recommendationData.doc_id,
      "h1",
      section_main_heading1,
      recommendationData.previous_string,
      action,
      id,
      "Update using button"
    );
    return response;
  };

  if (isVisible) {
    return null;
  }
  return (
    <div
      className={`suggestion-container ${isActive ? "active" : ""} 
      ${isAccepted ? "accepted" : ""} 
      ${isRejected ? "rejected" : ""}`}
      onClick={() => {
        if (!isActive) {
          onCoverTap();
        }
      }}
    >
      <InfoTooltip id={tooltipId} />
      <div className="suggestion-title">
        <div className="suggestion-header">
          <span className="suggestion-num">{num}</span>
          <span className="suggestion-title">{suggestionTitle}</span>{" "}
          <IoIosInformationCircleOutline
            className="info-icon"
            data-tooltip-id={tooltipId}
            data-tooltip-content={prediction}
          />
        </div>
        {isAccepted && (
          <button className="final-button-accept">Accepted</button>
        )}
        {isRejected && (
          <button className="final-button-reject">Rejected</button>
        )}
        {isActive &&
          !isAccepted &&
          !isRejected &&
          (isAcceptLoading || isRejectLoading ? (
            <div className="loading-spinner"></div>
          ) : (
            <div className="suggestion-action">
              <SvgDoneCheck
                className="final-icon"
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsAccpetLoading(true);
                  const acceptResult = await onTapAccept();
                  if (acceptResult === true) {
                    try {
                      const response = await handleModifyRecommendation(
                        "ACCEPTED"
                      );
                      if (response?.status === "success") {
                        setIsAccepted(true);
                        updateAcceptStatus();
                      } else {
                      }
                    } catch (e) {
                      toast.error("Please try again.");
                    } finally {
                      setIsAccpetLoading(false);
                    }
                  } else {
                    toast.error("Text not found");
                    setIsAccpetLoading(false);
                  }
                }}
              />
              <SvgCloseCross
                className="grey-icon"
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsRejectLoading(true);
                  try {
                    const response = await handleModifyRecommendation(
                      "REJECTED"
                    );
                    if (response?.status === "success") {
                      setIsRejected(true);
                      onTapReject();
                    } else {
                    }
                  } catch (error) {
                    toast.error("An error occurred. Please try again.");
                  } finally {
                    setIsRejectLoading(false);
                  }
                }}
              />
            </div>
          ))}
      </div>
      <div
        className={`suggestion-content ${isActive ? "active" : ""}`}
        ref={contentRef}
      >
        <div dangerouslySetInnerHTML={{ __html: filteredContent }} />
      </div>
    </div>
  );
};

export default SuggestionCardComponent;
