import React, { useEffect, useRef, useState } from "react";
import "./tab-body-style.css";
import CustomTooltip from "../PopUps/CustomToolTip";
import SvgDoneCheck from "../../icons/DoneCheck";

const ResolvedRequestComponent = ({ title, employee_name, date }) => {
  const tooltipId = "resolved-request-tooltip";
  const byteDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const time = new Date(date).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const titleRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const handleTooltipVisibility = () => {
      if (titleRef.current) {
        const textWidth = titleRef.current.scrollWidth; 
        const containerWidth = titleRef.current.clientWidth; 
        setShowTooltip(textWidth > containerWidth); 
      }
    };

    handleTooltipVisibility(); 
    window.addEventListener("resize", handleTooltipVisibility); 

    return () => {
      window.removeEventListener("resize", handleTooltipVisibility); 
    };
  }, [title]); 

  return (
    <div className="open-request-container-resolved">
      <div className="header-title">
        <SvgDoneCheck className="done-svg-icon" />
        <span
          ref={titleRef}
          data-tooltip-id={tooltipId}
          data-tooltip-content={showTooltip ? title : ""}
          className={showTooltip ? "has-tooltip" : ""}
        >
          {title}
        </span>
      </div>
      <div className="header-detail">
        {employee_name} <span>|</span> {byteDate} {time}
      </div>
      {showTooltip && <CustomTooltip id={tooltipId} />}
    </div>
  );
};

export default ResolvedRequestComponent;
