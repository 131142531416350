import React, { useState, useEffect, forwardRef, useContext } from "react";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/themes/dark.min.css";

import ChangeRequest from "./DocumentEdit/changeRequest";
import { ENDPOINTS } from "../../services/endPoint";
import { ThemeContext } from "../Theme/ThemeContext";

const EditorComponent = forwardRef(
  (
    {
      model,
      onModelChange,
      handleContentChange,
      activeDocIdRef,
      handleOpenResolveWarning,
      editorWidth,
      senderName,
      date,
      byte_content,
      handlePrevious,
      handleOnTap,
      handleNext,
      showRetryButton,
      byteId,
      isCRComponentVisible,
      sortedRecommendationData,
      currentRecommendationIndex,
    },
    ref
  ) => {
    const [editorReady, setEditorReady] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(true);
    const [bottomVisible, setButtonVisible] = useState(4);

    const { theme } = useContext(ThemeContext);
    useEffect(() => {
      const toolbar = document.querySelector(".toolbar-container");
      if (!toolbar) return;
    
      const checkWidth = () => {
        setIsSmallScreen(toolbar.offsetWidth < 1000); 
        if(toolbar.offsetWidth < 900){
          setButtonVisible(2)
        }else if(toolbar.offsetWidth < 950){
          setButtonVisible(3)
        }
      };
          checkWidth();
          const observer = new ResizeObserver(checkWidth);
      observer.observe(toolbar);
          window.addEventListener("resize", checkWidth);
    
      return () => {
        observer.disconnect();
        window.removeEventListener("resize", checkWidth);
      };
    }, []);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowEditor(true);
      }, 100);

      return () => clearTimeout(timer);
    }, []);

    return (
      <div className="change-request">
        {isCRComponentVisible && (
          <ChangeRequest
            onResolve={handleOpenResolveWarning}
            width={editorWidth ?? 320}
            showRetryButton={showRetryButton}
            requester={senderName ?? "loading"}
            date={date ?? "Date"}
            byteId={byteId}
            message={byte_content ?? "Change byte"}
            aiEdits={`${
              sortedRecommendationData && sortedRecommendationData.length > 0
                ? currentRecommendationIndex + 1
                : 0
            }/${sortedRecommendationData?.length ?? 0}`}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onTap={handleOnTap}
          />
        )}
        <div ref={ref}>
          {showEditor ? (
            <FroalaEditorComponent
              tag="textarea"
              model={editorReady ? model : ""}
              onModelChange={onModelChange}
              config={{
                theme,
                toolbarVisibleWithoutSelection: true,
                charCounterCount: true,
                toolbarContainer: "#toolbar-container",
                editorClass: "froala-editor",
                toolbarSticky: true,
                spellcheck: true,
                attribution: true,
                linkAlwaysBlank: true,
                heightMin: 200,
                heightMax: 800,
                imageUploadParam: "image",
                imageUploadURL: ENDPOINTS.UPLOAD_IMAGE(
                  activeDocIdRef.current,
                  localStorage.getItem("clientId")
                ),
                imageUploadMethod: "POST",
                imageMaxSize: 5 * 1024 * 1024,
                imageAllowedTypes: ["jpeg", "jpg", "png"],
                placeholderText: "Edit your content here...",
                events: {
                  initialized: function () {
                    try {
                      setEditorReady(true);
                      if (!this.html) {
                        return;
                      }
                      const secondToolbar =
                        document.querySelector(".fr-second-toolbar");
                      if (secondToolbar) secondToolbar.remove();
                    } catch (e) {}
                  },
                  "commands.before": function (cmd, param1, param2) {
                    try {
                      if (
                        cmd === "paragraphFormat" &&
                        (param1 === "H1" ||
                          param1 === "H2" ||
                          param1 === "H3" ||
                          param1 === "H4")
                      ) {
                        const userSelection = window.getSelection();
                        if (
                          userSelection &&
                          userSelection.rangeCount &&
                          userSelection.toString().trim() !== ""
                        ) {
                          const range = userSelection.getRangeAt(0);
                          let selectedNode = range.startContainer.parentNode;
                          if (
                            selectedNode &&
                            selectedNode.nodeName.toLowerCase() === "span"
                          ) {
                            const spanParent = selectedNode;
                            const grandParent = spanParent.parentNode;
                            while (spanParent.firstChild) {
                              grandParent.insertBefore(
                                spanParent.firstChild,
                                spanParent
                              );
                            }
                            grandParent.removeChild(spanParent);
                          }
                        }
                      }
                      if (
                        cmd === "html" ||
                        (cmd === "paragraphFormat" && param1 === "PRE")
                      ) {
                        const userSelection = window.getSelection();
                        if (
                          userSelection &&
                          userSelection.rangeCount &&
                          userSelection.toString().trim() !== ""
                        ) {
                          const range = userSelection.getRangeAt(0);
                          const selectedNode = range.startContainer;
                          const parentNode = selectedNode.parentNode;

                          if (selectedNode.nodeType === 3) {
                            if (parentNode && parentNode.nodeName === "CODE") {
                              if (
                                range.toString().trim() !==
                                parentNode.textContent.trim()
                              ) {
                                return false;
                              }
                              const cleanedText = parentNode.textContent;
                              this.html.insert(cleanedText);
                            } else {
                              const clonedContent = range.cloneContents();
                              const wrapper = document.createElement("div");
                              wrapper.appendChild(clonedContent);
                              const wrappedCode = `<code>${wrapper.innerHTML}</code>`;
                              this.html.insert(wrappedCode);
                            }
                          } else if (
                            parentNode &&
                            parentNode.nodeName === "CODE"
                          ) {
                            return false;
                          } else {
                            const codeBlock = "<code> </code>";
                            this.html.insert(codeBlock);
                          }
                        } else {
                          const selectionParent = userSelection.anchorNode
                            ? userSelection.anchorNode.parentNode
                            : null;
                          if (
                            !selectionParent ||
                            selectionParent.nodeName !== "CODE"
                          ) {
                            const codeBlock = "<code> </code>";
                            this.html.insert(codeBlock);
                          } else {
                          }
                        }

                        return false;
                      }
                      const updatedModel = this.html.get();
                      handleContentChange(updatedModel, activeDocIdRef.current);
                    } catch (e) {}
                  },
                  "commands.after": function (cmd, param1, param2) {
                    try {
                      const updatedModel = this.html.get();
                      handleContentChange(updatedModel, activeDocIdRef.current);
                    } catch (e) {}
                  },
                  "paste.before": function (clipboardEvent) {
                    try {
                      const userSelection = window.getSelection();
                      if (
                        userSelection &&
                        userSelection.anchorNode &&
                        userSelection.anchorNode.parentNode.nodeName === "CODE"
                      ) {
                        clipboardEvent.preventDefault();
                        const clipboardData = clipboardEvent.clipboardData;
                        //  || window.clipboardData //Temp comment
                        const pastedHTML = clipboardData.getData("text/html");
                        const pastedText = clipboardData.getData("text/plain");
                        const contentToInsert = pastedHTML || pastedText;
                        if (contentToInsert.trim() === "") {
                          return false;
                        }
                        const codeNode = userSelection.anchorNode.parentNode;
                        const existingContent = codeNode.innerHTML;
                        const updatedContent = `${existingContent}${contentToInsert}`;
                        codeNode.innerHTML = updatedContent;

                        return false;
                      }
                    } catch (e) {}
                  },
                  "paste.after": function () {
                    try {
                      const updatedModel = this.html.get();
                      if (updatedModel) {
                        handleContentChange(
                          updatedModel,
                          activeDocIdRef.current
                        );
                      } else {
                      }
                    } catch (e) {}
                  },
                  keydown: function (e) {
                    try {
                      if (
                        e.key === "Backspace" ||
                        e.key === "Delete" ||
                        e.key === "Enter"
                      ) {
                        const updatedModel = this.html.get();
                        handleContentChange(
                          updatedModel,
                          activeDocIdRef.current
                        );
                      }
                      const editor = this;
                      const keyCode = e.which || e.keyCode;

                      if (keyCode === 9) {
                        e.preventDefault();
                        const range = editor.selection.ranges(0);

                        if (range && range.startContainer) {
                          const listItem = editor.selection.element();
                          if (listItem.tagName === "LI") {
                            if (e.shiftKey) {
                              editor.commands.outdent();
                            } else {
                              editor.commands.indent();
                            }
                          }
                        }
                      }
                      if (keyCode === 32) {
                        const range = editor.selection.ranges(0);
                        if (range) {
                          const textBefore = range.startContainer.textContent
                            .substring(0, range.startOffset)
                            .trim();
                          if (textBefore === "1.") {
                            e.preventDefault();
                            range.startContainer.textContent =
                              range.startContainer.textContent.replace(
                                "1.",
                                ""
                              );
                            editor.html.insert("<ol><li><br></li></ol>");
                          }
                        }

                        if (range) {
                          const textBefore = range.startContainer.textContent
                            .substring(0, range.startOffset)
                            .trim();
                          if (textBefore === "*") {
                            e.preventDefault();
                            range.startContainer.textContent =
                              range.startContainer.textContent.replace("*", "");
                            editor.html.insert("<ul><li></li></ul>");
                            // const list = editor.el.querySelector("ul li");
                          }
                        }
                      }
                      if (keyCode === 8) {
                        const selection = editor.selection;
                        const element = selection.element();
                        if (element) {
                          const parentNode = element.parentNode;
                          if (parentNode) {
                            if (element.tagName === "BR") {
                              const listItem = element.parentNode;
                              if (listItem && listItem.tagName === "LI") {
                                const list = listItem.closest("ol");
                                const isEmpty =
                                  listItem.innerHTML.trim() === "<br>";
                                const isSingleItemList =
                                  list.querySelectorAll("li").length === 1;
                                if (isEmpty && isSingleItemList) {
                                  e.preventDefault();
                                  const parent = list.parentNode;
                                  const pNode = document.createElement("p");
                                  pNode.innerHTML = "1.&nbsp;";
                                  parent.replaceChild(pNode, list);
                                  const range = document.createRange();
                                  const sel = window.getSelection();
                                  const textNode = pNode.firstChild;
                                  range.setStart(
                                    textNode,
                                    textNode.textContent.length
                                  );
                                  range.collapse(true);
                                  sel.removeAllRanges();
                                  sel.addRange(range);
                                }
                              }
                            }
                          }
                        }
                      }
                    } catch (error) {}
                  },
                  input: function (inputEvent) {
                    try {
                      const updatedModel = this.html.get();
                      handleContentChange(updatedModel, activeDocIdRef.current);
                    } catch (e) {}
                  },
                  "imageManager.beforeDeleteImage": function ($img) {},
                  "image.removed": function ($img) {
                    return false;
                  },
                  "image.resize": function ($img) {
                    try {
                      const updatedModel = this.html.get();
                      handleContentChange(updatedModel, activeDocIdRef.current);
                    } catch (e) {}
                  },
                  "image.inserted": function ($img, response) {
                    const updatedModel = this.html.get();
                    handleContentChange(updatedModel, activeDocIdRef.current);
                  },
                  "image.uploaded": function (response) {
                    let jsonResponse = JSON.parse(response);
                    if (!jsonResponse.link) {
                      this.events.trigger("image.error", {
                        code: 2,
                        message: "No link in upload response.",
                      });
                      return false;
                    } else {
                    }
                    this.image.insert(
                      jsonResponse.link,
                      false,
                      null,
                      this.image.get(),
                      null
                    );
                  },
                },
                pluginsEnabled: [
                  "fullscreen",
                  "align",
                  "image",
                  "link",
                  "fontFamily",
                  "fontSize",
                  "specialCharacters",
                  "lists",
                  "table",
                  "codeView",
                  "colors",
                  "backgroundColor",
                  "inlineStyle",
                  "undo",
                  "redo",
                  "paragraphFormat",
                  "inlineStyle",
                  "clearFormatting",
                  "increaseIndent",
                ],
                toolbarButtons: {
                  moreText: {
                    buttons: [
                      "paragraphFormat",
                      "fontFamily",
                      "fontSize",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "textColor",
                      "backgroundColor",
                      "strikeThrough",
                      "html",
                      "subscript",
                      "superscript",
                      "specialCharacters",
                    ],
                    align: "left",
                    buttonsVisible: isSmallScreen ? bottomVisible : 6,
                  },
                  moreParagraph: {
                    buttons: [
                      "insertCode",
                      "alignLeft",
                      "alignCenter",
                      "alignRight",
                      "alignJustify",
                      "indent",
                      "outdent",
                    ],
                    align: "left",
                    buttonsVisible: 0,
                  },
                  moreRich: {
                    buttons: [
                      "formatUL",
                      "formatOL",
                      "formatOLSimple",
                      "|",
                      "insertLink",
                      "insertImage",
                      "insertTable",
                    ],
                    align: "left",
                    buttonsVisible:isSmallScreen ? bottomVisible : 6,
                  },
                  moreMisc: {
                    buttons: ["undo", "redo", "formatPainter"],
                    align: "right",
                    buttonsVisible: 2,
                  },
                },
                showColorsPopup: true,
                fontFamilySelection: true,
                fontSizeSelection: true,
                paragraphFormatSelection: true,
              }}
            />
          ) : (
            <p></p>
          )}
        </div>{" "}
      </div>
    );
  }
);

export default EditorComponent;
