import React, { useEffect, useState } from "react";
import "./skeleton-loader-style.css";

export default function UniqueLoadingComponent() {
  const text = "Modifying Document...";
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index <= text.length) {
        setDisplayedText(text.slice(0, index));
        index++;
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="unique-loading-container">
      <span className="unique-loading-text">
        {displayedText}
        <span className="unique-highlight"></span>
      </span>
    </div>
  );
}
