import React, { useEffect, useRef, useState } from "react";
import icons from "../../assets/icons";
import "./tab-body-style.css";
import CustomTooltip from "../PopUps/CustomToolTip";

const OpenRequestComponent = ({
  title,
  employee_name,
  date,
  aiEdits,
  onClick,
  onClickDelete,
}) => {
  const tooltipId = "open-request-tooltip";
  const byteDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const time = new Date(date).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const titleRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current) {
        const textWidth = titleRef.current.scrollWidth; 
        const containerWidth = titleRef.current.clientWidth; 
        setShowTooltip(textWidth > containerWidth); 
      }
    };

    checkOverflow(); // Check overflow on mount
    window.addEventListener("resize", checkOverflow); // Check on window resize

    return () => {
      window.removeEventListener("resize", checkOverflow); // Clean up on unmount
    };
  }, [title]); // Run effect whenever title changes

  return (
    <div className="open-request-container" onClick={onClick}>
      <div className="main-content">
        <p
          ref={titleRef}
          data-tooltip-id={tooltipId}
          data-tooltip-content={showTooltip ? title : ""}
          className={showTooltip ? "has-tooltip" : ""}
        >
          {title}
        </p>
        <div className="main-content-row">
          <span>{employee_name}</span>
          <p>{byteDate} {time}</p>
        </div>
      </div>
      <div className="ai-edit">
        <span>{aiEdits}</span>
        <p>AI Edits</p>
      </div>
      <img
        className="delete-icon"
        src={icons.trashIcon}
        alt="icon"
        onClick={(e) => {
          e.stopPropagation();
          onClickDelete();
        }}
      />
      {showTooltip && <CustomTooltip id={tooltipId} />}
    </div>
  );
};

export default OpenRequestComponent;
