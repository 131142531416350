import React, { useState, useEffect } from "react";
import {
  getUnsyncedDocumentCount,
  syncDocuments,
} from "../../custom/syncDocument";
import ResolveChangeRequestPopUp from "./ResolveChangeRequestPopUp";

const UnsavedChangesHandler = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Handle the attempt to leave the page
  const handleBeforeUnload = (event) => {
    if (getUnsyncedDocumentCount() > 0) {
      setShowPopup(true);
    }
  };

  // Handle "Stay and Save" button click
  const handleStayAndSave = async () => {
    await syncDocuments(true);
    setShowPopup(false);
  };

  // Handle "Leave Anyway" button click
  const handleLeaveAnyway = () => {
    window.location.reload();
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload); // Cleanup on unmount
    };
  }, []);
  return (
    <>
      <ResolveChangeRequestPopUp
        isVisible={showPopup}
        onClickLButton={handleLeaveAnyway}
        onClickRButton={handleStayAndSave}
        onClose={handleStayAndSave}
        title="Unsaved Changes - Are You Sure You Want to Leave?"
        subtitle="Your latest changes have not been saved yet. Leaving now may result in data loss. Would you like to wait while we sync your changes?"
        lButtonText="Leave Anyway"
        rButtonText="Stay and Save"
      />
    </>
  );
};

export default UnsavedChangesHandler;
