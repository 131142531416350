import React, { useEffect, useState } from "react";

const NotificationBar = ({ isVisible }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
    } else {
      const timeout = setTimeout(() => setShow(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  if (!show && !isVisible) {
    return null;
  }
  return (
    <div
      style={{
        ...styles.container,
        opacity: show ? 1 : 0, 
        transform: show ? "translateY(0)" : "translateY(-30px)",
        transition: "opacity 0.5s ease, transform 0.5s ease",
      }}
    >
      <span style={styles.text}>
        We noticed some irrelevant AI edit suggestions that were automatically
        handled and removed. The total AI edits have been reviewed and updated.{" "}
      </span>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#006666",
    marginBottom: "10px",
    color: "#f8f5f5",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "4px",
    zIndex: 10000,
    transform: "translateX(-50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  text: {
    flex: 1,
    marginRight: "20px",
  },
};

export default NotificationBar;
