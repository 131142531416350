import React, { useState, useEffect } from "react";
import "./editor-style.css";
import { X, Send } from "lucide-react";
import { apiService } from "../../services/apiService";

export default function ChatBotUI({
  visibility,
  onClose,
  chatReset,
  onApiCall,
  documentId,
  teamspaceId,
  handlebyteProcessing,
  sendByte,
}) {
  const [messages, setMessages] = useState([
    {
      text: "Need edits? Just type your request, and I'll handle it!",
      sender: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setMessages([
      {
        text: "Need edits? Just type your request, and I'll handle it!",
        sender: "bot",
      },
    ]);

    setInput("");
  }, [chatReset]);

  const sendMessage = async () => {
    try {
      if (input.trim() === "") return;
      setIsSending(true);

      setMessages([...messages, { text: input, sender: "user" }]);
      setInput("");

      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          { text: "Got it! Let me check.", sender: "bot" },
        ]);
      }, 100);
      await onApiCall();
      var result = await apiService.getRecommendationForDocBasedByte(
        input,
        teamspaceId,
        documentId
      );
      if (result.status === "success") {
        handlebyteProcessing(false);
        await sendByte(result.recommendation.request_id ?? null);
        // handleClose();
        setTimeout(() => {
          setIsSending(false);
          if (result.recommendation.length<1) {
            setMessages((prev) => [
              ...prev,
              {
                text: "Your instruction was too short and unclear. Can you expand your instruction",
                sender: "bot",
              },
            ]);
          } else {
            // handleClose();
          }
        }, 1000);
      }
    } catch (e) {
      setMessages((prev) => [
        ...prev,
        {
          text: "Something went wrong. Try Again",
          sender: "bot",
        },
      ]);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 400);
  };

  return (
    visibility && (
      <div className={`chatbot-container ${isClosing ? "hide" : ""}`}>
        <div className="chatbot-header">
          <span></span>
          <X className="close-icon" onClick={handleClose} />
        </div>
        <div className="chatbot-messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              {msg.text}
            </div>
          ))}
        </div>
        <div className="chatbot-input">
          <textarea
            className="chatbot-textarea"
            type="text"
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (!isSending) sendMessage();
              }
            }}          />
          <button
            onClick={sendMessage}
            className={`send-button ${isSending ? "disabled" : ""}`}
            disabled={isSending}
          >
            <Send size={18} className="rotated-icon" />
          </button>
        </div>
      </div>
    )
  );
}
