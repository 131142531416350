import React, { useState, useEffect, useRef } from "react";
import "./popup-style.css";
import HeaderSubHeadingComponent from "./CustomComponets";
import SvgCloseCross from "../../icons/CloseCross";
import { toast } from "react-toastify";
import { apiService } from "../../services/apiService";

const AddChangeRequestPopUp = ({ isVisible, onClose, onClick }) => {
  const [recommendation, setRecommendation] = useState("");
  const [loading, setLoading] = useState(false);
  const [teamspaces, setTeamspaces] = useState([]);
  const [selectedTeamspace, setSelectedTeamspace] = useState(null);
  const [fetchingTeamspaces, setFetchingTeamspaces] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      fetchTeamspaces();
    }
  }, [isVisible]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const fetchTeamspaces = async () => {
    const clientId = localStorage.getItem("clientId");
    if (!clientId) {
      toast.error("Please refresh & try again");
      return;
    }

    setFetchingTeamspaces(true);
    try {
      const response = await apiService.teamspaceListWithUserAccess(clientId);
      if (response?.length) {
        setTeamspaces(response);
      } else {
        setTeamspaces([]);
        toast.info("No teamspaces found. Please check your access.");
      }
    } catch (error) {
      setTeamspaces([]);
      toast.error("Failed to fetch teamspaces. Please try again.");
    } finally {
      setFetchingTeamspaces(false);
    }
  };

  const handleNewRequest = async () => {
    if (!recommendation.trim()) {
      toast.error("Please write a request");
      return;
    }
    if (!selectedTeamspace) {
      toast.error("Please select a teamspace");
      return;
    }

    setLoading(true);
    try {
      const requestData = {
        teamspaceId: selectedTeamspace,
        recommendation: recommendation.trim(),
      };
      await apiService.createChangeRequest(recommendation, selectedTeamspace);
      await onClick();
      handleClose();
    } catch (error) {
      toast.error("Failed to submit request. Try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setRecommendation("");
    setSelectedTeamspace(null);
    setSearch("");
    onClose();
  };

  const filteredTeamspaces = teamspaces.filter((ts) =>
    ts.teamspaceName.toLowerCase().includes(search.toLowerCase())
  );

  if (!isVisible) return null;

  return (
    <div className="popup-overlay show" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="show-row-space-between-header">
          <HeaderSubHeadingComponent
            title="Change Request"
            subtitle="Relax, AiEdits will help to resolve your requests"
          />
          <SvgCloseCross className="cross-icon" onClick={handleClose} />
        </div>

        {/* Custom Dropdown */}
        <div className="text-form-field-popup">
          <span className="teamspace-title">Teamspace</span>
          <div className="teamspace-custom-dropdown" ref={dropdownRef}>
            <div
              className={`teamspace-dropdown-header ${
                dropdownOpen ? "open" : ""
              }`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {selectedTeamspace
                ? teamspaces.find((ts) => ts.id === selectedTeamspace)
                    ?.teamspaceName
                : fetchingTeamspaces
                ? "Loading teamspaces..."
                : "Select a teamspace"}
            </div>

            {dropdownOpen && (
              <div className="teamspace-dropdown-menu">
                {/* <input
                  type="text"
                  placeholder="Search teamspaces..."
                  className="teamspace-dropdown-search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  autoFocus
                /> */}
                <div className="teamspace-dropdown-items">
                  {filteredTeamspaces.length > 0 ? (
                    filteredTeamspaces.map((ts) => (
                      <div
                        key={ts.id}
                        className="teamspace-dropdown-item"
                        onClick={() => {
                          setSelectedTeamspace(ts.id);
                          setDropdownOpen(false);
                        }}
                      >
                        {ts.teamspaceName}
                      </div>
                    ))
                  ) : (
                    <div className="teamspace-dropdown-item no-data">
                      No teamspaces
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Request Textarea */}
        <div className="text-form-field-popup">
          Write about your new request
          <textarea
            className="add-request-form"
            placeholder="Add text..."
            value={recommendation}
            onChange={(e) => setRecommendation(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <button
          disabled={!recommendation.trim() || !selectedTeamspace || loading}
          className={`popup-button ${
            !recommendation.trim() || !selectedTeamspace
              ? "disabled-button"
              : ""
          }`}
          onClick={handleNewRequest}
        >
          New Request
          {loading && <span className="button-loader"></span>}
        </button>
      </div>
    </div>
  );
};

export default AddChangeRequestPopUp;
