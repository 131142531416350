import React from "react";
import "./editor-style.css";

export default function AutoEditButton({buttonText, disabled, onClick }) {
  return (
    <button
      className={`auto-edit-btn ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
     {buttonText}
    </button>
  );
}
