import React, { useState, useEffect } from "react";
import "./style.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LoginPage from "./pages/loginPage";
import HomePage from "./pages/homePage";
import { AuthProvider } from "./components/ProtectedRoute/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HubspotForm from "./pages/ContactUs";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("authToken")
  );

  const isAuthenticated = () => {
    return isLoggedIn;
  };
  useEffect(() => {
    const authListener = () => {
      setIsLoggedIn(!!localStorage.getItem("authToken"));
    };
    window.addEventListener("storage", authListener);

    return () => {
      window.removeEventListener("storage", authListener);
    };
  }, []);
  return (
    <AuthProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Navigate to="/home/all-requests" />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path="/home/*" element={<HomePage />} />
          <Route path="/contact-us" element={<HubspotForm />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
