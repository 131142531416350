import React, { useState, useEffect,useRef } from "react";
import Sidebar from "../components/SideBar/SideBar";
import { Route, Routes, useLocation } from "react-router-dom";
import "../style.css";
import AllRequests from "./allRequest";
import TrashPage from "./TrashPage";
import { useAuth } from "../components/ProtectedRoute/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiService } from "../services/apiService";
import DeletePopUp from "../components/PopUps/DeletePopUp";
import IntegrationForm from "./IntegrationPage";
import ResolveChangeRequestPopUp from "../components/PopUps/ResolveChangeRequestPopUp";
import GenerateDocWithAi from "../components/FroalaEditor/GenerateDoc/GenerateDocPage";
import DocumentEditor from "../components/FroalaEditor/Document/DocumentEditor";
import DocumentEditEditor from "../components/FroalaEditor/DocumentEdit/DocumentEditEditor";

const HomePage = () => {
  const { setIsLoggedIn } = useAuth();
  const [isSessionExpiredPopVisible, setIsSessionExpiredPopVisible] =
    useState(false);
  const [isLogoutPopVisible, setIsLogoutPopVisible] = useState(false);
  const [activeItem, setActiveItem] = useState("All Requests");
  const [isTeamspaceOpen, setIsTeamspaceOpen] = useState(true);
  const conversationRef=useRef([]);
  const [generatedModel, setGeneratedModel] = useState("");
  const [generatedFileName, setGeneratedFileName] = useState("");
  const [generateDocDescription, setGenerateDocDescription] = useState("");
  const [generateDocInstruction, setGenerateDocInstruction] = useState("");
  const [isbyteProcessing, setByteProcessing] = useState(false);
  const [isNavigationBlocked, setIsNavigationBlocked] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  //Auth
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token || apiService.isTokenExpired()) {
      setIsLoggedIn(false);
      setIsSessionExpiredPopVisible(true);
      apiService.logout();
    }
  }, [setIsLoggedIn]);

  //Update Active Item for sidebar
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("open-byte")) {
      setByteProcessing(false);
      setActiveItem("All Requests");
    } else if (path.includes("resolved-byte")) {
      setActiveItem("All Requests");
    } else if (path.includes("trash")) {
      setActiveItem("Trash");
    } else if (path.includes("integration")) {
      setActiveItem("Integration");
    }else if (path.includes("generate-document")) {
      setIsTeamspaceOpen(true);
      const id = path.split("/")[3];
      setActiveItem(id);
    }  else if (path.includes("document-edit") || path.includes("document")) {
      setIsTeamspaceOpen(true);
      const id = path.split("/").pop();
      setActiveItem(id);
    } else if (path.includes("feedback")) {
      setActiveItem("Feedback");
    } else {
      setActiveItem("");
    }
  }, [location, setIsLoggedIn, navigate]);

  //Handle Logout Option
  const handleOpenLogoutPopUp = () => {
    setIsLogoutPopVisible(true);
  };

  //Handle Byte Processing : Froze screen
  const handlebyteProcessing = (value = !isbyteProcessing) => {
    setByteProcessing(value);
  };

  //Handle Move Away Navigation
  const handleNavigationAttempt = (newPath) => {
    if (typeof generatedModel === "string"&&generatedModel.trim()) {
      setIsNavigationBlocked(true);
      setPendingNavigation(newPath);
    } else {
      navigate(newPath);
    }
  };

  const confirmNavigation = () => {
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setIsNavigationBlocked(false);
      setGeneratedModel("");
      setPendingNavigation(null);
    }
  };

  const cancelNavigation = () => {
    setIsNavigationBlocked(false);
    setPendingNavigation(null);
  };

  //Handle Doc-Generate with Ai
  const handleGeneratedModel = (model) => {
    setGeneratedModel(model);
  };
  const handleGeneratedDocDescription = (about) => {
    setGenerateDocDescription(about);
  };
  const handleGeneratedDocInstruction = (instruction) => {
    setGenerateDocInstruction(instruction);
  };
  const handleFileName = (fileName) => {
    setGeneratedFileName(fileName);
  };

  //Handle Conversation
  const addToConversation = (newMessage) => {
 conversationRef.current = [...conversationRef.current, newMessage];
  };
  
  return (
    <div className="home">
      <ResolveChangeRequestPopUp
        isVisible={isNavigationBlocked}
        onClickLButton={confirmNavigation}
        onClickRButton={cancelNavigation}
        onClose={cancelNavigation}
        title="Unsaved Changes - Are You Sure You Want to Leave?"
        subtitle="Your latest changes have not been saved yet. Leaving now may result in data loss."
        lButtonText="Leave Anyway"
        rButtonText="Stay"
      />
      <Sidebar
        activeItem={activeItem}
        isTeamspaceOpen={isTeamspaceOpen}
        setIsTeamspaceOpen={setIsTeamspaceOpen}
        handleOpenLogoutPopUp={handleOpenLogoutPopUp}
        onNavigate={handleNavigationAttempt}
      />
      <div className="content">
        <Routes>
          <Route
            path="all-requests/open-byte"
            element={<AllRequests tab="open" />}
          />
          <Route
            path="all-requests/resolved-byte"
            element={<AllRequests tab="resolved" />}
          />
          <Route path="trash" element={<TrashPage />} />
          <Route path="integration" element={<IntegrationForm />} />
          <Route
            path=":byteId/document-edit/:id/"
            element={
              <DocumentEditEditor
                isByteProcessing={isbyteProcessing}
                handlebyteProcessing={handlebyteProcessing}
                handleGerneratedDoc={handleGeneratedModel}
                handleFileName={handleFileName}
                handleGeneratedDocDescription={handleGeneratedDocDescription}
                handleGeneratedDocInstruction={handleGeneratedDocInstruction}
                conversation={conversationRef}
                handleConversation={addToConversation}
              />
            }
          />
          <Route
            path="document/:id"
            element={
              <DocumentEditor
                isByteProcessing={isbyteProcessing}
                handlebyteProcessing={handlebyteProcessing}
                handleGerneratedDoc={handleGeneratedModel}
                handleFileName={handleFileName}
                handleGeneratedDocDescription={handleGeneratedDocDescription}
                handleGeneratedDocInstruction={handleGeneratedDocInstruction}
                conversation={conversationRef}
                handleConversation={addToConversation}
              />
            }
          />
          <Route
            path="generate-document/:id/:docName"
            element={
              <GenerateDocWithAi
                generatedModel={generatedModel}
                updateGenerateModel={handleGeneratedModel}
                generatedFileName={generatedFileName}
                generatedDocDescription={generateDocDescription}
                generatedDocInstruction={generateDocInstruction}
                conversation={conversationRef}
                handleConversation={addToConversation}
              />
            }
          />
        </Routes>

        <DeletePopUp
          isVisible={isSessionExpiredPopVisible || isLogoutPopVisible}
          title={isSessionExpiredPopVisible ? "Session has expired" : "Log out"}
          buttonText={isSessionExpiredPopVisible ? "Login again" : "Log out"}
          subtitle={
            isSessionExpiredPopVisible
              ? "Sorry, but you need to login again to continue"
              : "Are you sure you want to log out?"
          }
          desc={isSessionExpiredPopVisible ? "" : "You will be logged out."}
          onClick={() => {
            if (isSessionExpiredPopVisible) {
              navigate("/");
            } else {
              apiService.logout();
              setIsLoggedIn(false);
              navigate("/");
            }
          }}
          onClose={() => {
            setIsSessionExpiredPopVisible(false);
            setIsLogoutPopVisible(false);
          }}
        />
      </div>
    </div>
  );
};

export default HomePage;
