import React, { useRef, useEffect, useCallback, useReducer } from "react";
import "../editor-style.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/apiService.js";
import EditorSkeleton from "../../loading-screen/EditorSkeleton.js";
import { toast } from "react-toastify";
import FroalaEditor from "../FroalaEditorComponent.js";
import { cacheDocument } from "../../../custom/syncDocument.js";
import ChatBotUI from "../ChatBot.js";
import BuildDocPopUp from "../../PopUps/BuildDocPopUp.js";
import AutoEditButton from "../AutoEdit.js";
import ProcessByteLoadingScreen from "../../loading-screen/ProcessingByte.js";
import AskChatbot from "../../QueryBot/AskBotButton.js";
import RecommendationSkeletonLoader from "../../loading-screen/RecommendationSkeleton.js";

const DocumentEditor = ({
  isByteProcessing,
  handlebyteProcessing,
  handleGerneratedDoc,
  handleGeneratedDocDescription,
  handleGeneratedDocInstruction,
  handleFileName,
  handleConversation,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debounceDelay = 500;
  const requestDataRef = useRef(null);
  const activeDocIdRef = useRef("");
  const teamspaceIdRef = useRef("");
  const activeByteIdRef = useRef("");
  const activeFileName = useRef("");
  const editorRef = useRef(null);
  const dBModelRef = useRef("");
  const location = useLocation();
  const navigate = useNavigate();
  const { byteId, id } = useParams();

  useEffect(() => {
    dispatch({ type: "RESET" });
    fetchData();
    activeByteIdRef.current = byteId;
    activeDocIdRef.current = id;
  }, [location.pathname]);

  //FETCH DOCUMENT DATA
  const fetchData = async () => {
    try {
      const underProcessingBytes =
        JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
      if (
        underProcessingBytes.some((item) => String(item.docId) === String(id))
      ) {
        dispatch({ type: "SET_IS_DOC_LEVEL_BYTE_PROCESSED", payload: false });
      } else {
        dispatch({ type: "SET_IS_DOC_LEVEL_BYTE_PROCESSED", payload: true });
      }
      await handleDocumentBasedRecommendation();
    } catch (error) {
      toast.error("Invalid Document! Please try again");
      navigate("/home/all-requests/open-byte");
    }
  };

  // Function to handle document-based recommendations
  const handleDocumentBasedRecommendation = async () => {
    const response = await apiService.getRecommendationSingleDoc(id);
    if (response) {
      requestDataRef.current = response.data;
      const url = response.data.document.doc_content;
      dispatch({ type: "SET_LOADING", payload: false });
      const [htmlContent] = await Promise.all([fetchHTMLContent(url, id)]);
      teamspaceIdRef.current = response.data?.document?.teamspace_id;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;

      const bodyChildren =
        tempDiv.querySelector("body")?.children || tempDiv.children;

      const isOnlyH1 =
        bodyChildren.length === 1 && bodyChildren[0].tagName === "H1";

      dispatch({
        type: "SET_SHOW_BUILD_POP_UP",
        payload: isOnlyH1,
      });
      dispatch({ type: "SET_LOADING", payload: false });

      dBModelRef.current = htmlContent;
      activeFileName.current = url.substring(url.lastIndexOf("/") + 1);
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          fileName: url.substring(url.lastIndexOf("/") + 1),
          model: htmlContent,
        },
      });
    }
  };

  // Helper function to fetch and parse HTML content
  const fetchHTMLContent = async (url, docId) => {
    const cachedData = JSON.parse(localStorage.getItem(docId));
    if (cachedData && cachedData.content) {
      return cachedData.content;
    }
    const cacheBuster = `?_=${new Date().getTime()}`;
    const fullUrl = url.includes("?")
      ? `${url}&${cacheBuster}`
      : `${url}${cacheBuster}`;

    const htmlResponse = await fetch(fullUrl, {
      mode: "cors",
      cache: "no-store",
    });
    if (!htmlResponse.ok) {
      return;
    }
    const htmlText = await htmlResponse.text();

    return htmlText;
  };

  //Upload Document
  const uploadDocument = async (newContent, docId) => {
    if (docId !== activeDocIdRef.current) {
      return;
    }
    const cleanedContent = removeMarkTags(newContent);
    cacheDocument(docId, cleanedContent, activeFileName.current);
  };

  const debouncedUpload = debounceWithCancel((newContent, docId) => {
    uploadDocument(newContent, docId);
  }, debounceDelay);

  const removeMarkTags = (htmlContent) => {
    if (!htmlContent || typeof htmlContent !== "string") return "";
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    const marks = tempElement.querySelectorAll("mark");
    const brTags = tempElement.querySelectorAll(`br[data-location]`);
    const spanTags = tempElement.querySelectorAll(`span[data-location]`);
    const hiddenElements = tempElement.querySelectorAll("ul, ol, p, span");

    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;
      if (isRemoved) {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      } else {
        mark.remove();
      }
    });

    brTags.forEach((br) => {
      br.remove();
    });
    spanTags.forEach((span) => {
      span.remove();
    });
    hiddenElements.forEach((el) => {
      if (window.getComputedStyle(el).visibility === "hidden") {
        el.remove();
      }
    });
    const froalaTag = tempElement.querySelector(
      'p a[title="Froala Editor"]'
    )?.parentNode;
    if (froalaTag) {
      froalaTag.remove();
    }

    return tempElement.innerHTML;
  };

  const handleContentChange = (newContent, docId) => {
    debouncedUpload(newContent, docId);
  };

  //Handle size of change Request Header
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        dispatch({
          type: "SET_EDITOR_WIDTH",
          payload: entry.contentRect.width,
        });
        dispatch({
          type: "SET_EDITOR_HEIGHT",
          payload: entry.contentRect.height,
        });
      }
    });
    const editorElement = editorRef.current;
    if (editorElement && editorElement instanceof Element) {
      resizeObserver.observe(editorElement);
    }
    return () => {
      if (editorElement && editorElement instanceof Element) {
        resizeObserver.unobserve(editorElement);
      }
    };
  }, [state.modelState.model]);

  //Handle Auto-edit Chat bot
  const handleChatBoxState = () => {
    if (state.uiState.showChatBox) {
      dispatch({ type: "SET_SHOW_CHATBOX", payload: false });
    } else {
      dispatch({ type: "SET_SHOW_CHATBOX", payload: true });
    }
  };

  //Froala Model Callback
  const handleModelChange = useCallback((newModel) => {
    dispatch({
      type: "SET_MODEL",
      payload: newModel,
    });
  }, []);

  //Build Doc With Ai
  const handleGenerateDocument = async (instructions, about, conversation) => {
    try {
      handleConversation({ role: "user", content: "Generate the document" });
      navigate(
        `/home/generate-document/${activeDocIdRef.current}/${requestDataRef.current.document.doc_name}`
      );
      const generatedDoc = await apiService.generateDocumentWithAi(
        instructions,
        about,
        activeDocIdRef.current,
        requestDataRef.current.document.doc_name,
        conversation
      );
      if (generatedDoc.status === "SUCCESS") {
        handleGeneratedDocDescription(about);
        handleGeneratedDocInstruction(instructions);
        handleFileName(activeFileName.current);
        handleGerneratedDoc(generatedDoc?.document);
        if (generatedDoc?.document) {
          handleConversation({
            role: "assistant",
            content: generatedDoc?.document,
          });
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  //HANDLE BUILD POP UP
  const handleCloseBuildPopUp = async () => {
    dispatch({ type: "SET_SHOW_BUILD_POP_UP", payload: false });
  };

  //Handle Byte Processing State
  const handleByteProcessingState = async () => {
    const docId = activeDocIdRef.current;
    let processingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    processingBytes.push({ docId, timestamp: Date.now() });
    localStorage.setItem(
      "underProcessingBytes",
      JSON.stringify(processingBytes)
    );
    setTimeout(() => {
      removeExpiredProcessingBytes();
    }, 5 * 60 * 1000);
    await handlebyteProcessing();
  };

  const removeExpiredProcessingBytes = () => {
    let processingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    const updatedBytes = processingBytes.filter(
      (item) => Date.now() - item.timestamp < 5 * 60 * 1000
    );
    localStorage.setItem("underProcessingBytes", JSON.stringify(updatedBytes));
  };

  //Handle Adding a byte flow
  const handleDocBasedByte = async (recommendationByte) => {
    navigate(
      `/home/${recommendationByte}/document-edit/${activeDocIdRef.current}`
    );
    const underProcessingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    const docId = activeDocIdRef.current;
    const updatedBytes = underProcessingBytes.filter(
      (item) => String(item.docId) !== String(docId)
    );
    localStorage.setItem("underProcessingBytes", JSON.stringify(updatedBytes));
  };

  //LOADING - Skeleton loading
  if (state.loadingState.isLoading) {
    return (
      <div id="editor" className="froala-editor-section">
        <div id="toolbar-container" className="toolbar-container"></div>
        <div style={{ paddingBottom: "15px" }}>
          <EditorSkeleton
            height="40px"
            borderRadius="100px"
            padding="4px 0px"
          />
        </div>
        <div className="editor-suggestion">
          <div className="change-request">
            <EditorSkeleton width="100%" height="100vh" borderRadius="4px" />
          </div>
          <RecommendationSkeletonLoader count={4} />
        </div>
      </div>
    );
  }

  //Main Body
  return (
    <div className="doc-editor">
      <AskChatbot
        teamspaceId={teamspaceIdRef.current}
        documentId={activeDocIdRef.current}
      />
      <div id="editor" className="froala-editor-section fade-in">
        {/* Toolbar Container */}
        <div className="auto-edit-bar">
          <div id="toolbar-container" className="toolbar-container"></div>
          <AutoEditButton
            buttonText="Auto Edit"
            disabled={
              !state.loadingState.isDocLevelByteProcessingComplete ||
              state.uiState.showChatBox
            }
            onClick={handleChatBoxState}
          />
          <BuildDocPopUp
            isVisible={state.uiState.showBuildDocPopUp}
            onClose={handleCloseBuildPopUp}
            onClick={async (instructions, about, conversation) => {
              await handleGenerateDocument(instructions, about, conversation);
              return true;
            }}
          />
        </div>

        <div className="editor-suggestion">
          {(isByteProcessing ||
            !state.loadingState.isDocLevelByteProcessingComplete) && (
            <ProcessByteLoadingScreen
              width={state.uiState.editorWidth}
              height={state.uiState.editorHeight}
            />
          )}

          {state.loadingState.isLoading ? (
            <EditorSkeleton />
          ) : (
            // Froala Editor
            <FroalaEditor
              ref={editorRef}
              model={state.modelState.model}
              onModelChange={handleModelChange}
              activeDocIdRef={activeDocIdRef}
              handleContentChange={handleContentChange}
            />
          )}
          <div className="suggestion-auto-edit">
            <ChatBotUI
              visibility={state.uiState.showChatBox}
              onClose={handleChatBoxState}
              chatReset={state.uiState.resetChatBot}
              documentId={activeDocIdRef.current}
              teamspaceId={teamspaceIdRef.current}
              onApiCall={handleByteProcessingState}
              handlebyteProcessing={(value) => {
                handlebyteProcessing(value);
              }}
              sendByte={(recommendationByte) => {
                try {
                  handleDocBasedByte(recommendationByte);
                  return true;
                } catch (error) {
                  return false;
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentEditor;

const debounceWithCancel = (func, delay) => {
  let timeoutId;
  let isCancelled = false;

  const debouncedFunction = (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    isCancelled = false;
    timeoutId = setTimeout(() => {
      if (!isCancelled) {
        func.apply(null, args);
      }
    }, delay);
  };

  debouncedFunction.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    isCancelled = true;
  };

  return debouncedFunction;
};

const initialState = {
  loadingState: {
    isLoading: true,
    isSuggestionLoading: true,
    isDocLevelByteProcessingComplete: true,
  },
  uiState: {
    showBuildDocPopUp: false,
    showChatBox: false,
    editorWidth: 860,
    editorHeight: 860,
    resetChatBot: false,
  },

  fileState: {
    fileName: "",
  },
  modelState: {
    model: "Loading",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_CHAT_BOT":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          resetChatBot: action.payload,
        },
      };
    case "SET_SHOW_BUILD_POP_UP":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showBuildDocPopUp: action.payload,
        },
      };
    case "SET_SHOW_CHATBOX":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showChatBox: action.payload,
        },
      };
    case "SET_EDITOR_WIDTH":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          editorWidth: action.payload,
        },
      };
    case "SET_EDITOR_HEIGHT":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          editorHeight: action.payload,
        },
      };
    case "UPDATE_STATE":
      return {
        ...state,
        fileState: { ...state.fileState, fileName: action.payload.fileName },
        modelState: {
          ...state.modelState,
          model: action.payload.model,
        },
      };
    case "SET_LOADING":
      return {
        ...state,
        loadingState: { ...state.loadingState, isLoading: action.payload },
      };
    case "SET_IS_DOC_LEVEL_BYTE_PROCESSED":
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          isDocLevelByteProcessingComplete: action.payload,
        },
      };
    case "SET_IS_SUGGESTION_LOADING":
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          isSuggestionLoading: action.payload,
        },
      };
    case "SET_MODEL":
      return {
        ...state,
        modelState: { ...state.modelState, model: action.payload },
      };
    case "SET_FILE_NAME":
      return {
        ...state,
        fileState: { ...state.fileState, fileName: action.payload },
      };
    case "RESET":
      return { ...initialState };
    default:
      return state;
  }
};
