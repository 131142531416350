import React, { useEffect,useCallback, useState, useContext, useRef } from "react";
import "../editor-style.css";
import { ENDPOINTS } from "../../../services/endPoint.js";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/themes/dark.min.css";
import { ThemeContext } from "../../Theme/ThemeContext.js";
import AutoEditButton from "../AutoEdit.js";
import BuildDocChatBotUI from "./BuildDocChatBot.js";
import { apiService } from "../../../services/apiService.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import KnowledgeKeeperDoc from "./KnowldegeKeeperDoc.js";

const GenerateDocWithAi = ({
  generatedModel,
  updateGenerateModel,
  generatedDocDescription,
  generatedDocInstruction,
  generatedFileName,
  conversation,
  handleConversation,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [bottomVisible, setButtonVisible] = useState(4);
  const { id, docName } = useParams();
  const containerRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const toolbar = document.querySelector(".toolbar-container");
    if (!toolbar) return;

    const checkWidth = () => {
      setIsSmallScreen(toolbar.offsetWidth < 1000);
      if (toolbar.offsetWidth < 900) {
        setButtonVisible(2);
      } else if (toolbar.offsetWidth < 950) {
        setButtonVisible(3);
      }
    };
    checkWidth();
    const observer = new ResizeObserver(checkWidth);
    observer.observe(toolbar);
    window.addEventListener("resize", checkWidth);

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkWidth);
    };
  }, []);

  //Handle refresh
  const handleHardReload = useCallback(() => {
    if (conversation.current.length < 1) {
      navigate(`/home/document/${id}`, { replace: true });
    }
  }, [id, navigate, conversation]); // ✅ Dependencies

  useEffect(() => {
    handleHardReload();
  }, [handleHardReload]);


  
  useEffect(() => {
    if (conversation.current.length === 2) {
      setLoading(false);
    }
  }, [generatedModel,conversation]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (generatedModel.trim()) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [generatedModel]);

  //Handles html issue
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowEditor(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  //Generate Document
  const handleGenerateDocument = async () => {
    try {
      setLoading(true);
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }

      const generatedDoc = await apiService.generateDocumentWithAi(
        generatedDocInstruction,
        generatedDocDescription,
        id,
        docName,
        conversation.current
      );
      updateGenerateModel(generatedDoc?.document);
      if (generatedDoc?.document) {
        handleConversation({
          role: "assistant",
          content: generatedDoc?.document,
        });
      } else {
        toast.error("Something went wrong");
      }
      // setTimeout(() => {
      //   if (containerRef.current) {
      //     containerRef.current.scrollTo({
      //       top: containerRef.current.scrollHeight,
      //       behavior: "smooth",
      //     });
      //   }
      // }, 3000);
    } catch {
      //Handle catch
    } finally {
      setLoading(false);
    }
  };

  //Save-document
  const handleSaveDcoument = async () => {
    const htmlBlob = new Blob([generatedModel], { type: "text/html" });
    const htmlFile = new File([htmlBlob], generatedFileName, {
      type: "text/html",
      lastModified: Date.now(),
    });
    const result = await apiService.uploadDocument(htmlFile, id);
    if (result.status) {
      updateGenerateModel("");
      navigate(`/home/document/${id}`, {
        replace: true,
      });
    }
  };

  return (
    <div className="doc-editor">
      <div id="editor" className="froala-editor-section fade-in">
        <div className="auto-edit-bar">
          <div id="toolbar-container" className="toolbar-container"></div>
          <AutoEditButton
            buttonText="Upload"
            disabled={false}
            onClick={handleSaveDcoument}
          />
        </div>
        <div className="editor-suggestion" ref={containerRef}>
          <div className="change-request">
            <KnowledgeKeeperDoc content={generatedModel} loading={loading} />
            <div style={{ visibility: "hidden" }}>
              {showEditor && (
                <FroalaEditorComponent
                  tag="textarea"
                  model={generatedModel}
                  config={{
                    theme,
                    toolbarVisibleWithoutSelection: true,
                    charCounterCount: true,
                    toolbarContainer: "#toolbar-container",
                    editorClass: "froala-editor",
                    toolbarSticky: true,
                    spellcheck: true,
                    attribution: true,
                    heightMin: 200,
                    heightMax: 800,
                    imageUploadParam: "image",
                    imageUploadURL: ENDPOINTS.UPLOAD_IMAGE(
                      id,
                      localStorage.getItem("clientId")
                    ),
                    imageUploadMethod: "POST",
                    imageMaxSize: 5 * 1024 * 1024,
                    imageAllowedTypes: ["jpeg", "jpg", "png"],
                    placeholderText: "Edit your content here...",
                    events: {
                      initialized: function () {
                        try {
                          // setEditorReady(true);
                          if (!this.html) {
                            return;
                          }
                          // this.edit.off();
                          const secondToolbar =
                            document.querySelector(".fr-second-toolbar");
                          if (secondToolbar) secondToolbar.remove();
                        } catch (e) {}
                      },
                      "commands.before": function (cmd, param1, param2) {
                        return false;
                      },
                    },

                    pluginsEnabled: [
                      "fullscreen",
                      "align",
                      "image",
                      "link",
                      "fontFamily",
                      "fontSize",
                      "specialCharacters",
                      "lists",
                      "table",
                      "codeView",
                      "colors",
                      "backgroundColor",
                      "inlineStyle",
                      "undo",
                      "redo",
                      "paragraphFormat",
                      "inlineStyle",
                      "clearFormatting",
                      "increaseIndent",
                    ],
                    toolbarButtons: {
                      moreText: {
                        buttons: [
                          "paragraphFormat",
                          "fontFamily",
                          "fontSize",
                          "|",
                          "bold",
                          "italic",
                          "underline",
                          "textColor",
                          "backgroundColor",
                          "strikeThrough",
                          "html",
                          "subscript",
                          "superscript",
                          "specialCharacters",
                        ],
                        align: "left",
                        buttonsVisible: isSmallScreen ? bottomVisible : 6,
                      },
                      moreParagraph: {
                        buttons: [
                          "insertCode",
                          "alignLeft",
                          "alignCenter",
                          "alignRight",
                          "alignJustify",
                          "indent",
                          "outdent",
                        ],
                        align: "left",
                        buttonsVisible: 0,
                      },
                      moreRich: {
                        buttons: [
                          "formatUL",
                          "formatOL",
                          "formatOLSimple",
                          "|",
                          "insertLink",
                          "insertImage",
                          "insertTable",
                        ],
                        align: "left",
                        buttonsVisible: isSmallScreen ? bottomVisible : 6,
                      },
                      moreMisc: {
                        buttons: ["undo", "redo", "formatPainter"],
                        align: "right",
                        buttonsVisible: 3,
                      },
                    },
                    linkAlwaysBlank: true,
                    showColorsPopup: true,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    paragraphFormatSelection: true,
                  }}
                />
              )}{" "}
            </div>
          </div>
          <div className="suggestion-auto-edit">
            <BuildDocChatBotUI
              conversation={conversation}
              handleConveration={(message) => {
                handleConversation(message);
              }}
              onClick={async () => {
                try {
                  await handleGenerateDocument();
                  return true;
                } catch (error) {
                  return false;
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateDocWithAi;
