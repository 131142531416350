import React, { useRef, useEffect, useCallback, useReducer } from "react";
import "../editor-style.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/apiService.js";
import RecommendationSkeletonLoader from "../../loading-screen/RecommendationSkeleton.js";
import EditorSkeleton from "../../loading-screen/EditorSkeleton.js";
import ResolveChangeRequestPopUp from "../../PopUps/ResolveChangeRequestPopUp.js";
import { toast } from "react-toastify";
import SuggestionList from "./SuggestionListComponent.js";
import { diff_match_patch } from "diff-match-patch";
import FroalaEditor from "../FroalaEditorComponent.js";
import { cacheDocument } from "../../../custom/syncDocument.js";
import ChatBotUI from "../ChatBot.js";
import { REMOVE_CHARACTERS } from "../constants.js";
import BuildDocPopUp from "../../PopUps/BuildDocPopUp.js";
import AutoEditButton from "../AutoEdit.js";
import ProcessByteLoadingScreen from "../../loading-screen/ProcessingByte.js";

const DocumentEditEditor = ({
  isByteProcessing,
  handlebyteProcessing,
  handleGerneratedDoc,
  handleGeneratedDocDescription,
  handleGeneratedDocInstruction,
  handleFileName,
  handleConversation,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debounceDelay = 500;
  const requestDataRef = useRef(null);
  const modelRef = useRef(state.modelState.model);
  const activeDocIdRef = useRef("");
  const teamspaceIdRef = useRef("");
  const activeByteIdRef = useRef("");
  const activeFileName = useRef("");
  const acceptedArray = useRef([]);
  const rejectedArray = useRef([]);
  const activeRecommendationDataRef = useRef(
    state.recommendationState.activeRecommendationData
  );
  const recommendationDataRef = useRef(
    state.recommendationState.recommendationData
  );
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const dBModelRef = useRef("");
  const location = useLocation();
  const navigate = useNavigate();
  const { byteId, id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    activeByteIdRef.current = byteId;
    activeDocIdRef.current = id;
  }, [location.pathname]);

  //FETCH API
  const fetchData = async () => {
    try {
      await handleByteBasedRecommendation();
    } catch (error) {
      toast.error("Invalid Document! Please try again");
      navigate("/home/all-requests/open-byte");
    }
  };

  // Function to handle Byte-based recommendations
  const handleByteBasedRecommendation = async (newByteId = byteId) => {
    try {
      const response = await apiService.getRecommendationForByte(
        !activeByteIdRef.current ? newByteId : activeByteIdRef.current
      );
      if (response) {
        requestDataRef.current = response.data;
        dispatch({ type: "SET_API_DATA", payload: response.data });
        const result = determineDocumentURL(response);
        const url = result[0];
        const currentDocId = result[1];
        const [htmlContent] = await Promise.all([
          fetchHTMLContent(url, currentDocId),
          processByteBasedDocuments(response),
        ]);
        teamspaceIdRef.current = response.data?.documents[0]?.teamspace_id;
        dBModelRef.current = htmlContent;
        activeFileName.current = url.substring(url.lastIndexOf("/") + 1);
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            fileName: url.substring(url.lastIndexOf("/") + 1),
            model: htmlContent,
          },
        });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    } catch (e) {
      //handle error
    }
  };

  // Function to determine document URL
  const determineDocumentURL = (response) => {
    if (!activeDocIdRef.current || activeDocIdRef.current === "loading") {
      navigate(
        `/home/${byteId}/document-edit/${response.data.documents[0].doc_id}`,
        { replace: true }
      );
    }
    return [
      response.data.documents[0].doc_content,
      response.data.documents[0].doc_id,
    ];
  };

  // Function to process byte-based documents
  const processByteBasedDocuments = (response) => {
    if (response.data.documents && response.data.documents.length > 0) {
      let allRecommendations = [];
      for (let i = 0; i < response.data.documents.length; i++) {
        const document = response.data.documents[i];
        const { doc_id, doc_content, recommendations } = document;

        const mappedRecommendations = recommendations.map((rec) => ({
          ...rec,
          doc_content,
          byte_id: response.data.request_id,
          doc_id,
          x: 0,
          y: -9999,
        }));
        allRecommendations.push(...mappedRecommendations);
      }
      dispatch({
        type: "SET_RECOMMENDATION_DATA",
        payload: JSON.parse(JSON.stringify(allRecommendations)),
      });
      dispatch({
        type: "RESET_CHAT_BOT",
        payload: true,
      });
      handlebyteProcessing(false);
    }
  };

  // Helper function to fetch and parse HTML content
  const fetchHTMLContent = async (url, docId) => {
    console.log("DOCID while navigate", docId);

    const cachedData = JSON.parse(localStorage.getItem(docId));
    if (cachedData && cachedData.content) {
      return cachedData.content;
    }
    const cacheBuster = `?_=${new Date().getTime()}`;
    const fullUrl = url.includes("?")
      ? `${url}&${cacheBuster}`
      : `${url}${cacheBuster}`;

    const htmlResponse = await fetch(fullUrl, {
      mode: "cors",
      cache: "no-store",
    });
    if (!htmlResponse.ok) {
      return;
    }
    const htmlText = await htmlResponse.text();

    return htmlText;
  };

  //Handle size of change Request Header
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        dispatch({
          type: "SET_EDITOR_WIDTH",
          payload: entry.contentRect.width,
        });
        dispatch({
          type: "SET_EDITOR_HEIGHT",
          payload: entry.contentRect.height,
        });
      }
    });

    if (editorRef.current && editorRef.current instanceof Element) {
      resizeObserver.observe(editorRef.current);
    }
    return () => {
      if (editorRef.current && editorRef.current instanceof Element) {
        resizeObserver.unobserve(editorRef.current);
      }
    };
  }, [state.modelState.model]);

  //AUTO ADJUST SCROLLER
  useEffect(() => {
    if (
      !state.recommendationState.activeRecommendationData ||
      !state.recommendationState.recommendationData
    )
      return;

    const container = containerRef.current;
    if (container) {
      const activeRecommendation =
        state.recommendationState.recommendationData.find(
          (rec) =>
            rec.id === state.recommendationState.activeRecommendationData.id
        );

      const delay = 600;

      const scrollWithDelay = () => {
        if (activeRecommendation) {
          const scrollPosition = activeRecommendation.y - 200;
          container.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        } else {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }
      };

      setTimeout(scrollWithDelay, delay);
    }
  }, [
    state.recommendationState.activeRecommendationData,
    state.recommendationState.currentRecommendationIndex,
    // recommendationData,
  ]);

  useEffect(() => {
    activeRecommendationDataRef.current =
      state.recommendationState.activeRecommendationData;
  }, [state.recommendationState.activeRecommendationData]);

  useEffect(() => {
    recommendationDataRef.current =
      state.recommendationState.recommendationData;
    console.log("RECOMMENDATION DATA", recommendationDataRef.current);

    dispatch({
      type: "SET_SORTEDRECOMMENDATION_DATA",
      payload: recommendationDataRef.current,
    });
  }, [state.recommendationState.recommendationData]);

  // useEffect(() => {
  //   activeFileName.current = state.fileState.fileName;
  // }, [state.fileState.fileName]);

  //Cache updated doc
  const uploadDocument = async (newContent, docId) => {
    if (docId !== activeDocIdRef.current) {
      return;
    }
    const cleanedContent = removeMarkTags(newContent);
    cacheDocument(docId, cleanedContent, activeFileName.current);
  };

  const debouncedUpload = debounceWithCancel((newContent, docId) => {
    uploadDocument(newContent, docId);
  }, debounceDelay);

  const removeMarkTags = (htmlContent) => {
    if (!htmlContent || typeof htmlContent !== "string") return "";
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    const marks = tempElement.querySelectorAll("mark");
    const brTags = tempElement.querySelectorAll(`br[data-location]`);
    const spanTags = tempElement.querySelectorAll(`span[data-location]`);
    const hiddenElements = tempElement.querySelectorAll("ul, ol, p, span");

    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;
      if (isRemoved) {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      } else {
        mark.remove();
      }
    });

    brTags.forEach((br) => {
      br.remove();
    });
    spanTags.forEach((span) => {
      span.remove();
    });
    hiddenElements.forEach((el) => {
      if (window.getComputedStyle(el).visibility === "hidden") {
        el.remove();
      }
    });
    const froalaTag = tempElement.querySelector(
      'p a[title="Froala Editor"]'
    )?.parentNode;
    if (froalaTag) {
      froalaTag.remove();
    }

    return tempElement.innerHTML;
  };

  const handleContentChange = (newContent, docId) => {
    debouncedUpload(newContent, docId);
  };

  //Recommendation Functionality
  useEffect(() => {
    if (requestDataRef.current) {
      modelRef.current = state.modelState.model;
    }
  }, [state.modelState.model]);

  useEffect(() => {
    if (
      state.recommendationState.activeRecommendationData &&
      state.loadingState.isProcessingComplete
    ) {
      const timeoutId = setTimeout(() => {
        highlightText();
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [
    state.recommendationState.activeRecommendationData,
    state.loadingState.isProcessingComplete,
  ]);

  useEffect(() => {
    const placeAnnotation = async () => {
      if (state.recommendationState.recommendationData) {
        const result = await processAllRecommendations();
        dispatch({
          type: "SET_IS_PROCESSING",
          payload: result,
        });
      }
    };

    placeAnnotation();
  }, [state.recommendationState.recommendationData, state.fileState.fileName]);

  const handleChatBoxState = () => {
    if (state.uiState.showChatBox) {
      dispatch({ type: "SET_SHOW_CHATBOX", payload: false });
    } else {
      dispatch({ type: "SET_SHOW_CHATBOX", payload: true });
    }
  };

  //Update Retry Button State
  const updateRetryButton = () => {
    if (
      state.recommendationState.recommendationData &&
      state.loadingState.isProcessingComplete === true
    ) {
      setTimeout(() => {
        const shouldShowRetry =
          state.recommendationState.recommendationData.some(
            (recommendation) =>
              String(recommendation.doc_id) ===
                String(activeDocIdRef.current) && recommendation.y === -9999
          );

        if (shouldShowRetry) {
          dispatch({
            type: "SET_SHOW_RETRY_BUTTON_AI_EDIT_MISSING",
            payload: true,
          });
        } else {
          dispatch({
            type: "SET_SHOW_RETRY_BUTTON_AI_EDIT_MISSING",
            payload: false,
          });
        }
      }, 3500);
    }
  };
  useEffect(() => {
    updateRetryButton();
  }, [
    state.recommendationState.recommendationData,
    state.recommendationState.activeRecommendationData,
    state.recommendationState.sortedRecommendationData,
  ]);

  useEffect(() => {
    if (
      state.recommendationState.recommendationData &&
      state.recommendationState.activeRecommendationData
    ) {
      const newIndex =
        state.recommendationState.sortedRecommendationData.findIndex(
          (recommendation) =>
            recommendation.id ===
            state.recommendationState.activeRecommendationData.id
        );

      if (newIndex !== -1) {
        dispatch({
          type: "SET_CURRENTRECOMMENDATION_INDEX",
          payload: newIndex,
        });
      }
    }
  }, [
    state.recommendationState.activeRecommendationData,
    state.recommendationState.sortedRecommendationData,
  ]);

  //ADD RECOMMENDATION
  const reverseHighlightLogic = (doc) => {
    if (!doc) return;
    const marks = doc.querySelectorAll("mark");

    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;

      if (isRemoved) {
        mark.remove();
      } else {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      }
    });

    const elements = "p, h1, h2, h3, span, div, li, ul, ol";
    removeElementsWithOnlyBr(doc, elements);
  };

  //Add New Content
  const addText = (recommendationId) => {
    if (!requestDataRef.current) return false;

    if (state.recommendationState.activeRecommendationData) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(modelRef.current, "text/html");

      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );

      if (recommendationElement) {
        recommendationElement.remove();
      }

      reverseHighlightLogic(doc);
      removeElementsWithOnlyBr(doc);
      const newModel = doc.documentElement.outerHTML;
      dispatch({
        type: "SET_MODEL",
        payload: newModel,
      });
      uploadDocument(newModel, activeDocIdRef.current, "ADD TEXT");
      return true;
    }

    return false;
  };

  //REJECT RECOMMENDATION
  const rejectRecommendation = (recommendationId) => {
    if (!requestDataRef.current) return false;
    if (state.recommendationState.activeRecommendationData) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(modelRef.current, "text/html");
      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );

      if (recommendationElement) {
        const parent = recommendationElement.parentNode;
        if (parent) {
          recommendationElement.remove();
          clearPreviousHighlights(doc);
          const newModel = doc.documentElement.outerHTML;
          modelRef.current = newModel;
          dispatch({
            type: "SET_MODEL",
            payload: newModel,
          });
          return true;
        }
      }
    }
    return false;
  };

  //Froala Model Callback
  const handleModelChange = useCallback((newModel) => {
    dispatch({
      type: "SET_MODEL",
      payload: newModel,
    });
  }, []);

  //Navigation for document >> document-edit
  const handleNavigation = (recommendation) => {
    dispatch({
      type: "SET_ACTIVERECOMMENDATION",
      payload: recommendation,
    });
  };

  // Sorting setRecommendationData increasing y (sortedRecommendations);
  const sortRecommendationsByDocIdAndY = (recommendations) => {
    if (!recommendations || recommendations.length <= 0) {
      return;
    }
    const filteredRecommendations = recommendations.filter(
      (recommendation) => recommendation.y !== "hide"
    );

    const groupedByDocId = {};

    // Group recommendations by doc_id
    filteredRecommendations.forEach((recommendation) => {
      const { doc_id } = recommendation;
      if (!groupedByDocId[doc_id]) {
        groupedByDocId[doc_id] = [];
      }
      groupedByDocId[doc_id].push(recommendation);
    });

    // Sort each group by y
    Object.keys(groupedByDocId).forEach((docId) => {
      groupedByDocId[docId].sort((a, b) => a.y - b.y);
    });

    const sortedRecommendations = [];

    filteredRecommendations.forEach((recommendation) => {
      if (
        !sortedRecommendations.some(
          (rec) => rec.doc_id === recommendation.doc_id
        )
      ) {
        sortedRecommendations.push(...groupedByDocId[recommendation.doc_id]);
      }
    });

    return sortedRecommendations;
  };

  //CHECK FOR RANGE
  useEffect(() => {
    if (
      state.recommendationState.recommendationData.length <= 0 ||
      !state.loadingState.isProcessingComplete
    ) {
      dispatch({
        type: "SET_IS_SUGGESTION_LOADING",
        payload: false,
      });
      return;
    }
    let smallestYRec = null;
    const updatedRecommendations = [
      ...state.recommendationState.recommendationData,
    ];
    const spans = document.querySelectorAll("span[data-location]") || [];
    spans.forEach((span) => {
      if (!span) return;
      const recId = parseInt(span.getAttribute("data-location").split("_")[1]);
      const recommendation = updatedRecommendations.find(
        (rec) => rec.id === recId
      );
      if (recommendation) {
        const rect = span.getBoundingClientRect();
        const container = containerRef?.current;
        const scrollOffset = container
          ? container.scrollTop
          : window.scrollY || document.documentElement.scrollTop;

        let calculatedY = rect.top + scrollOffset - 180;
        if (calculatedY < 0) {
          calculatedY = 10;
        }
        if (
          acceptedArray.current.includes(recId) ||
          rejectedArray.current.includes(recId)
        ) {
          return;
        }
        const isWithinRange = Math.abs(recommendation.y - calculatedY) <= 100;
        if (!isWithinRange || recommendation.y < 0) {
          recommendation.y = calculatedY;
          while (
            updatedRecommendations.some(
              (rec) =>
                rec.id !== recId && Math.abs(rec.y - recommendation.y) <= 50
            )
          ) {
            recommendation.y += 110;
          }
          if (
            !smallestYRec ||
            (recommendation.y <= smallestYRec.y && recommendation.y > 0)
          ) {
            smallestYRec = recommendation;
          }
        }
      }
    });
    const docFilteredRecommendations =
      state.recommendationState.recommendationData.filter(
        (rec) => String(rec.doc_id) === String(activeDocIdRef.current)
      );
    const smallestYD =
      docFilteredRecommendations
        .filter(
          (rec) => rec.y !== undefined && rec.y !== "hide" && rec.y !== -9999
        )
        .sort((a, b) => Number(a.y) - Number(b.y))[0] || null;
    if (smallestYD && !state.recommendationState.activeRecommendationData) {
      dispatch({
        type: "SET_ACTIVERECOMMENDATION",
        payload: smallestYD,
      });
    }


    const sortedData = sortRecommendationsByDocIdAndY(updatedRecommendations);
    dispatch({
      type: "SET_SORTEDRECOMMENDATION_DATA",
      payload: sortedData,
    });

    dispatch({
      type: "SET_IS_SUGGESTION_LOADING",
      payload: false,
    });
  }, [state.modelState.model]);

  //PLACE ANNOTATION
  const processAllRecommendations = async () => {
    try {
      if (
        !state.recommendationState.recommendationData ||
        state.recommendationState.recommendationData.length === 0 ||
        state.modelState.model === "Loading"
      ) {
        return false;
      }

      let parser = new DOMParser();
      let doc = parser.parseFromString(modelRef.current, "text/html");
      const recommendationsToProcess = recommendationDataRef.current.filter(
        (rec) => {
          return (
            rec &&
            String(rec.doc_id) === String(activeDocIdRef.current) &&
            !acceptedArray.current.includes(rec.id) &&
            !rejectedArray.current.includes(rec.id)
          );
        }
      );

      for (let [index, rec] of recommendationsToProcess.entries()) {
        addRecommendationAnnotation(doc, rec, index + 1);
      }
      removeElementsWithOnlyBr(doc);
      const updatedModel = doc.documentElement.outerHTML;
      dispatch({
        type: "SET_MODEL",
        payload: updatedModel,
      });
      return true;
    } catch (e) {}
  };

  //CHECK FOR HEADING
  function isHeading(node) {
    return ["H1", "H2", "H3", "H4", "H5", "H6"].includes(node.nodeName);
  }

  //ADD ANNOTATION
  const addRecommendationAnnotation = (doc, rec, index) => {
    if (!rec || !rec.change_request_text) return;
    if (!doc || !doc.body) return;

    const stripTags = (htmlString) => {
      const parsedDoc = new DOMParser().parseFromString(
        htmlString,
        "text/html"
      );
      return parsedDoc.body.textContent || "";
    };

    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = rec.change_request_text;
    let matchFound = true;
    let lastRecommendationElement = null;
    const prevTempContainer = document.createElement("div");
    prevTempContainer.innerHTML = rec.previous_string;
    let childIndex = 0;
    for (const child of tempContainer.childNodes) {
      if (!matchFound) break;
      if (child.nodeName === "UL" || child.nodeName === "OL") {
        for (const listItem of child.childNodes) {
          if (listItem.nodeName === "LI" && listItem.textContent?.trim()) {
            if (!listItem.textContent?.trim()) continue;
            processText(listItem, false);
            if (!matchFound) break;
          }
        }
      }

      if (!child.textContent?.trim()) continue;

      processText(child, false);

      childIndex++;
    }
    if (
      !matchFound &&
      (childIndex === 0 ||
        childIndex === tempContainer.childNodes.length - 1) &&
      prevTempContainer.childNodes.length > 0
    ) {
      processText(prevTempContainer.childNodes[0], true);
    }
    if (!matchFound) {
      if (lastRecommendationElement) {
        let previousSibling = lastRecommendationElement.previousElementSibling;

        while (
          previousSibling &&
          isHeading(previousSibling) &&
          !matchFound &&
          childIndex < tempContainer.children.length
        ) {
          processText(tempContainer.children[childIndex], true);
          childIndex++;
        }
      }
    }
    function processText(node, KeepLoopMutlipleMatch) {
      const searchText = stripTags(node.textContent || "") || "";
      const normalizedRecommendation = cleanText(searchText);
      const normalizeTextContent = (node) => cleanText(node?.textContent || "");

      const flatBodyText = normalizeTextContent(doc.body);
      const matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
      let multipleMatch = false;
      const regex = new RegExp(normalizedRecommendation, "g");
      const matchIndices = [];
      let match;

      while ((match = regex.exec(flatBodyText)) !== null) {
        matchIndices.push(match.index);
      }

      if (matchIndices.length > 1) {
        multipleMatch = true;
      }

      if (matchStartIndex !== -1) {
        let matchEndIndex = matchStartIndex + normalizedRecommendation.length;
        let currentOffset = 0;
        matchFound = true;

        const addTag = (node) => {
          if (!node) return;
          if (node.nodeType === Node.TEXT_NODE) {
            const originalText = node.textContent || "";
            const normalizedText = cleanText(originalText);
            const textLength = normalizedText.length;

            if (
              currentOffset < matchEndIndex &&
              currentOffset + textLength > matchStartIndex
            ) {
              if (multipleMatch && lastRecommendationElement) {
                if (KeepLoopMutlipleMatch) {
                } else {
                  return;
                }
              }

              let parent = node.parentNode;
              if (parent?.parentNode) {
                if (
                  parent.parentNode.tagName === "UL" ||
                  parent.parentNode.tagName === "OL"
                ) {
                  parent = getMainParentNode(parent);
                }
                // if (
                //   !lastRecommendationElement ||
                //   lastRecommendationElement.compareDocumentPosition(parent) &
                //     Node.DOCUMENT_POSITION_FOLLOWING
                // ) {
                //   const recommendationElement = document.createElement("span");
                //   Object.assign(recommendationElement.style, {
                //     visibility: "hidden",
                //     position: "absolute",
                //     width: "20px",
                //     height: "20px",
                //     margin: "0px",
                //     borderRadius: "50%",
                //     backgroundColor: "#006666",
                //     color: "white",
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "center",
                //     fontSize: "1px",
                //     zIndex: "40",
                //     userSelect: "none",
                //   });
                //   recommendationElement.setAttribute(
                //     "data-location",
                //     `recommendation_${rec.id}`
                //   );
                //   recommendationElement.setAttribute("data-index", index + 1);
                //   recommendationElement.setAttribute(
                //     "contenteditable",
                //     "false"
                //   );

                //   recommendationElement.innerHTML = "’";
                //   parent.parentNode.insertBefore(
                //     recommendationElement,
                //     parent.nextSibling
                //   );
                //   if (lastRecommendationElement?.parentNode) {
                //     lastRecommendationElement.parentNode.removeChild(
                //       lastRecommendationElement
                //     );
                //   }
                //   lastRecommendationElement = recommendationElement;
                // }
                if (
                  !lastRecommendationElement ||
                  (lastRecommendationElement &&
                    parent &&
                    lastRecommendationElement.compareDocumentPosition(parent) &
                      Node.DOCUMENT_POSITION_FOLLOWING)
                ) {
                  const recommendationElement = document.createElement("span");
                  Object.assign(recommendationElement.style, {
                    visibility: "hidden",
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    margin: "0px",
                    borderRadius: "50%",
                    backgroundColor: "#006666",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1px",
                    zIndex: "40",
                    userSelect: "none",
                  });

                  recommendationElement.setAttribute(
                    "data-location",
                    `recommendation_${rec?.id || "unknown"}`
                  );
                  recommendationElement.setAttribute("data-index", index + 1);
                  recommendationElement.setAttribute(
                    "contenteditable",
                    "false"
                  );
                  recommendationElement.innerHTML = "’";

                  if (parent?.parentNode) {
                    let nextElement = parent.nextSibling;

                    while (
                      nextElement &&
                      nextElement.nodeType === Node.TEXT_NODE
                    ) {
                      nextElement = nextElement.nextSibling;
                    }

                    if (nextElement) {
                      parent.parentNode.insertBefore(
                        recommendationElement,
                        nextElement
                      );
                    } else {
                      parent.parentNode.appendChild(recommendationElement);
                    }
                  } else {
                    console.warn(
                      "Parent node is missing while inserting recommendationElement"
                    );
                  }

                  if (lastRecommendationElement?.parentNode) {
                    lastRecommendationElement.parentNode.removeChild(
                      lastRecommendationElement
                    );
                  }

                  lastRecommendationElement = recommendationElement;
                }
              }
            }
            currentOffset += textLength;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            Array.from(node.childNodes).forEach((childNode) =>
              addTag(childNode)
            );
          }
        };

        addTag(doc.body);
      } else {
        matchFound = false;
      }
    }
  };

  //HIGHLIGHT TEXT TO BE CHANGED
  const highlightText = () => {
    try {
      if (
        !requestDataRef.current ||
        !state.recommendationState.activeRecommendationData
      ) {
        return;
      }
      let parser = new DOMParser();
      let doc;
      try {
        doc = parser.parseFromString(state.modelState.model, "text/html");
      } catch (error) {
        return;
      }
      if (
        acceptedArray.current.includes(
          state.recommendationState.activeRecommendationData.id
        ) ||
        rejectedArray.current.includes(
          state.recommendationState.activeRecommendationData.id
        )
      ) {
        clearPreviousHighlights(doc);
        dispatch({
          type: "SET_MODEL",
          payload: doc.documentElement.outerHTML,
        });
        return;
      }
      const { previous_string, change_request_text } =
        state.recommendationState.activeRecommendationData;
      clearPreviousHighlights(doc);
      const differences = compareHtmlStrings(
        previous_string,
        change_request_text
      );
      // console.log("PREVIOUS STRING", previous_string);
      // console.log("CHANGE REQUEST TEXT", change_request_text);
      console.log("DIFF CHECK ( CHECK FOR EMPTY HTML TAGS", differences);

      differences.forEach((difference, index) => {
        const prevHtml = difference.prev;
        const newHtml = difference.new;
        const newHtmlTag = getTagName(newHtml);
        let prevHtmlTag = "";
        let prevHtmlList = [];
        let searchHtml = "";
        let isPrev = false;
        let isNext = false;
        if (!prevHtml) {
          const previousDiff = differences[index - 1] || null;
          const nextDiff = differences[index + 1] || null;
          const isPreviousHeading =
            previousDiff?.prev && /<(h[1-4])>/i.test(previousDiff.prev);
          if (isPreviousHeading && nextDiff && nextDiff?.prev) {
            searchHtml = nextDiff.prev;
            isNext = true;
          } else if (previousDiff && previousDiff?.prev) {
            searchHtml = previousDiff.prev;
            isPrev = true;
          } else if (nextDiff && nextDiff?.prev) {
            searchHtml = nextDiff.prev;
            isNext = true;
          }
          if (searchHtml && /<img\s/i.test(searchHtml)) {
            const alternativeDiff = differences[index - 2] || null;
            if (alternativeDiff && alternativeDiff?.prev) {
              searchHtml = alternativeDiff.prev;
            }
          }
        } else {
          searchHtml = prevHtml;
        }
        const getTextContent = (html) => {
          if (!html) return "";
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = html;
          return tempDiv.textContent.trim();
        };

        for (let i = 0; i < index; i++) {
          if (differences[i]?.prev) {
            const textContent = getTextContent(differences[i].prev);
            if (textContent && textContent !== getTextContent(searchHtml)) {
              prevHtmlList.push(textContent);
            }
          }
        }
        prevHtmlTag = getTagName(searchHtml);
        diffChecker(
          prevHtml,
          newHtml,
          doc,
          searchHtml,
          isPrev,
          isNext,
          prevHtmlList,
          newHtmlTag,
          prevHtmlTag
        );
      });
      dispatch({
        type: "SET_MODEL",
        payload: doc.documentElement.outerHTML,
      });
    } catch (e) {}
  };
  const getTagName = (html) => {
    if (!html) return "";
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html.trim();
    return tempDiv.firstElementChild
      ? tempDiv.firstElementChild.tagName.toLowerCase()
      : "";
  };

  //COMPARE
  function compareHtmlStrings(previousString, changeRequestText) {
    const parseHTML = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      return [...doc.body.childNodes];
    };

    const normalizeText = (text) =>
      text.trim().replace(/\s+/g, " ").toLowerCase();

    const calculateSimilarity = (text1, text2) => {
      const words1 = normalizeText(text1).split(" ");
      const words2 = normalizeText(text2).split(" ");
      const commonWords = words1.filter((word) => words2.includes(word));
      const similarity =
        (2 * commonWords.length) / (words1.length + words2.length);
      return similarity;
    };

    const nodesAreSimilar = (node1, node2) => {
      if (!node1 || !node2) return false;
      const text1 = node1.outerHTML || node1.nodeValue || "";
      const text2 = node2.outerHTML || node2.nodeValue || "";

      const isSimilar = calculateSimilarity(text1, text2) > 0.7;
      if (node2.textContent === node1.textContent) {
        return true;
      }
      if (
        normalizeText(node1.textContent) === normalizeText(node2.textContent)
      ) {
        return true;
      }
      return isSimilar;
    };
    let list = [];
    const prevNodes = parseHTML(previousString);
    const newNodes = parseHTML(changeRequestText);
    const usedPrevNodes = new Set();
    const usedNewNodes = new Set();
    newNodes.forEach((newNode, j) => {
      if (newNode.nodeType === Node.TEXT_NODE) {
        return;
      }
      let matched = false;
      prevNodes.forEach((prevNode, i) => {
        if (prevNode.nodeType === Node.TEXT_NODE) {
          return;
        }
        if (
          !usedPrevNodes.has(i) &&
          !usedNewNodes.has(j) &&
          nodesAreSimilar(prevNode, newNode)
        ) {
          const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
          const newContent = newNode?.outerHTML || newNode?.nodeValue || "";

          if (normalizeText(prevContent) || normalizeText(newContent)) {
            list.push({
              prev: prevContent,
              new: newContent,
            });
          }

          usedPrevNodes.add(i);
          usedNewNodes.add(j);
          matched = true;
        }
      });

      if (!matched) {
        const newContent = newNode?.outerHTML || newNode?.nodeValue || "";
        if (normalizeText(newContent)) {
          list.push({
            prev: "",
            new: newContent,
          });
        }
      }
    });

    prevNodes.forEach((prevNode, i) => {
      if (!usedPrevNodes.has(i)) {
        const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
        if (normalizeText(prevContent)) {
          list.push({
            prev: prevContent,
            new: "",
          });
        }
      }
    });

    const isOnlyHtmlTags = (str) => {
      return /^<(\w+)[^>]*><\/\1>$/.test(str.trim());
    };
    const mergedList = [];
    for (let i = 0; i < list.length; i++) {
      const current = list[i];
      const lastItem = mergedList[mergedList.length - 1];
      if (isOnlyHtmlTags(current.prev) && isOnlyHtmlTags(current.new)) {
        continue;
      }

      if (
        current.prev === "" &&
        i > 0 &&
        lastItem.prev === "" &&
        lastItem.new !== ""
      ) {
        lastItem.new += current.new;
      } else if (
        lastItem &&
        lastItem.prev === current.prev &&
        lastItem.new === current.new
      ) {
        continue;
      } else {
        mergedList.push(current);
      }
    }

    return mergedList;
  }

  //DIFFCHECKER
  const diffChecker = (
    previous_string,
    change_request_text,
    doc,
    searchHtml,
    isPrev,
    isNext,
    prevHtmlList,
    newHtmlTag,
    prevHtmlTag
  ) => {
    try {
      function removeEmAndStrongTags(content) {
        content = content.replace(/<em[^>]*>(.*?)<\/em>/g, "$1");
        content = content.replace(/<strong[^>]*>(.*?)<\/strong>/g, "$1");
        return content;
      }
      const stripOuterTags = (htmlString) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;
        if (tempElement.children.length === 1) {
          return tempElement.children[0].innerHTML;
        }
        return tempElement.innerHTML;
      };
      let prevContent = stripOuterTags(previous_string);
      let newContent = stripOuterTags(change_request_text);
      if (
        (prevContent.includes("<em>") && newContent.includes("<strong>")) ||
        (prevContent.includes("<strong>") && newContent.includes("<em>"))
      ) {
        // Only remove <em> and <strong> if both conditions are true
        prevContent = removeEmAndStrongTags(prevContent);
        newContent = removeEmAndStrongTags(newContent);
      }
      let highlightedHTML = "";
      const dmp = new diff_match_patch();
      let diffs = dmp.diff_main(prevContent, newContent, true);
      dmp.diff_cleanupSemantic(diffs);
      let addContent = false;
      let removeContent = false;
      diffs.forEach(([type, text]) => {
        if (type === 1) {
          highlightedHTML += wrapTextContent(
            text,
            "#D8F9FF",
            !newHtmlTag ? prevHtmlTag : newHtmlTag,
            false
          );
          addContent = true;
        } else if (type === -1) {
          highlightedHTML += wrapTextContent(
            text,
            "#D8F9FF",
            !newHtmlTag ? prevHtmlTag : newHtmlTag,
            true
          );
          removeContent = true;
        } else {
          highlightedHTML += text;
        }
      });

      const tempPrevContainer = document.createElement("div");
      tempPrevContainer.innerHTML = searchHtml;
      let lastDocNode = tempPrevContainer.lastChild;

      while (
        lastDocNode &&
        (lastDocNode.textContent.trim() === "" ||
          lastDocNode.nodeName === "HR" ||
          lastDocNode.textContent.trim().length < 3)
      ) {
        lastDocNode = lastDocNode.previousSibling;
      }
      if (highlightedHTML === prevContent) {
        return;
      }

      if (removeContent) {
        replaceMatchingText(
          doc.body,
          lastDocNode?.textContent || "",
          highlightedHTML,
          isPrev,
          isNext,
          prevHtmlList,
          newHtmlTag
        );
      }
      if (addContent && !removeContent) {
        handleAddedContent(
          doc.body,
          lastDocNode?.textContent || "",
          highlightedHTML,
          isPrev,
          isNext,
          prevHtmlList,
          newHtmlTag
        );
      }

      if (!removeContent && !addContent) {
        return;
      }
      removeElementsWithOnlyBr(doc);
    } catch (e) {}
  };

  //Found Relevant position (in case of repeat)
  function areAllStringsInPreviousSiblings(parentElement, prevHtmlList) {
    if (!Array.isArray(prevHtmlList)) {
      return false;
    }

    let prevSibling = parentElement.previousSibling;
    let foundTexts = new Set();
    let cleanedPrevHtmlList = prevHtmlList.map((item) =>
      item.replace(/\s+/g, "").trim()
    );

    while (prevSibling) {
      let prevSiblingText = cleanText(prevSibling.textContent);
      if (cleanedPrevHtmlList.includes(prevSiblingText)) {
        foundTexts.add(prevSiblingText);
      }
      prevSibling = prevSibling.previousSibling;
    }
    const matchPercentage = (foundTexts.size / prevHtmlList.length) * 100;
    return matchPercentage > 30;
  }

  //Replace text
  const replaceMatchingText = (
    node,
    searchText,
    replacementHTML,
    isPrev,
    isNext,
    prevHtmlList,
    newHtmlTag
  ) => {
    try {
      const walker = document.createTreeWalker(
        node,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      const normalizedSearchText = cleanText(searchText);
      let found = false;
      let matches = [];
      while (walker.nextNode()) {
        const walkerNode = walker.currentNode;
        let currentNode = walker.currentNode;
        let style = walkerNode.parentNode.style.cssText ?? "";
        if (
          !style &&
          currentNode.nextSibling &&
          currentNode.nextSibling.style
        ) {
          style = currentNode.nextSibling.style.cssText ?? "";
        }
        if (
          !style &&
          currentNode.previousSibling &&
          currentNode.previousSibling.style
        ) {
          style = currentNode.previousSibling.style.cssText ?? "";
        }

        let parentElement = getMainParentNode(currentNode);
        if (parentElement.tagName === "LI" && parentElement.parentNode) {
          parentElement = parentElement.parentNode;
        }
        const currentText = cleanText(parentElement.textContent);
        if (currentText && currentText.includes(normalizedSearchText)) {
          let parentText = cleanText(parentElement.textContent);
          if (parentText !== normalizedSearchText) {
            continue;
          }
          matches.push(parentElement);
          if (
            matches.length > 1 &&
            !areAllStringsInPreviousSiblings(parentElement, prevHtmlList)
          ) {
            continue;
          }

          // if (parentElement.style) {
          //   parentElement.style.cssText = style;
          // }

          const tempContainer = document.createElement(
            !newHtmlTag ? parentElement.tagName ?? "div" : newHtmlTag
          );
          tempContainer.innerHTML = replacementHTML;
          // tempContainer.style.cssText = style;
          // Array.from(tempContainer.childNodes).forEach((child) => {
          //   applyStyleRecursively(child, style);
          // });
          parentElement.innerHTML = isPrev
            ? parentElement.innerHTML + tempContainer.innerHTML
            : tempContainer.innerHTML;
          found = true;
          break;
          // }
        }
      }
      if (!found) {
        dispatch({
          type: "SET_SHOW_RETRY_BUTTON_TRACK_CHANGES_MISSING",
          payload: true,
        });
      }
      return found;
    } catch (e) {}
  };

  // const extractTextIgnoringMark = (node) => {
  //   const clonedNode = node.cloneNode(true);
  //   clonedNode.querySelectorAll("mark").forEach((mark) => mark.remove());
  //   return cleanText(clonedNode.textContent);
  // };
  //ADD NODE TEXT
  const handleAddedContent = (
    node,
    searchText,
    addedText,
    isPrev,
    isNext,
    prevHtmlList,
    newHtmlTag
  ) => {
    try {
      const newNode = document.createElement(!newHtmlTag ? "div" : newHtmlTag);

      newNode.innerHTML = addedText;
      const normalizedSearchText = cleanText(searchText);
      const walker = document.createTreeWalker(
        node,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );
      let found = false;
      const similarityThreshold = 0.98;
      let matches = [];
      // Traverse through text nodes
      while (walker.nextNode()) {
        const walkerNode = walker.currentNode;
        let currentNode = walker.currentNode;
        currentNode = getMainParentNode(currentNode);
        // const currentText = extractTextIgnoringMark(currentNode);
        const currentText = cleanText(currentNode.textContent);

        const similarity = calculateSimilarity(
          currentText,
          normalizedSearchText
        );
        if (similarity >= similarityThreshold) {
          matches.push(currentNode);
          if (
            matches.length > 1 &&
            !areAllStringsInPreviousSiblings(currentNode, prevHtmlList)
          ) {
            continue;
          }
          let mainParent = getMainParentNode(currentNode);
          let style = "";
          if (walkerNode.parentNode && walkerNode.parentNode.style) {
            let styleNode = walkerNode.parentNode;
            if (styleNode.style.visibility === "hidden") {
              if (styleNode.nextSibling && styleNode.nextSibling.style) {
                styleNode = styleNode.nextSibling;
              } else if (
                styleNode.previousSibling &&
                styleNode.previousSibling.style
              ) {
                styleNode = styleNode.previousSibling;
              }
            }
            style = styleNode.style.cssText || "";
          }
          applyStyleRecursively(newNode, style);
          Array.from(newNode.childNodes).forEach((child) => {
            applyStyleRecursively(child, style);
          });
          //  const reversedChildren = Array.from(newNode.childNodes).reverse();
          if (mainParent.tagName === "CODE") {
            //ADDED THIS FOR CODE CASE
            mainParent = mainParent.parentNode;
          }
          if (isPrev) {
            // reversedChildren.forEach((child) => {
            //   mainParent.parentNode.insertBefore(child, mainParent.nextSibling);
            // });
            let nextSibling = mainParent.nextSibling;

            // Traverse until we find a visible sibling
            // while (nextSibling && nextSibling.style.visibility === "hidden") {
            //   nextSibling = nextSibling.nextSibling;
            // }
            mainParent.parentNode.insertBefore(newNode, nextSibling);
          } else if (isNext) {
            // reversedChildren.forEach((child) => {
            //   mainParent.parentNode.insertBefore(child, mainParent);
            // });
            mainParent.parentNode.insertBefore(newNode, mainParent);
          } else {
            mainParent.innerHTML = newNode.innerHTML;
          }

          found = true;
          // break;
        } else {
        }
      }
      if (!found) {
        dispatch({
          type: "SET_SHOW_RETRY_BUTTON_TRACK_CHANGES_MISSING",
          payload: true,
        });
      }
      return found;
    } catch (e) {}
  };

  //OPTIMIZE TEXT
  const cleanText = (text) => {
    let cleanedText = text;

    REMOVE_CHARACTERS.forEach((char) => {
      const escapedChar = char.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      cleanedText = cleanedText.replace(new RegExp(escapedChar, "g"), "");
    });

    return cleanedText.trim().normalize();
  };

  //GET STYLE
  const applyStyleRecursively = (node, style) => {
    if (!style || style.visibility === "hidden") {
      return;
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.nodeName === "MARK") {
        return;
      }
      if (node.nodeName === "CODE") {
        return;
      } else if (node.nodeName === "STRONG") {
        const fontStyles = extractFontStyles(style);
        node.setAttribute("style", fontStyles);
      } else if (
        node.nodeName === "H1" ||
        node.nodeName === "H2" ||
        node.nodeName === "H3" ||
        node.nodeName === "H4"
      ) {
        const fontStyles = extractFontFamily(style);
        node.setAttribute("style", fontStyles);
      } else {
        const fontStyles = extractAllStyles(style);

        node.setAttribute("style", fontStyles);
      }
    }
    Array.from(node.childNodes).forEach((child) => {
      applyStyleRecursively(child, style);
    });
  };
  const extractAllStyles = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter((rule) => rule.length > 0);

    return styleObj.join("; ");
  };
  const extractFontStyles = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter(
        (rule) => rule.startsWith("font-family") || rule.startsWith("font-size")
      );
    return styleObj.join("; ");
  };
  const extractFontFamily = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter((rule) => rule.startsWith("font-family"));
    return styleObj.join("; ");
  };

  //LEVENSHTEIN METHOD
  const levenshtein = (a, b) => {
    if (!a || !b) {
      return Math.max(a ? a.length : 0, b ? b.length : 0);
    }
    const tmp = [];
    for (let i = 0; i <= b.length; i++) {
      tmp[i] = [];
      for (let j = 0; j <= a.length; j++) {
        tmp[i][j] = 0;
      }
    }
    for (let i = 0; i <= a.length; i++) {
      tmp[0][i] = i; // First row: [0, 1, 2, ..., a.length]
    }

    for (let i = 0; i <= b.length; i++) {
      tmp[i][0] = i; // First column: [0, 1, 2, ..., b.length]
    }

    // Fill in the rest of the array with minimum cost calculations
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        tmp[i][j] = Math.min(
          tmp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1), // substitution
          tmp[i][j - 1] + 1, // insertion
          tmp[i - 1][j] + 1 // deletion
        );
      }
    }
    return tmp[b.length][a.length];
  };

  //SIMILARITY
  const calculateSimilarity = (a, b) => {
    const maxLen = Math.max(a.length, b.length);
    const levDist = levenshtein(a, b);

    return (maxLen - levDist) / maxLen;
  };

  //GET MAIN PARENT OF NESTED NODE
  const getMainParentNode = (currentNode) => {
    while (
      currentNode &&
      currentNode.parentNode &&
      currentNode.parentNode.nodeName !== "DIV" &&
      currentNode.parentNode.nodeName !== "BODY"
    ) {
      currentNode = currentNode.parentNode;
    }
    return currentNode;
  };

  //WRAP DIFFERENCE IN REPLACE TYPE AIEDIT
  const wrapTextContent = (content, color, newHtmlTag, isRemoved = false) => {
    const tempDiv = document.createElement(newHtmlTag);
    tempDiv.innerHTML = content;
    const wrapTextNode = (node) => {
      if (
        node.nodeType === Node.TEXT_NODE &&
        node.textContent.trim().length > 0
      ) {
        const markedText = document.createElement("mark");
        // markedText.style.backgroundColor = color;
        if (isRemoved) {
          markedText.style.textDecoration = "line-through";
        }
        markedText.style.fontFamily = "inherit";
        markedText.style.fontSize = "inherit";
        markedText.style.color = "inherit";
        markedText.textContent = node.textContent;
        node.replaceWith(markedText);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(wrapTextNode);
      }
    };

    Array.from(tempDiv.childNodes).forEach(wrapTextNode);
    return tempDiv.innerHTML;
  };

  //REMOVE EXTRA SPACES
  function removeElementsWithOnlyBr(doc) {
    const selectors = "p, h1, h2, h3, h4,span, div, li, ul, ol";
    const elements = doc.querySelectorAll(selectors);
    elements.forEach((element) => {
      const children = Array.from(element.childNodes);
      const hasImage = element.querySelector("img");
      const hasNoTextContent =
        !hasImage &&
        (element.textContent.trim() === "" ||
          children.every(
            (child) =>
              (child.nodeType === Node.ELEMENT_NODE &&
                child.nodeName === "BR") ||
              (child.nodeType === Node.TEXT_NODE &&
                child.textContent.trim() === "")
          ));

      if (hasNoTextContent) {
        element.remove();
      }
    });
    removeEmptyListElements(doc);
  }

  function removeEmptyListElements(doc) {
    const listElements = doc.querySelectorAll("ul, ol");

    listElements.forEach((list) => {
      const children = Array.from(list.childNodes);
      const hasNoTextContent =
        list.textContent.trim() === "" ||
        children.every((child) => {
          if (child.nodeType === Node.TEXT_NODE) {
            return child.textContent.trim() === "";
          }
          if (child.nodeType === Node.ELEMENT_NODE) {
            return child.nodeName === "BR";
          }
          return true;
        });

      if (hasNoTextContent) {
        list.remove();
      }
    });
  }

  //REMOVE MARK TAGS FROM DOC
  const clearPreviousHighlights = (doc) => {
    const marks = doc.querySelectorAll("mark");
    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;
      if (isRemoved) {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      } else if (!isRemoved) {
        mark.remove();
      }
    });
    const elements = "p, h1, h2, h3, span, div, li, ul, ol";
    removeElementsWithOnlyBr(doc, elements);
  };

  //Handle Resolve Warning Pop Up
  const handleOpenResolveWarning = async () => {
    const data = await apiService.pendingRecommendation(
      requestDataRef.current.request_id
    );
    if (data.isPending) {
      dispatch({ type: "SET_SHOW_RESOLVE_WARNING", payload: true });
    } else {
      await handleResolveByte();
      // navigate(`/home/document/${activeDocIdRef.current}`);
    }
  };

  //Build Doc With Ai
  const handleGenerateDocument = async (instructions, about, conversation) => {
    try {
      handleConversation({ role: "user", content: "Generate the document" });
      navigate(
        `/home/generate-document/${activeDocIdRef.current}/${requestDataRef.current.document.doc_name}`
      );
      const generatedDoc = await apiService.generateDocumentWithAi(
        instructions,
        about,
        activeDocIdRef.current,
        requestDataRef.current.document.doc_name,
        conversation
      );
      if (generatedDoc.status === "SUCCESS") {
        handleGeneratedDocDescription(about);
        handleGeneratedDocInstruction(instructions);
        handleFileName(activeFileName.current);
        handleGerneratedDoc(generatedDoc?.document);
        if (generatedDoc?.document) {
          handleConversation({
            role: "assistant",
            content: generatedDoc?.document,
          });
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  //HANDLE BUILD POP UP
  const handleCloseBuildPopUp = async () => {
    dispatch({ type: "SET_SHOW_BUILD_POP_UP", payload: false });
  };

  //HANDLE VIEW PENDING AIEDITS
  const handleViewAiEdits = async () => {
    handleCloseResolveWarning();
    let filteredRecommendations =
      state.recommendationState.sortedRecommendationData.filter(
        (rec) =>
          String(rec.doc_id) === String(activeDocIdRef.current) &&
          !acceptedArray.current.includes(rec.id) &&
          !rejectedArray.current.includes(rec.id)
      );

    if (filteredRecommendations.length === 0) {
      filteredRecommendations =
        state.recommendationState.sortedRecommendationData.filter(
          (rec) =>
            !acceptedArray.current.includes(rec.id) &&
            !rejectedArray.current.includes(rec.id)
        );
      if (filteredRecommendations.length > 0) {
        const activeRecommendation = filteredRecommendations[0];
        dispatch({
          type: "SET_IS_PROCESSING",
          payload: false,
        });
        dispatch({
          type: "SET_ACTIVERECOMMENDATION",
          payload: activeRecommendation,
        });
        await handleDocumentChange(
          activeRecommendation.doc_id,
          activeRecommendation.doc_content
        );
      } else {
        dispatch({
          type: "SET_ACTIVERECOMMENDATION",
          payload: null,
        });
      }
    } else {
      const activeRecommendation = filteredRecommendations[0];
      dispatch({
        type: "SET_ACTIVERECOMMENDATION",
        payload: activeRecommendation,
      });
      return;
    }
  };

  //CLOSE RESOLVE
  const handleCloseResolveWarning = () => {
    dispatch({ type: "SET_SHOW_RESOLVE_WARNING", payload: false });
  };

  //Handle Resolve Byte
  const handleResolveByte = async () => {
    await apiService.resolveByte(requestDataRef.current.request_id);
    handleCloseResolveWarning();
    dispatch({
      type: "SET_RECOMMENDATION_DATA",
      payload: [],
    });
    navigate(`/home/document/${activeDocIdRef.current}`, {
      replace: true,
    });
  };

  //Handle Navigation
  const handlePrevious = () => {
    if (state.recommendationState.currentRecommendationIndex > 0) {
      updateModel(
        state.recommendationState.currentRecommendationIndex - 1,
        "previous"
      );
    }
  };

  const handleNext = () => {
    if (
      state.recommendationState.currentRecommendationIndex <
      state.recommendationState.sortedRecommendationData.length - 1
    ) {
      updateModel(
        state.recommendationState.currentRecommendationIndex + 1,
        "next"
      );
    }
  };

  const updateModel = async (newIndex, direction) => {
    let recommendation =
      state.recommendationState.sortedRecommendationData[newIndex];
    if (!recommendation) {
      return;
    }
    const currentDocId = recommendation.doc_id;
    if (String(activeDocIdRef.current) !== String(currentDocId)) {
      activeDocIdRef.current = currentDocId;
      if (direction === "next") {
        dispatch({
          type: "SET_ACTIVERECOMMENDATION",
          payload: null,
        });
      } else {
        dispatch({
          type: "SET_IS_PROCESSING",
          payload: false,
        });
        dispatch({
          type: "SET_ACTIVERECOMMENDATION",
          payload: recommendation,
        });
      }
      const container = containerRef.current;
      if (container) {
        container.scrollTop = 0;
      }
      await handleDocumentChange(currentDocId, recommendation.doc_content);
    } else {
      dispatch({
        type: "SET_ACTIVERECOMMENDATION",
        payload: recommendation,
      });
    }
  };

  // Function to handle document content change and navigation
  const handleDocumentChange = async (docId, docContentUrl) => {
    // handleUpdate();
    navigate(`/home/${byteId}/document-edit/${docId}`);
    const newHtmlContent = await fetchHTMLContent(docContentUrl, docId);
    dispatch({
      type: "SET_MODEL",
      payload: newHtmlContent,
    });
    dispatch({
      type: "SET_FILE_NAME",
      payload: docContentUrl.substring(docContentUrl.lastIndexOf("/") + 1),
    });
    dBModelRef.current = newHtmlContent;
  };

  const handleOnTap = () => {
    dispatch({
      type: "SET_CURRENTRECOMMENDATION_INDEX",
      payload: -1,
    });
    dispatch({
      type: "SET_ACTIVERECOMMENDATION",
      payload: null,
    });
    dispatch({
      type: "SET_RECOMMENDATION_DATA",
      payload: [],
    });
    navigate(`/home/document/${id}`, {
      replace: true,
    });
  };

  //Handle Byte Processing State
  const handleByteProcessingState = async () => {
    const docId = activeDocIdRef.current;
    let processingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    processingBytes.push({ docId, timestamp: Date.now() });
    localStorage.setItem(
      "underProcessingBytes",
      JSON.stringify(processingBytes)
    );
    setTimeout(() => {
      removeExpiredProcessingBytes();
    }, 5 * 60 * 1000);
    await handlebyteProcessing();
  };

  const removeExpiredProcessingBytes = () => {
    let processingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    const updatedBytes = processingBytes.filter(
      (item) => Date.now() - item.timestamp < 5 * 60 * 1000
    );
    localStorage.setItem("underProcessingBytes", JSON.stringify(updatedBytes));
  };

  //Handle Adding a byte flow
  const handleDocBasedByte = async (recommendationByte) => {
    dispatch({ type: "RESET" });
    dispatch({ type: "SET_LOADING", payload: false });
    activeByteIdRef.current = recommendationByte;
    navigate(
      `/home/${recommendationByte}/document-edit/${activeDocIdRef.current}`
    );
    await handleByteBasedRecommendation(recommendationByte);
    const underProcessingBytes =
      JSON.parse(localStorage.getItem("underProcessingBytes")) || [];
    const docId = activeDocIdRef.current;
    const updatedBytes = underProcessingBytes.filter(
      (item) => String(item.docId) !== String(docId)
    );
    localStorage.setItem("underProcessingBytes", JSON.stringify(updatedBytes));
  };

  //LOADING - Skeleton loading
  if (state.loadingState.isLoading) {
    return (
      <div id="editor" className="froala-editor-section">
        <div id="toolbar-container" className="toolbar-container"></div>
        <div style={{ paddingBottom: "15px" }}>
          <EditorSkeleton
            height="40px"
            borderRadius="100px"
            padding="4px 0px"
          />
        </div>
        <div className="editor-suggestion">
          <div className="change-request">
            <EditorSkeleton width="100%" height="100vh" borderRadius="4px" />
          </div>
          <RecommendationSkeletonLoader count={4} />
        </div>
      </div>
    );
  }

  //Main Body
  return (
    <div className="doc-editor">
      <ResolveChangeRequestPopUp
        isVisible={state.uiState.showResolveWarning}
        onClickLButton={handleResolveByte}
        onClickRButton={handleViewAiEdits}
        onClose={handleCloseResolveWarning}
        title="Open AiEdits Pending"
        subtitle="Do you still wish to resolve the Change Request?"
        lButtonText="Resolve CR"
        rButtonText="View AiEdits"
      />
      <div id="editor" className="froala-editor-section fade-in">
        {/* Toolbar Container */}
        <div className="auto-edit-bar">
          <div id="toolbar-container" className="toolbar-container"></div>
          <AutoEditButton
            buttonText="Auto Edit"
            disabled={
              !state.loadingState.isDocLevelByteProcessingComplete ||
              state.uiState.showChatBox
            }
            onClick={handleChatBoxState}
          />
          <BuildDocPopUp
            isVisible={state.uiState.showBuildDocPopUp}
            onClose={handleCloseBuildPopUp}
            onClick={async (instructions, about, conversation) => {
              await handleGenerateDocument(instructions, about, conversation);
              return true;
            }}
          />
        </div>

        <div className="editor-suggestion" ref={containerRef}>
          {(isByteProcessing ||
            !state.loadingState.isDocLevelByteProcessingComplete) && (
            <ProcessByteLoadingScreen
              width={state.uiState.editorWidth}
              height={state.uiState.editorHeight}
            />
          )}

          {state.loadingState.isLoading ? (
            <EditorSkeleton />
          ) : (
            // Froala Editor
            <FroalaEditor
              ref={editorRef}
              model={state.modelState.model}
              onModelChange={handleModelChange}
              activeDocIdRef={activeDocIdRef}
              handleContentChange={handleContentChange}
              handleOnTap={handleOnTap}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              handleOpenResolveWarning={handleOpenResolveWarning}
              byte_content={
                state.recommendationState.apiData?.request_text ??
                "Change Request"
              }
              byteId={requestDataRef.current?.request_id}
              editorWidth={state.uiState.editorWidth}
              senderName={requestDataRef.current?.sender}
              date={requestDataRef.current?.date_time}
              showRetryButton={
                state.uiState.showRetryButtonAiEditMissing ||
                state.uiState.showRetryButtonTrackChangeMissing
              }
              isCRComponentVisible={
                state.recommendationState.recommendationData
              }
              currentRecommendationIndex={
                state.recommendationState.currentRecommendationIndex
              }
              sortedRecommendationData={
                state.recommendationState.sortedRecommendationData
              }
            />
          )}
          <div className="suggestion-auto-edit">
            <ChatBotUI
              visibility={state.uiState.showChatBox}
              onClose={handleChatBoxState}
              chatReset={state.uiState.resetChatBot}
              documentId={activeDocIdRef.current}
              teamspaceId={teamspaceIdRef.current}
              onApiCall={handleByteProcessingState}
              handlebyteProcessing={(value) => {
                handlebyteProcessing(value);
              }}
              sendByte={(recommendationByte) => {
                try {
                  handleDocBasedByte(recommendationByte);
                  return true;
                } catch (error) {
                  return false;
                }
              }}
            />
            {state.loadingState.isSuggestionLoading ? (
              <RecommendationSkeletonLoader count={5} />
            ) : (
              <SuggestionList
                sortedRecommendationData={
                  state.recommendationState.sortedRecommendationData
                }
                id={id}
                acceptedArray={acceptedArray}
                rejectedArray={rejectedArray}
                activeRecommendationData={
                  state.recommendationState.activeRecommendationData
                }
                addText={addText}
                rejectRecommendation={(id) => {
                  rejectRecommendation(id);
                }}
                handleNavigation={handleNavigation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentEditEditor;

const debounceWithCancel = (func, delay) => {
  let timeoutId;
  let isCancelled = false;

  const debouncedFunction = (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    isCancelled = false;
    timeoutId = setTimeout(() => {
      if (!isCancelled) {
        func.apply(null, args);
      }
    }, delay);
  };

  debouncedFunction.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    isCancelled = true;
  };

  return debouncedFunction;
};

const initialState = {
  loadingState: {
    isLoading: true,
    isSuggestionLoading: true,
    isProcessingComplete: false,
    isDocLevelByteProcessingComplete: true,
  },
  uiState: {
    showBuildDocPopUp: false,
    showResolveWarning: false,
    showChatBox: false,
    showRetryButtonAiEditMissing: false,
    showRetryButtonTrackChangeMissing: false,
    editorWidth: 860,
    editorHeight: 860,
    resetChatBot: false,
  },
  recommendationState: {
    apiData: null,
    recommendationData: [],
    activeRecommendationData: null,
    sortedRecommendationData: null,
    currentRecommendationIndex: -1,
  },
  fileState: {
    fileName: "",
  },
  modelState: {
    model: "Loading",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_CHAT_BOT":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          resetChatBot: action.payload,
        },
      };
    case "SET_SHOW_BUILD_POP_UP":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showBuildDocPopUp: action.payload,
        },
      };
    case "SET_SHOW_RETRY_BUTTON_AI_EDIT_MISSING":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showRetryButtonAiEditMissing: action.payload,
        },
      };
    case "SET_SHOW_RETRY_BUTTON_TRACK_CHANGES_MISSING":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showRetryButtonTrackChangeMissing: action.payload,
        },
      };
    case "SET_SHOW_CHATBOX":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showChatBox: action.payload,
        },
      };
    case "SET_SHOW_RESOLVE_WARNING":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          showResolveWarning: action.payload,
        },
      };
    case "SET_EDITOR_WIDTH":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          editorWidth: action.payload,
        },
      };
    case "SET_EDITOR_HEIGHT":
      return {
        ...state,
        uiState: {
          ...state.uiState,
          editorHeight: action.payload,
        },
      };
    case "UPDATE_STATE":
      return {
        ...state,
        fileState: { ...state.fileState, fileName: action.payload.fileName },
        modelState: {
          ...state.modelState,
          model: action.payload.model,
        },
      };
    case "SET_LOADING":
      return {
        ...state,
        loadingState: { ...state.loadingState, isLoading: action.payload },
      };
    case "SET_IS_PROCESSING":
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          isProcessingComplete: action.payload,
        },
      };
    case "SET_IS_DOC_LEVEL_BYTE_PROCESSED":
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          isDocLevelByteProcessingComplete: action.payload,
        },
      };
    case "SET_IS_SUGGESTION_LOADING":
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          isSuggestionLoading: action.payload,
        },
      };
    case "SET_MODEL":
      return {
        ...state,
        modelState: { ...state.modelState, model: action.payload },
      };
    case "SET_RECOMMENDATION_DATA":
      return {
        ...state,
        recommendationState: {
          ...state.recommendationState,
          recommendationData: action.payload,
        },
      };
    case "SET_API_DATA":
      return {
        ...state,
        recommendationState: {
          ...state.recommendationState,
          apiData: action.payload,
        },
      };
    case "SET_SORTEDRECOMMENDATION_DATA":
      return {
        ...state,
        recommendationState: {
          ...state.recommendationState,
          sortedRecommendationData: action.payload,
        },
      };
    case "SET_CURRENTRECOMMENDATION_INDEX":
      return {
        ...state,
        recommendationState: {
          ...state.recommendationState,
          currentRecommendationIndex: action.payload,
        },
      };
    case "SET_ACTIVERECOMMENDATION":
      return {
        ...state,
        recommendationState: {
          ...state.recommendationState,
          activeRecommendationData: action.payload,
        },
      };
    case "SET_FILE_NAME":
      return {
        ...state,
        fileState: { ...state.fileState, fileName: action.payload },
      };
    case "RESET":
      return { ...initialState };
    default:
      return state;
  }
};
