import React, { useState, useRef, useEffect } from "react";
import "../editor-style.css";
import { Send } from "lucide-react";

export default function BuildDocChatBotUI({
  onClick,
  handleConveration,
}) {
  const [messages, setMessages] = useState([
    {
      content: "Modify Doc? Just type your request, and I'll handle it!",
      role: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef(null);

  //Update scroller position
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  //Send Message
  const sendMessage = async () => {
    try {
      if (input.trim() === "") return;
      setIsSending(true);
      const userMessage = { role: "user", content: input };
     await handleConveration(userMessage);
      setMessages((prev) => [...prev, userMessage]);
      setInput("");
      const loadingMessage = {
        content: "Got it! Processing your request...",
        role: "bot",
      };
      setMessages((prev) => [...prev, loadingMessage]);
      const response = await onClick();
      setMessages((prev) =>
        prev.filter(
          (msg) => msg.content !== "Got it! Processing your request..."
        )
      );
      if (response) {
        setMessages((prev) => [
          ...prev,
          { content: "All set! Your doc is updated...", role: "bot" },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { content: "Hmm... couldn't process that. Try again?", role: "bot" },
        ]);
      }
    } catch (e) {
      setMessages((prev) => [
        ...prev,
        { content: "Something went wrong. Please try again.", role: "bot" },
      ]);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">{/* <span></span> */}</div>
      <div className="chatbot-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.content}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chatbot-input">
        <textarea
          className="chatbot-textarea"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!isSending) sendMessage();
            }
          }}
        />
        <button
          onClick={sendMessage}
          className={`send-button ${isSending ? "disabled" : ""}`}
          disabled={isSending}
        >
          <Send size={18} className="rotated-icon" />
        </button>
      </div>
    </div>
  );
}
